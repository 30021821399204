import { VisitsModel } from '@w3lcome/types';
import logger from '_/services/logger';
import { AxiosRequestConfig } from 'axios';

import { CheckinInterface } from './../../interfaces/CheckinInterface';
import RequestCreator from './BaseRequest';

interface VisitType {
  total: number;
  limit: number;
  skip: number;
  data: VisitsModel[];
}

class VisitsApi extends RequestCreator<VisitsModel> {
  private primaryKeyPath = 'ipad/primary-keys';
  private checkinPath = '/ipad/checkin';
  private childrenCheckinPath = 'ipad/children/checkin';
  private childrenPrimaryKeyPath = 'ipad/children/primary-keys';
  private childrenVisitsPath = 'ipad/children/visits';

  checkin = async (values: CheckinInterface, config?: AxiosRequestConfig) => {
    try {
      const { data } = await this.api.post(`${this.checkinPath}`, values, config);
      return data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  childrenCheckin = async (values: CheckinInterface, config?: AxiosRequestConfig) => {
    try {
      const { data } = await this.api.post(`${this.childrenCheckinPath}`, values, config);
      return data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  getChildrenVisits = async (config?: AxiosRequestConfig) => {
    try {
      const { data } = await this.api.get(`${this.childrenVisitsPath}`, config);
      return data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  getChildrenVisit = async (id: string, config?: AxiosRequestConfig) => {
    try {
      const { data } = await this.api.get(`${this.childrenVisitsPath}/${id}`, config);
      return data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  searchVisitToCheckout = async (name: string) => {
    try {
      const result = await this.api.get<VisitType>(`${this.path}`, {
        params: {
          'name[$iLike]': `%${name}%`,
          checkoutAt: '<null>',
          'name[$ne]': '<null>',
          'checkinAt[$ne]': '<null>',
        },
      });

      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  searchChildrenVisitToCheckout = async (name: string) => {
    try {
      const result = await this.api.get<VisitType>(`${this.childrenVisitsPath}`, {
        params: {
          'name[$iLike]': `%${name}%`,
          checkoutAt: '<null>',
          'name[$ne]': '<null>',
          'checkinAt[$ne]': '<null>',
        },
      });

      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  getVisitByPrimaryKey = async (
    value: string
  ): Promise<{ visit: VisitsModel; canceledVisits?: VisitsModel[] }> => {
    try {
      const { data } = await this.api.get(`${this.primaryKeyPath}/${value}`);

      return data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  getChildrenVisitByPrimaryKey = async (
    value: string,
    companyId: string
  ): Promise<{ visit: VisitsModel; canceledVisits?: VisitsModel[] }> => {
    try {
      const { data } = await this.api.get(`${this.childrenPrimaryKeyPath}/${value}`, {
        params: { companyId },
      });

      return data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };
}

const api = new VisitsApi('/ipad/visits');
export default api;
