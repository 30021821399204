import { colors } from '_/config/theme';
import React from 'react';
import { useWindowDimensions } from 'react-native';
import HTML, { MixedStyleDeclaration, MixedStyleRecord } from 'react-native-render-html';

import Text from '../Text';

interface HTMLRenderProps {
  html: string;
  baseStyle?: MixedStyleDeclaration | undefined;
}

const tagsStyles: MixedStyleRecord = {
  a: {
    color: colors.black,
    textDecorationLine: 'none',
  },
  p: { backgroundColor: 'transparent', color: colors.black },
};

const HTMLRender: React.FC<HTMLRenderProps> = ({ html, baseStyle }) => {
  const contentWidth = useWindowDimensions().width;

  return (
    <HTML
      baseStyle={baseStyle}
      tagsStyles={tagsStyles}
      source={{
        html: html.replace(/<\/?a[^>]*>/g, '').replace(/background-color:[^;]+/g, ''),
      }}
      contentWidth={contentWidth}
    />
  );
};

export default HTMLRender;
