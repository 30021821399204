import { Text } from '_/components';
import { fontSizes } from '_/config/theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

interface CheckoutButtonProps {
  onPress: () => void;
  textColor: string;
}

const CheckoutButton: React.FC<CheckoutButtonProps> = ({ onPress, textColor }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.viewBottomContent}>
      <TouchableOpacity
        hitSlop={{ top: 12, right: 12, bottom: 12, left: 12 }}
        onPress={onPress}
        style={styles.buttonBottomContent}
      >
        <Text fontType="bold" size={fontSizes.lg} style={{ color: textColor }}>
          {t('homeScreen.Check-out')}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default CheckoutButton;

const styles = StyleSheet.create({
  viewBottomContent: {
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
  },
  buttonBottomContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
});
