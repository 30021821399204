import { Button, CustomFieldInput, Header } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import KeyboardContainer from '_/components/KeyboardContainer';
import { colors } from '_/config/theme';
import { useCustomization } from '_/hooks/CustomizationContext';
import { useVisit } from '_/hooks/VisitContext';
import { useNextCheckinScreen } from '_/hooks/useNextCheckinScreen';
import { useCentralizedValuesFallback } from '_/hooks/useCentralizedValuesFallback';
import { CustomFieldWithError } from '_/interfaces/CustomFieldWithError';
import { AppRoute } from '_/navigation/types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Animated,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import { showMessage } from 'react-native-flash-message';

export const ExtraHostForm: React.FC = () => {
  const { customization } = useCustomization();

  const { selectedOrFallbackCustomization } = useCentralizedValuesFallback();

  const { setVisitDataByHostFields, getVisitDataByHostFields, checkFields, visit } = useVisit();
  const [focusedField, setFocusedField] = useState<CustomFieldWithError | undefined>();

  const { t } = useTranslation();

  const scrollY = useRef(new Animated.Value(0)).current;
  const scrollViewRef = useRef<ScrollView>(null);

  const { goToNextScreen } = useNextCheckinScreen(AppRoute.CHECKIN_EXTRA_HOST_FORM);

  const [countryCode, setCountryCode] = useState(visit.countryCode);

  const [submitFailed, setSubmitFailed] = useState(false);

  function handleSubmit() {
    const checkedFields = checkFields(
      selectedOrFallbackCustomization?.extraHostFormFields ?? [],
      true
    );

    if (checkedFields.some(({ hasError }) => hasError)) {
      showMessage({
        message: t('infoScreen.missingFieldsError'),
        backgroundColor: colors.danger,
      });
      scrollViewRef.current?.scrollTo({ animated: true, x: 0, y: 0 });
      setSubmitFailed(true);
      return;
    }

    if (submitFailed) setSubmitFailed(false);

    goToNextScreen();
  }

  return (
    <KeyboardContainer
      keyboardOpen={!!focusedField}
      inputName={focusedField?.name || ''}
      keyboardType="default"
      onChange={(value) => setVisitDataByHostFields(value, focusedField)}
      initialValue={getVisitDataByHostFields(focusedField)}
    >
      <KeyboardAvoidingView
        enabled
        style={StyleSheet.absoluteFill}
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      >
        <Animated.ScrollView
          onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], {
            useNativeDriver: Platform.OS !== 'web',
          })}
          ref={scrollViewRef}
          style={StyleSheet.absoluteFill}
          scrollEventThrottle={16}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <View style={styles.container}>
            <ContainerWidthLimit>
              <Header />
              <View style={styles.content}>
                {selectedOrFallbackCustomization?.extraHostFormFields &&
                  selectedOrFallbackCustomization.extraHostFormFields.map((field) => {
                    const [checkedField] = checkFields([field], true);
                    return (
                      <CustomFieldInput
                        key={field.id}
                        value={getVisitDataByHostFields(field)}
                        onChangeText={(value) => setVisitDataByHostFields(value, field)}
                        customField={submitFailed ? checkedField : field}
                        countryCode={countryCode}
                        onChangeCountryCode={(countryCode) => setCountryCode(countryCode)}
                        onFocus={() => {
                          setFocusedField(field);
                        }}
                        onBlur={() => setFocusedField(undefined)}
                      />
                    );
                  })}
                <Button
                  large
                  backgroundColor={customization?.mainColor || colors.primary}
                  labelColor={customization?.backgroundColor || colors.white}
                  onPress={handleSubmit}
                  containerStyles={styles.buttonContainer}
                  buttonStyles={[styles.button, { justifyContent: 'space-between' }]}
                  rightIcon="arrow-right"
                >
                  {t('Next')}
                </Button>
              </View>
            </ContainerWidthLimit>
          </View>
        </Animated.ScrollView>
      </KeyboardAvoidingView>
    </KeyboardContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    alignItems: 'center',
  },
  content: {
    flex: 1,
    alignItems: 'center',
  },
  buttonContainer: {
    width: '60%',
  },

  button: {
    marginTop: 26,
    marginBottom: 22,
    height: 64,
  },
});
