import { PrinterPaper } from '_/interfaces/PrinterPaper';

import { LabelSize } from './reactNativeBrother';

interface BadgeConfigResult {
  rotate: number;
  labelSize: number;
}

export function getBadgeConfig(paper: string): BadgeConfigResult {
  switch (paper) {
    // Die-cut labels
    case PrinterPaper.Paper17mmx54mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeDieCutW17H54 };
    case PrinterPaper.Paper17mmx87mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeDieCutW17H87 };
    case PrinterPaper.Paper23mmx23mm:
      return { rotate: 0, labelSize: LabelSize.LabelSizeDieCutW23H23 };
    case PrinterPaper.Paper29mmx42mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeDieCutW29H42 };
    case PrinterPaper.Paper29mmx90mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeDieCutW29H90 };
    case PrinterPaper.Paper38mmx90mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeDieCutW38H90 };
    case PrinterPaper.Paper39mmx48mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeDieCutW39H48 };
    case PrinterPaper.Paper52mmx29mm:
      return { rotate: 0, labelSize: LabelSize.LabelSizeDieCutW52H29 };
    case PrinterPaper.Paper62mmx29mm:
      return { rotate: 0, labelSize: LabelSize.LabelSizeDieCutW62H29 };
    case PrinterPaper.Paper62mmx100mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeDieCutW62H100 };
    case PrinterPaper.Paper60mmx86mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeDieCutW60H86 };
    case PrinterPaper.Paper54mmx29mm:
      return { rotate: 0, labelSize: LabelSize.LabelSizeDieCutW54H29 };
    case PrinterPaper.Paper102mmx51mm:
      return { rotate: 0, labelSize: LabelSize.LabelSizeDieCutW102H51 };
    case PrinterPaper.Paper102mmx152mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeDieCutW102H152 };
    case PrinterPaper.Paper103mmx164mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeDieCutW103H164 };
    // Continuous labels
    case PrinterPaper.Paper12mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeRollW12 };
    case PrinterPaper.Paper29mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeRollW29 };
    case PrinterPaper.Paper38mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeRollW38 };
    case PrinterPaper.Paper50mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeRollW50 };
    case PrinterPaper.Paper54mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeRollW54 };
    case PrinterPaper.Paper62mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeRollW62 };
    case PrinterPaper.Paper62mmRB:
      return { rotate: 90, labelSize: LabelSize.LabelSizeRollW62RB };
    case PrinterPaper.Paper102mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeRollW102 };
    case PrinterPaper.Paper103mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeRollW103 };
    case PrinterPaper.PaperDT90mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeDTRollW90 };
    case PrinterPaper.PaperDT102mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeDTRollW102 };
    case PrinterPaper.PaperDT102mmx51mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeDTRollW102H51 };
    case PrinterPaper.PaperDT102mmx152mm:
      return { rotate: 90, labelSize: LabelSize.LabelSizeDTRollW102H152 };
    default:
      return { rotate: 90, labelSize: LabelSize.LabelSizeRollW62 };
  }
}
