import { colors } from '_/config/theme';
import { useCustomization } from '_/hooks/CustomizationContext';
import React from 'react';
import { View, StyleSheet } from 'react-native';

interface CircleCheckProps {
  isSelect: boolean;
}

const CircleCheck: React.FC<CircleCheckProps> = ({ isSelect }) => {
  const { customization } = useCustomization();

  return (
    <View
      style={[
        styles.container,
        { borderColor: isSelect ? customization?.mainColor || colors.primary : colors.dark3 },
      ]}
    >
      {isSelect && (
        <View
          style={[styles.content, { backgroundColor: customization?.mainColor || colors.primary }]}
        />
      )}
    </View>
  );
};

export default CircleCheck;

const styles = StyleSheet.create({
  container: {
    height: 22,
    width: 22,
    borderWidth: 2,
    borderRadius: 11,
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    height: 16,
    width: 16,
    borderRadius: 8,
  },
});
