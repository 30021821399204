import { IpadCallback } from '@w3lcome/types';
import { ipadCallbackApi, visitsApi } from '_/services/api';
import logger from '_/services/logger';
import React, { createContext, useContext, useEffect } from 'react';

import { useAuth } from './AuthContext';
import { useIntegrations } from './IntegrationsContext';
import { usePrinters } from './PrintersContext';

export interface CallbackContextData {}

const CallbackContext = createContext<CallbackContextData>({} as CallbackContextData);

type CallbackType = {
  children: React.ReactNode;
};

export const CallbackProvider: React.FC<CallbackType> = ({ children }) => {
  const { feathersApp, ipad } = useAuth();
  const { printVisitBadge } = usePrinters();
  const { runApprovalIntegrations } = useIntegrations();

  useEffect(() => {
    feathersApp?.service('ipad-callback').on('created', runCallback);

    return () => {
      feathersApp?.service('ipad-callback').off('created', runCallback);
    };
  }, [feathersApp]);

  async function runCallback(data: IpadCallback) {
    if (!data.visitId || data.ipadId !== ipad?.id) {
      return;
    }

    try {
      const visit = await visitsApi.getItem(data.visitId);

      if (data.type === 'print') {
        await printVisitBadge(visit);
      } else {
        await runApprovalIntegrations(visit);
      }

      await ipadCallbackApi.update(data.id, { executedAt: new Date() });
    } catch (error) {
      logger(error);
    }
  }

  return <CallbackContext.Provider value={{}}>{children}</CallbackContext.Provider>;
};

export function useCallback(): CallbackContextData {
  const context = useContext(CallbackContext);

  if (!context) {
    throw new Error('useCallback must be used within an CallbackProvider');
  }

  return context;
}
