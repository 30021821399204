import { merge } from 'lodash';
import { useState } from 'react';

export function useObjectState<T>(
  initialState: Partial<T>
): [Partial<T>, (value: Partial<T>) => void, (initialValues?: Partial<T>) => void] {
  const [data, setData] = useState<Partial<T>>(initialState);

  function setObjectData(values: Partial<T>) {
    setData((prevData) => ({ ...merge(prevData, values) }));
  }

  function resetObjectData(initialValues?: Partial<T>) {
    setData(initialValues || {});
  }

  return [data, setObjectData, resetObjectData];
}
