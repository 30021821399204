import { add, isAfter } from 'date-fns';

export function isDateAfterLimit(validFor?: number | null, last?: Date | string) {
  if (!last || validFor === null || validFor === undefined || validFor < 0) {
    return false;
  }

  const lastDate = new Date(last);
  const validUntilDate = add(lastDate, { days: validFor });
  const now = new Date();

  return isAfter(now, validUntilDate);
}
