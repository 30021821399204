import { useNavigation } from '@react-navigation/native';
import { images } from '_/assets';
import { Button, Header, Input } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import KeyboardContainer from '_/components/KeyboardContainer';
import { colors, fontSizes } from '_/config/theme';
import { AppRoute, LGPDCheckinInformationProps } from '_/navigation/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { showMessage } from 'react-native-flash-message';

import { useCustomizationLgpd } from '../../hooks/CustomizationLgpdContext';
import { useVisitLgpdContext } from '../../hooks/VisitLgpdContext';

const LgpdCheckinInformationScreen: React.FC = () => {
  const { t } = useTranslation();
  const { navigate } = useNavigation<LGPDCheckinInformationProps>();

  const { resetData, visit, getVisitDataByField, setVisit } = useVisitLgpdContext();
  const { customizationLgpd } = useCustomizationLgpd();

  const [isFocusedInput, setIsFocusedInput] = useState(false);
  const [isValidName, setIsValidName] = useState(true);
  const [name, setName] = useState(
    `${getVisitDataByField('FirstName')} ${getVisitDataByField('LastName')}`.trim()
  );

  const customizationLabel = customizationLgpd?.CompanyAuxText02?.toLocaleUpperCase();

  const checkIsValidName = () => {
    const isFullName = new RegExp(
      `^[a-zA-Zà-úÀ-Ú]([-']?[a-zA-Zà-úÀ-Ú]+)*( [a-zA-Zà-úÀ-Ú]([-']?[a-zA-Zà-úÀ-Ú]+)*)+$`
    );

    return customizationLgpd?.CompanyAuxChk02 ? true : isFullName.test(name.trim());
  };

  function handleSubmit() {
    const isValid = checkIsValidName();
    setIsValidName(isValid);

    if (!isValid) {
      showMessage({
        message: t('infoScreen.missingFieldsError'),
        backgroundColor: colors.danger,
      });
      return;
    }

    const parseName = name
      .trim()
      .split(' ')
      .filter((e) => e);

    setVisit({ FirstName: parseName[0], LastName: parseName.slice(1).join(' ') });

    if (!visit.IsValidGDPRSignature) {
      navigate(AppRoute.LGPD_TOTEM_CHECKIN_NDA);
      return;
    }

    navigate(AppRoute.LGPD_TOTEM_CHECKIN_MESSAGE);
  }

  return (
    <KeyboardContainer
      keyboardOpen={isFocusedInput}
      onChange={(text) => {
        setName(text);
      }}
      inputName="name"
      keyboardType="default"
    >
      <View style={[styles.container, { alignItems: 'center' }]}>
        <ContainerWidthLimit>
          <Header
            onGoBack={resetData}
            source={
              customizationLgpd?.CompanyLogo
                ? { uri: customizationLgpd?.CompanyLogo }
                : images.poweredByLogo
            }
          />
          <ScrollView keyboardShouldPersistTaps="always" contentContainerStyle={{ paddingTop: 24 }}>
            <View style={styles.content}>
              <View style={styles.innerContainer}>
                <Input
                  label={customizationLabel ?? ''}
                  value={getVisitDataByField('IdNumber') as string}
                  placeholder={customizationLabel}
                  style={{ letterSpacing: 2 }}
                  labelSize={fontSizes.xl}
                  required
                  staticLabel
                />
                <Input
                  label={customizationLgpd?.CompanyAuxChk02 ? t('Name') : t('fullName')}
                  placeholder={customizationLgpd?.CompanyAuxChk02 ? t('firstName') : t('fullName')}
                  onChangeText={(text) => {
                    setName(text);
                  }}
                  onFocus={() => setIsFocusedInput(true)}
                  onBlur={() => setIsFocusedInput(false)}
                  value={name}
                  labelSize={fontSizes.xl}
                  required
                  staticLabel
                  autoCapitalize="words"
                  containerStyle={{
                    marginTop: 32,
                    ...(!isValidName && {
                      borderBottomWidth: 6,
                      borderBottomColor: colors.danger,
                    }),
                  }}
                />
              </View>

              <Button
                backgroundColor={colors.primary}
                labelColor={colors.white}
                containerStyles={styles.buttonContainer}
                buttonStyles={[styles.button, { justifyContent: 'space-between' }]}
                rightIcon="arrow-right"
                onPress={handleSubmit}
              >
                {t('Confirm')}
              </Button>
            </View>
          </ScrollView>
        </ContainerWidthLimit>
      </View>
    </KeyboardContainer>
  );
};

export default LgpdCheckinInformationScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  content: {
    alignItems: 'center',
    flex: 1,
  },
  innerContainer: {
    flex: 1,
    width: '80%',
  },
  buttonContainer: {
    width: '60%',
  },
  button: {
    marginTop: 26,
    marginBottom: 32,
    height: 64,
  },
});
