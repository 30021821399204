import { CustomFieldValuesModel } from '@w3lcome/types';
import { Header } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import { colors } from '_/config/theme';
import { useCustomization } from '_/hooks/CustomizationContext';
import { useVisit } from '_/hooks/VisitContext';
import { useNextCheckinScreen } from '_/hooks/useNextCheckinScreen';
import { AppRoute } from '_/navigation/types';
import React, { useState, useEffect, useRef } from 'react';
import { View, StyleSheet, ScrollView, Animated, Platform } from 'react-native';

import { QuestionnaireQuestions } from './QuestionnaireQuestions';
import { QuestionnaireVideo } from './QuestionnaireVideo';

type QuestionnaireStep = 'video' | 'questions';

const CheckinQuestionnaireScreen: React.FC = () => {
  const { customization } = useCustomization();
  const [step, setStep] = useState<QuestionnaireStep>();
  const { goToNextScreen } = useNextCheckinScreen(AppRoute.CHECKIN_QUESTIONNARIE);
  const { setVisit } = useVisit();
  const scrollY = useRef(new Animated.Value(0)).current;
  const scrollViewRef = useRef<ScrollView>(null);

  const [scrollSize, setScrollSize] = useState(0);

  useEffect(() => {
    if (customization?.questionnaireVideoUrl) {
      setStep('video');
    } else {
      setStep('questions');
    }
  }, [customization]);

  function handlePressVideoNext() {
    if (customization?.questionnaireFields) {
      setStep('questions');
    } else {
      setVisit({ questionnaireAnsweredAt: new Date() });
      goToNextScreen();
    }
  }

  function handlePressQuestionsNext(questionnaireFields?: CustomFieldValuesModel[]) {
    setVisit({ questionnaireFields, questionnaireAnsweredAt: new Date() });
    goToNextScreen();
  }

  return (
    <Animated.ScrollView
      onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], {
        useNativeDriver: Platform.OS !== 'web',
      })}
      ref={scrollViewRef}
      style={StyleSheet.absoluteFill}
      onContentSizeChange={(w, h) => setScrollSize(h)}
      scrollEventThrottle={16}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      <View style={styles.container}>
        <ContainerWidthLimit>
          <Header />

          {step === 'video' && <QuestionnaireVideo onPressNext={handlePressVideoNext} />}

          {step === 'questions' && (
            <QuestionnaireQuestions
              scrollY={scrollY}
              scrollViewRef={scrollViewRef}
              scrollSize={scrollSize}
              onPressNext={handlePressQuestionsNext}
            />
          )}
        </ContainerWidthLimit>
      </View>
    </Animated.ScrollView>
  );
};

export default CheckinQuestionnaireScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    paddingBottom: 32,
    alignItems: 'center',
  },
});
