import Button from '_/components/Button';
import Text from '_/components/Text';
import { colors } from '_/config/theme';
import { useEnvironment } from '_/hooks/EnvironmentContext';
import React from 'react';
import { StyleSheet, View } from 'react-native';

interface StageBannerProps {
  children: React.ReactNode;
  isLogged?: boolean;
  signout: () => void;
}

export const StageBanner = ({ children, isLogged, signout }: StageBannerProps) => {
  const { environment } = useEnvironment();

  return (
    <View style={styles.container}>
      {environment === 'stg' && (
        <View style={styles.stageEnvironment}>
          <Text fontType="bold" style={styles.stageText}>
            Stage Environment
          </Text>
          {!!isLogged && (
            <Button
              small
              backgroundColor={colors.white}
              buttonStyles={styles.buttonStyles}
              onPress={() => signout()}
              textStyles={{ color: colors.warning }}
            >
              Logout
            </Button>
          )}
        </View>
      )}
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  stageEnvironment: {
    width: '100%',
    paddingHorizontal: 20,
    height: 40,
    backgroundColor: colors.warning,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  stageText: {
    color: colors.white,
  },
  buttonStyles: {
    height: 30,
  },
});
