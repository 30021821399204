import { colors } from '_/config/theme';
import { ExtendedCountryCode, ICountries } from '_/helpers/countryDials';
import React from 'react';
import { Image, StyleSheet, TouchableOpacity } from 'react-native';

import Text from '../Text';

interface CountryItemProps {
  country: ICountries;
  handleChangeCountryCode: (code: ExtendedCountryCode) => void;
}

export const CountryItem: React.FC<CountryItemProps> = ({ country, handleChangeCountryCode }) => {
  return (
    <TouchableOpacity
      delayPressIn={50}
      style={styles.container}
      onPress={() => handleChangeCountryCode(country.code)}
    >
      <Image source={country.flag} style={{ width: 36, height: 36 }} />
      <Text fontType="medium" size={16} style={{ marginLeft: 8 }}>
        {country.name}
      </Text>
      <Text color={colors.dark3} style={{ marginLeft: 8 }}>
        ({country.countryCode})
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
  },
});
