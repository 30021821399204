import { fontSizes } from '_/config/theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity } from 'react-native';

import Text from '../Text';

interface GDPRButtonProps {
  onOpen: () => void;
}

export const GDPRButton: React.FC<GDPRButtonProps> = ({ onOpen }) => {
  const { t } = useTranslation();

  return (
    <TouchableOpacity onPress={onOpen}>
      <View>
        <Text center size={fontSizes.lg}>
          {t('homeScreen.gdpr.buttonLine1')}
        </Text>
        <Text center fontType="medium" size={fontSizes.lg}>
          {t('homeScreen.gdpr.buttonLine2')}
        </Text>
      </View>
    </TouchableOpacity>
  );
};
