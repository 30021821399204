import { colors, fontSizes } from '_/config/theme';
import React, { useState } from 'react';
import { View, TouchableOpacity, StyleSheet, Dimensions } from 'react-native';

import Modal from '../Modal';
import Text from '../Text';

const { width } = Dimensions.get('window');

interface ModalDoubleButtonProps {
  isModalVisible: boolean;
  onClose: () => void;
  onFinish: () => void;
  title?: string;
  message?: string;
  messageBold?: string;
  onCloseText?: string;
  onFinishText?: string;
  textColorClose?: string;
  textColorFinish?: string;
  children?: React.ReactNode;
}

const ModalDoubleButton: React.FC<ModalDoubleButtonProps> = ({
  isModalVisible,
  title,
  message,
  messageBold,
  onClose,
  onFinish,
  onCloseText,
  onFinishText,
  textColorClose,
  textColorFinish,
  children,
}) => {

  const [buttonFinishPress,setButtonFinishPress] = useState(false);

  const onPressFinish = () => {
    setButtonFinishPress(true);
    onFinish();
  } 

  return (
    <Modal isVisible={isModalVisible}>
      <View style={styles.container}>
        <View style={{ marginHorizontal: width > 700 ? 24 : 12 }}>
          {!!title && (
            <Text
              style={{ marginTop: 12 }}
              center
              size={fontSizes.xl}
              fontType="semibold"
              color={colors.dark3}
            >
              {title}
            </Text>
          )}

          {!!message && (
            <Text
              style={{ marginTop: 6 }}
              center
              size={fontSizes.lg2}
              fontType="medium"
              color={colors.dark3}
            >
              {message}
            </Text>
          )}

          {children && children}

          {!!messageBold && (
            <Text
              style={{ marginTop: 6 }}
              center
              size={fontSizes.xl}
              fontType="semibold"
              color={colors.dark3}
            >
              {messageBold}
            </Text>
          )}
        </View>

        <View style={styles.buttons}>
          <TouchableOpacity style={styles.button} onPress={onClose}>
            <Text fontType="semibold" size={24} color={textColorClose ?? colors.dark3}>
              {onCloseText}
            </Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.button} onPress={onPressFinish} disabled={buttonFinishPress}>
            <Text fontType="semibold" size={24} color={textColorFinish ?? colors.dark3}>
              {onFinishText}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default ModalDoubleButton;

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    borderTopWidth: 1,
    borderTopColor: colors.light3,
    marginTop: 24,
  },
  button: {
    marginTop: 24,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
