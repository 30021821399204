import { images } from '_/assets';
import { Button, Text } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import { colors, fontSizes } from '_/config/theme';
import { useAuth } from '_/hooks/AuthContext';
import { useCustomization } from '_/hooks/CustomizationContext';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Image, Dimensions, TouchableOpacity } from 'react-native';
import * as Animatable from 'react-native-animatable';

const { width, height } = Dimensions.get('window');

const PaymentErrorScreen: React.FC = () => {
  const { t } = useTranslation();
  const { loadData, company } = useCustomization();
  const [loading, setLoading] = useState(false);
  const { signout } = useAuth();

  const handleLoadData = async () => {
    setLoading(true);
    await loadData();
    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <ContainerWidthLimit style={styles.center}>
        <View style={styles.content}>
          <Animatable.View
            duration={2500}
            animation="bounce"
            easing="ease-out"
            iterationCount="infinite"
            iterationDelay={20000}
            style={styles.contentImage}
          >
            <Image style={styles.image} source={images.paymentError} resizeMode="contain" />
          </Animatable.View>
          <View style={styles.contentTextAndButton}>
            <View style={{ marginBottom: 24 }}>
              <Text center size={fontSizes.xxl} fontType="bold" color={colors.light2}>
                {company?.name}
              </Text>
            </View>
            <View>
              <Text
                center
                style={styles.texts}
                size={fontSizes.xxl}
                fontType="bold"
                color={colors.dark1}
              >
                {t('paymentErrorScreen.ErrorDuringAccess')}
              </Text>
              <Text center style={styles.texts} size={fontSizes.lg2} color={colors.dark3}>
                {t('paymentErrorScreen.ContactAdm')}
              </Text>
            </View>
            <View style={styles.contentButton}>
              <Button loading={loading} onPress={handleLoadData}>
                {t('tryAgain')}
              </Button>
            </View>
          </View>
          <TouchableOpacity
            style={{ paddingBottom: 20 }}
            onPress={async () => {
              await signout();
            }}
          >
            <Text size={fontSizes.lg} fontType="semibold" color={colors.light1}>
              {t('removeIpad')}
            </Text>
          </TouchableOpacity>
        </View>
      </ContainerWidthLimit>
    </View>
  );
};

export default PaymentErrorScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingHorizontal: 42,
  },
  content: {
    flex: 1,
    textAlign: 'center',
    alignItems: 'center',
    paddingVertical: 32,
    width: '90%',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  contentImage: {
    flex: 1,
    width: width / 2 + 80,
    height: height / 2 - 80,
  },
  contentTextAndButton: {
    flex: 1,
  },
  texts: {
    marginTop: 18,
  },
  contentButton: {
    flex: 1,
    justifyContent: 'center',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
