// import { Dimensions } from 'react-native';
import {
  widthPercentageToDP as wp2dp,
  heightPercentageToDP as hp2dp,
} from 'react-native-responsive-screen';

// iPad mini
export const BASE_WIDTH = 768;
export const BASE_HEIGHT = 1024;
export const BASE_SCALE = 2;

/**
 * Width-Percentage
 * Converts width dimension to percentage
 */
export const wp = (dimension: number) => {
  return wp2dp((dimension / BASE_WIDTH) * 100 + '%');
};

/**
 * Height-Percentage
 * Converts width dimension to percentage
 */
export const hp = (dimension: number) => {
  return hp2dp((dimension / BASE_HEIGHT) * 100 + '%');
};
