import { useEffect, useState } from 'react';
import { Platform } from 'react-native';

export function useIsDesktopConnected() {
  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    if (Platform.OS !== 'web') return;

    setIsConnected(navigator.onLine);

    const updateConnectionStatus = () => setIsConnected(navigator.onLine);

    window.addEventListener('online', updateConnectionStatus);
    window.addEventListener('offline', updateConnectionStatus);

    return () => {
      window.removeEventListener('online', updateConnectionStatus);
      window.removeEventListener('offline', updateConnectionStatus);
    };
  }, []);

  return isConnected;
}
