import { HTMLRender, Text } from '_/components';
import { colors } from '_/config/theme';
import { useCustomization } from '_/hooks/CustomizationContext';
import React from 'react';
import { View, ActivityIndicator } from 'react-native';

interface CustomMessageProps {
  message: string;
  error?: string;
  loading: boolean;
}

const CustomMessage: React.FC<CustomMessageProps> = ({ message, error, loading }) => {
  const { customization } = useCustomization();

  if (loading) {
    return <ActivityIndicator size="large" color={customization?.mainColor || colors.primary} />;
  }

  if (error) {
    return (
      <Text center color={colors.danger} size={28}>
        {error}
      </Text>
    );
  }

  return (
    <View pointerEvents="none">
      {!!customization?.ipadWelcomeMessage && <HTMLRender html={message} />}
    </View>
  );
};

export default CustomMessage;
