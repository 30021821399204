import React from 'react';
import {
  Animated,
  Dimensions,
  Linking,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import Constants from 'expo-constants';
import { Feather } from '@expo/vector-icons';
import { BlurView } from 'expo-blur';
import { useTranslation } from 'react-i18next';
import { View } from 'react-native-animatable';

import Text from '../Text';
import { colors, defaultShadow, fontSizes, whiteOpacity } from '_/config/theme';
import { useAuth } from '_/hooks/AuthContext';

const AppVersionModal: React.FC = () => {
  const { t } = useTranslation();
  const { appVersionData, setAppVersionData, updateAppVersion } = useAuth();

  if (!appVersionData) {
    return null;
  }

  const onReject = () => {
    setAppVersionData(null);
  };

  const onAccept = () => {
    if (appVersionData.isOtaUpdate || Platform.OS === 'web') {
      updateAppVersion();
    } else {
      const appLink =
        Platform.OS === 'ios'
          ? Constants.expoConfig?.extra?.totemLinkIos
          : Constants.expoConfig?.extra?.totemLinkAndroid;

      Linking.openURL(appLink as string);
    }
  };

  return (
    <Animated.View style={styles.container}>
      <BlurView intensity={80} style={styles.innerContainer}>
        <Animated.View style={styles.content}>
          <View style={styles.header}>
            <Text fontType="bold" center size={fontSizes.xl} color={colors.black}>
              <Feather name="alert-triangle" color={colors.warning} size={fontSizes.xl2} />{' '}
              {t('appVersionModal.title')}
            </Text>
          </View>
          <ScrollView>
            <View style={styles.subtitle}>
              <Text color={colors.black} size={fontSizes.lg}>
                {t('appVersionModal.description')}
              </Text>
              {!appVersionData.showUpdateMessage && (
                <View style={styles.description}>
                  <Text color={colors.dark1} size={fontSizes.md}>
                    <Feather name="help-circle" color={colors.black} size={fontSizes.md} />{' '}
                    {appVersionData.updateMessage}
                  </Text>
                </View>
              )}
            </View>
          </ScrollView>
          <View style={styles.buttons}>
            {!appVersionData.mandatoryUpdate && (
              <TouchableOpacity style={styles.button} onPress={onReject}>
                <Text color={colors.danger} size={fontSizes.lg} fontType="medium">
                  {t('doItLater')}
                </Text>
              </TouchableOpacity>
            )}
            <TouchableOpacity style={styles.button} onPress={onAccept}>
              <Text color={colors.dark3} size={fontSizes.xl} fontType="bold">
                {t('Update')}
              </Text>
            </TouchableOpacity>
          </View>
        </Animated.View>
      </BlurView>
    </Animated.View>
  );
};

export default AppVersionModal;

const { width, height } = Dimensions.get('window');

const styles = StyleSheet.create({
  container: {
    zIndex: 999,
    backgroundColor: Platform.OS === 'android' ? whiteOpacity : colors.transparent,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    elevation: 5,
  },
  innerContainer: {
    padding: 40,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    textAlign: 'center',
    paddingVertical: 18,
  },
  content: {
    backgroundColor: colors.white,
    width: width > height ? height : '85%',
    maxHeight: width > height ? '90%' : '70%',
    borderRadius: 12,
    borderColor: colors.light3,
    borderWidth: 1,
    ...defaultShadow,
  },
  subtitle: {
    alignItems: 'center',
    paddingHorizontal: 24,
    marginBottom: 12,
  },
  description: {
    alignItems: 'center',
    backgroundColor: colors.light3,
    borderRadius: 4,
    paddingVertical: 8,
    paddingHorizontal: 24,
    marginVertical: 4,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 8,
    borderTopColor: colors.light3,
    borderTopWidth: 2,
  },
  button: {
    flex: 1,
    alignItems: 'center',
    padding: 16,
  },
});
