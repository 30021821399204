import * as FileSystem from 'expo-file-system';

export async function downloadAndConvertToBase64(imageUrl?: string | null) {
  if (!imageUrl) {
    return '';
  }

  try {
    const downloadResult = await FileSystem.downloadAsync(
      imageUrl,
      FileSystem.documentDirectory + 'image.jpg'
    );

    const base64Result = await FileSystem.readAsStringAsync(downloadResult.uri, {
      encoding: 'base64',
    });

    return `data:image/jpg;base64,${base64Result}`;
  } catch (error) {
    console.error('Error downloading and converting image:', error);
  }

  return '';
}
