import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { Plan } from '@w3lcome/types';
import { useAuth } from '_/hooks/AuthContext';
import { useCustomization } from '_/hooks/CustomizationContext';
import { LoadingAppScreen, LoadingScreen, PinScreen, PaymentErrorScreen } from '_/screens';
import { useCustomizationLgpd } from '_/screens/LgpdCheckinFlow/hooks/CustomizationLgpdContext';
import React, { useMemo } from 'react';

import LgpdCheckinStackScreen from './LgpdCheckinStackScreen';
import MainStackScreen from './MainStackScreen';
import { AppRoute } from './types';

const RootStack = createStackNavigator();

export default function Routes() {
  const { token, loading, endpointLgpd, authenticationLgpd } = useAuth();
  const { company } = useCustomization();
  const { customizationLgpd } = useCustomizationLgpd();

  const isCompanyActive = useMemo(() => {
    if (!company?.planIsActive) {
      return false;
    }

    if (company.plan !== Plan.TRIAL) {
      return true;
    }

    const date = new Date(company?.trialUntil).toISOString();
    const dateToday = new Date().toISOString();
    return Date.parse(dateToday) < Date.parse(date);
  }, [company]);

  function getRoute() {
    if (loading) {
      return <RootStack.Screen name={AppRoute.LOADING} component={LoadingAppScreen} />;
    }

    if (endpointLgpd && authenticationLgpd) {
      if (customizationLgpd) {
        return (
          <RootStack.Screen name={AppRoute.LGPD_TOTEM_CHECKIN} component={LgpdCheckinStackScreen} />
        );
      } else {
        return <RootStack.Screen name={AppRoute.LOADING} component={LoadingScreen} />;
      }
    }

    if (!token) {
      return <RootStack.Screen name={AppRoute.PIN} component={PinScreen} />;
    }

    if (company) {
      if (!isCompanyActive) {
        return <RootStack.Screen name={AppRoute.PAYMENT_ERROR} component={PaymentErrorScreen} />;
      }
      return <RootStack.Screen name={AppRoute.ROOT_STACK_HOME} component={MainStackScreen} />;
    }

    return <RootStack.Screen name={AppRoute.LOADING} component={LoadingScreen} />;
  }

  return (
    <NavigationContainer>
      <RootStack.Navigator screenOptions={{ headerShown: false }}>{getRoute()}</RootStack.Navigator>
    </NavigationContainer>
  );
}
