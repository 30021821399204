import { Text, Button } from '_/components';
import CodeInput from '_/components/CodeInput';
import KeyboardContainer from '_/components/KeyboardContainer';
import { colors } from '_/config/theme';
import { wp } from '_/helpers/dimension';
import { useAuth } from '_/hooks/AuthContext';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Keyboard } from 'react-native';
import { showMessage } from 'react-native-flash-message';

export default function PinView() {
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState('');
  const inputRef = useRef<any>();
  const { signin } = useAuth();
  const { t } = useTranslation();
  const [isFocusedInput, setIsFocusedInput] = useState(false);

  function onChangeText(value: string) {
    const changed = value !== pin;

    setPin(value.toUpperCase());

    if (changed) {
      if (value.length === 6) {
        Keyboard.dismiss();
      }
    }
  }

  async function handleSubmit() {
    if (pin.length < 6) {
      inputRef.current?.focus();
      return;
    }

    try {
      setLoading(true);
      await signin(pin);
    } catch (error) {
      showMessage({
        message: t('error'),
        description: t('invalidPin'),
        backgroundColor: colors.danger,
      });
      setPin('');
    } finally {
      setLoading(false);
    }
  }

  return (
    <View style={styles.container}>
      <KeyboardContainer
        keyboardOpen={isFocusedInput}
        onChange={onChangeText}
        inputName="pin"
        keyboardType="default"
      >
        <Text size={24} center>
          {t('insertPinCode')}
        </Text>
        <View>
          <CodeInput
            pin={pin}
            inputRef={inputRef}
            onChangeText={onChangeText}
            setIsFocusedInput={setIsFocusedInput}
          />
        </View>
        <Button large loading={loading} onPress={handleSubmit}>
          {t('Start')}
        </Button>
      </KeyboardContainer>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 20,
    borderRadius: 12,
    borderWidth: 2,
    borderColor: colors.light3,
    backgroundColor: colors.white,
    width: wp(500),
  },
});
