import { fontSizes } from '_/config/theme';
import { useLanguage } from '_/hooks/LanguageContext';
import React from 'react';
import { View, StyleSheet, TouchableOpacity, Image, StyleProp, ViewStyle } from 'react-native';

import Text from '../Text';

interface LanguageButtonProps {
  textColor: string;
  onPress: () => void;
  containerStyle?: StyleProp<ViewStyle>;
}

const LanguageButton: React.FC<LanguageButtonProps> = ({ onPress, containerStyle, textColor }) => {
  const { language } = useLanguage();

  return (
    <View style={[styles.viewBottomContent, containerStyle]}>
      <TouchableOpacity onPress={onPress}>
        <View style={styles.buttonBottomContent}>
          <Image style={styles.flag} source={language.flag} />
          <Text size={fontSizes.lg} style={{ marginHorizontal: 8, color: textColor }}>
            {language.lang}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default LanguageButton;

const styles = StyleSheet.create({
  viewBottomContent: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonBottomContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  flag: {
    width: 30,
    height: 30,
    marginRight: 8,
  },
});
