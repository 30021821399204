import logger from '_/services/logger';
import * as Updates from 'expo-updates';
import React, { ErrorInfo } from 'react';
import { Platform } from 'react-native';
import * as Sentry from '@sentry/react-native';

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger({ error, errorInfo });

    if (Platform.OS === 'web') {
      Sentry?.captureException({ error, errorInfo });
      window?.location?.reload();
    } else {
      Sentry?.captureException({ error, errorInfo });
      Updates.reloadAsync();
    }
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
