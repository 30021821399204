import { Feather } from '@expo/vector-icons';
import { colors, defaultShadow, fontSizes, whiteOpacity } from '_/config/theme';
import { BlurView } from 'expo-blur';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, Easing, Platform, StyleSheet, TouchableOpacity, View } from 'react-native';

import Text from '../Text';

interface ModalProps {
  onClose?: () => void;
  onFinishHide?: () => void;
  icon?: string;
  iconColor?: string;
  title?: string;
  message?: string;
  buttonText?: string;
  isVisible: boolean;
  animationDurationMs?: number;
  children?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  onClose,
  onFinishHide,
  icon,
  iconColor = colors.dark3,
  buttonText,
  title,
  message,
  isVisible,
  animationDurationMs = 600,
  children,
}) => {
  const { t } = useTranslation();
  const animation = useRef(new Animated.Value(0));

  useEffect(() => {
    Animated.timing(animation.current, {
      duration: animationDurationMs,
      toValue: isVisible ? 1 : 0,
      easing: Easing.linear,
      useNativeDriver: Platform.OS !== 'web',
    }).start(() => {
      if (!isVisible) {
        onFinishHide?.();
      }
    });
  }, [isVisible, animationDurationMs]);

  return (
    <Animated.View
      pointerEvents={isVisible ? 'auto' : 'none'}
      style={[
        styles.container,
        {
          opacity: animation.current.interpolate({
            inputRange: [0, 0.2],
            outputRange: [0, 1],
            extrapolate: 'clamp',
          }),
          transform: [
            {
              scale: animation.current.interpolate({
                inputRange: [0, 0.1],
                outputRange: [0, 1],
                extrapolate: 'clamp',
              }),
            },
          ],
        },
      ]}
    >
      <BlurView intensity={80} style={styles.innerContainer}>
        <Animated.View
          style={[
            styles.modal,
            {
              opacity: animation.current.interpolate({
                inputRange: [0, 0.4, 1],
                outputRange: [0, 0, 1],
                extrapolate: 'clamp',
              }),
            },
          ]}
        >
          {children ? (
            children
          ) : (
            <>
              {!!icon && <Feather name={icon as any} size={fontSizes.xxxl} color={iconColor} />}
              {!!title && (
                <Text
                  style={{ marginTop: 12 }}
                  center
                  size={fontSizes.xl3}
                  fontType="medium"
                  color={colors.dark3}
                >
                  {title}
                </Text>
              )}
              {!!message && (
                <Text
                  style={{ marginTop: 12, paddingHorizontal: 24 }}
                  center
                  size={fontSizes.lg2}
                  fontType="medium"
                  color={colors.dark3}
                >
                  {message}
                </Text>
              )}
              <View style={styles.divider} />
              <TouchableOpacity
                hitSlop={{ top: 24, right: 12, bottom: 24, left: 12 }}
                style={styles.button}
                onPress={onClose}
              >
                <Text fontType="bold" size={24} color={colors.dark3}>
                  {buttonText || t('Ok')}
                </Text>
              </TouchableOpacity>
            </>
          )}
        </Animated.View>
      </BlurView>
    </Animated.View>
  );
};

export default Modal;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 900,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: Platform.OS === 'android' ? whiteOpacity : colors.transparent,
  },
  innerContainer: {
    padding: 40,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    backgroundColor: colors.white,
    borderRadius: 8,
    maxWidth: '80%',
    width: 600,
    paddingVertical: 24,
    alignItems: 'center',
    borderColor: colors.light3,
    borderWidth: 1,
    ...defaultShadow,
  },
  divider: {
    height: 1,
    borderRadius: 2,
    backgroundColor: colors.light3,
    width: '100%',
    marginVertical: 24,
  },
  button: {
    width: '100%',
    alignItems: 'center',
  },
});
