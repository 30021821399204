import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import apiConfig from '_/config/app';
import { useRef, useCallback } from 'react';
import io from 'socket.io-client';

import { useEnvironment } from './EnvironmentContext';

export function useSocket() {
  const { environment } = useEnvironment();
  const socket = useRef<SocketIOClient.Socket>();

  const initSocket = useCallback(
    (token: string) => {
      const baseUrl = environment === 'stg' ? apiConfig.apiStgUrl : apiConfig.apiUrl;
      socket.current = io(`${baseUrl}?ipadauthorization=${token}`, {
        transports: ['websocket'],
        upgrade: false,
      });

      const app = feathers();

      app.configure(socketio(socket.current));

      return app;
    },
    [environment]
  );

  const closeSocket = useCallback(() => {
    socket.current?.close();
  }, []);

  return { initSocket, closeSocket };
}
