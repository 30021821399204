import React, { createContext, useContext, useEffect, useState, useCallback, useRef } from 'react';
import { Platform } from 'react-native';
import axios from 'axios';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as Network from 'expo-network';
import * as Updates from 'expo-updates';
import feathers from '@feathersjs/feathers';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNetInfo } from '@react-native-community/netinfo';
import { IpadLang, IpadsModel, VisitsModel } from '@w3lcome/types';
import { useTranslation } from 'react-i18next';

import appConfig from '_/config/app';
import { authApi, hikiCentralApi, ipadApi } from '_/services/api';
import logger from '_/services/logger';
import DeliveryDB from '_/services/sqlite/DeliveryDB';
import VisitDB from '_/services/sqlite/VisitDB';

import { StageBanner } from '_/components/StageBanner';
import { useSocket } from './useSocket';

export interface AuthContextData {
  signin(pin: string): Promise<void>;
  signout(): Promise<void>;
  token: string | null;
  ipad: IpadsModel | null;
  loading: boolean;
  reloadIpad: () => void;
  syncDeviceVersion: (loadIpad: boolean) => Promise<void>;
  changeIpadLang: (currentLanguage: IpadLang) => void;
  feathersApp: feathers.Application | undefined;
  endpointLgpd: string | null;
  authenticationLgpd: string | null;
  getLgpdAuthentication: (url: string, user: string, pass: string) => Promise<void>;
  appVersionData: { [key: string]: any } | null;
  setAppVersionData: (data: { [key: string]: any } | null) => void;
  updateAppVersion: () => void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

type AuthType = {
  children: React.ReactNode;
};

export const AuthProvider: React.FC<AuthType> = ({ children }) => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState<string | null>(null);
  const [ipad, setIpad] = useState<IpadsModel | null>(null);
  const [endpointLgpd, setEndpointLgpd] = useState<string | null>(null);
  const [authenticationLgpd, setAuthenticationLgpd] = useState<string | null>(null);
  const [appVersionData, setAppVersionData] = useState<{ [key: string]: any } | null>(null);

  const [feathersApp, setFeathersApp] = useState<feathers.Application>();
  const { closeSocket, initSocket } = useSocket();

  const netInfo = useNetInfo();

  useEffect(() => {
    feathersApp?.service('ipads').on('patched', () => reloadIpad(ipad?.id));
    feathersApp?.service('ipads').on('removed', (data: IpadsModel) => removeIpad(data.id));

    // if (ipad?.hasFaceRecognitionIntegration) {
    //   feathersApp?.service('visits').on('patched', sendFacePicture);
    // }
    feathersApp?.service('app-versions').on('patched', verifyAppVersion);

    return () => {
      feathersApp?.service('ipads').off('patched', () => reloadIpad(ipad?.id));
      feathersApp?.service('ipads').off('removed', (data: IpadsModel) => removeIpad(data.id));

      // if (ipad?.hasFaceRecognitionIntegration) {
      //   feathersApp?.service('visits').off('patched', sendFacePicture);
      // }
      feathersApp?.service('app-versions').off('patched', verifyAppVersion);
    };
  }, [feathersApp, ipad?.id]);

  const feathersAppRef = useRef<feathers.Application | null>(null);

  useEffect(() => {
    if (netInfo.isConnected && !feathersAppRef.current) {
      if (token) {
        feathersAppRef.current = initSocket(token);
        setFeathersApp(feathersAppRef.current);
      }

      return () => {
        if (feathersAppRef.current) {
          feathersAppRef.current = null;
          closeSocket();
        }
      };
    }
  }, [token, netInfo.isConnected, initSocket]);

  function changeIpadLang(currentLanguage: IpadLang) {
    if (!ipad) {
      return;
    }

    setIpad({ ...ipad, currentLanguage } as IpadsModel);
    ipadApi.update(ipad?.id, { currentLanguage });
  }

  useEffect(() => {
    const version = Constants.expoConfig?.version;
    console.log(`Version: ${ipad?.currentVersion}/${version}`);

    if (ipad && ipad?.currentVersion !== version) {
      ipadApi.update(ipad?.id as string, { currentVersion: version } as IpadsModel);
    }
  }, [ipad?.id, ipad?.currentVersion]);

  const reloadIpad = useCallback(async (ipadId = ipad?.id) => {
    if (!ipadId) {
      return;
    }

    try {
      const updatedIpad = await ipadApi.getItem(ipadId);
      setIpad(updatedIpad);
      await AsyncStorage.setItem(appConfig.ipadKey, JSON.stringify(updatedIpad));
    } catch (error) {
      logger(error);
      if ((error as any)?.response?.status === 404) {
        signout();
      }
    }
  }, []);

  function removeIpad(id: string) {
    if (id === ipad?.id) {
      signout();
    }
  }

  useEffect(() => {
    async function loadStoragedToken(): Promise<void> {
      const storedToken = await AsyncStorage.getItem(appConfig.tokenKey);
      const storedIpad = await AsyncStorage.getItem(appConfig.ipadKey);

      if (storedIpad && storedToken) {
        const parsedIpad = JSON.parse(storedIpad) as IpadsModel;
        setIpad(parsedIpad);
        setToken(storedToken);
        reloadIpad(parsedIpad.id);
      }

      setLoading(false);
    }

    loadStoragedToken();
  }, [reloadIpad, ipad?.id]);

  useEffect(() => {
    setLoading(true);
    async function loadStoredEndpoint() {
      const storedEndpoint = await AsyncStorage.getItem(appConfig.lgpdEndpoint);
      const storedAuthentication = await AsyncStorage.getItem(appConfig.lgpdAuthentication);

      if (storedEndpoint && storedAuthentication) {
        setEndpointLgpd(storedEndpoint);
        setAuthenticationLgpd(storedAuthentication);
      }
      setLoading(false);
    }

    loadStoredEndpoint();
  }, [endpointLgpd]);

  useEffect(() => {
    syncDeviceVersion(false);
  }, [ipad]);

  useEffect(() => {
    const checkAppVersionUpdates = async () => {
      try {
        const { data } = await axios.get(`${appConfig.apiUrl}/app-versions`, {
          params: {
            appType: 'totem',
            appSlug: Constants.expoConfig?.slug,
          },
          headers: {
            authorization: Constants.expoConfig?.extra?.appVersionsSecret,
          },
        });
        const appVersionResponse = data?.data?.[0];

        if (
          appVersionResponse &&
          appVersionResponse.latestVersion !== Constants.expoConfig?.version
        ) {
          setAppVersionData(appVersionResponse);
        }
      } catch (error) {
        logger(error);
        throw error;
      }
    };

    checkAppVersionUpdates();
  }, []);

  const syncDeviceVersion = async (loadIpadData: boolean) => {
    if (loadIpadData && ipad) {
      reloadIpad(ipad.id);
    }
    const ipadsModels = {} as IpadsModel;
    const ip = await Network.getIpAddressAsync();
    const osVersion = Device.osVersion;

    if (ipad && ipad?.wifiIp !== ip) {
      ipadsModels.wifiIp = ip;
    }

    if (ipad && ipad?.deviceVersion !== osVersion) {
      ipadsModels.deviceVersion = osVersion;
    }

    if (Object.keys(ipadsModels).length > 0) {
      await ipadApi.update(ipad?.id as string, ipadsModels);
    }
  };

  const getLgpdAuthentication = async (url: string, user: string, pass: string) => {
    try {
      await axios.get(`${url}/W-AccessAPI/v1/companies/gdpr`, {
        headers: {
          WAccessAuthentication: `${user}:${pass}`,
          WAccessUtcOffset: '-180',
        },
      });

      setEndpointLgpd(url);
      setAuthenticationLgpd(`${user}:${pass}`);
      await AsyncStorage.setItem(appConfig.lgpdEndpoint, url);
      await AsyncStorage.setItem(appConfig.lgpdAuthentication, `${user}:${pass}`);
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  const signin = async (pin: string) => {
    try {
      const result = await authApi.signin(pin, i18n.language);
      const { accessToken, ipad } = result;
      setToken(accessToken);
      setIpad(ipad);
      await AsyncStorage.setItem(appConfig.tokenKey, accessToken);
      await AsyncStorage.setItem(appConfig.ipadKey, JSON.stringify(ipad));
      VisitDB.init();
      DeliveryDB.init();
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  const signout = async () => {
    try {
      AsyncStorage.getAllKeys()
        .then((keys) => {
          const removeKeys = keys.filter((key) => key !== appConfig.companyKey);
          AsyncStorage.multiRemove(removeKeys);
        })
        .catch(() => null);
      authApi.signout();
      setToken(null);
      setIpad(null);
      setEndpointLgpd(null);
      setAuthenticationLgpd(null);
    } catch (error) {
      logger(error);
    }
  };

  // const getBase64FromUrl = async (imageUrl?: string | null): Promise<void | string> => {
  //   if (!imageUrl) {
  //     logger('Image URL is empty');
  //     return;
  //   }

  //   const response = await fetch(imageUrl);
  //   const blob = await response.blob();

  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const base64String = (reader?.result as string)?.split(',')[1];
  //       resolve(base64String);
  //     };
  //     reader.onerror = (error) => {
  //       logger(error);
  //       reject();
  //     };
  //     reader.readAsDataURL(blob);
  //   });
  // };

  // Obs: não usaremos mais essa metodologia até segundo plano
  // const sendFacePicture = async (data: VisitsModel) => {
  //   // TODO: image must have of 10kb to 100kb
  //   const base64Image = await getBase64FromUrl(data?.pictureMediumUrl);

  //   // Para poder testar por enquanto
  //   console.log('[HikiCentral] - base64Image', base64Image);

  //   if (base64Image) await hikiCentralApi.sendFacePicture(base64Image);
  // };

  const reloadApp = async () => {
    if (Platform.OS === 'web') {
      window?.location?.reload();
    } else {
      if (!__DEV__) await Updates.fetchUpdateAsync();
      await Updates.reloadAsync();
    }
  };

  const updateAppVersion = async () => {
    if (appVersionData) {
      setLoading(true);

      await ipadApi.update(
        ipad?.id as string,
        { currentVersion: appVersionData.latestVersion } as IpadsModel
      );

      setAppVersionData(null);
      reloadApp();
    }
  };

  const verifyAppVersion = async (data: any) => {
    const { appType, appSlug, latestVersion } = data || {};
    if (
      appType === 'totem' &&
      appSlug === Constants.expoConfig?.slug &&
      latestVersion !== ipad?.currentVersion
    ) {
      setAppVersionData(data);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ipad,
        token,
        signin,
        signout,
        loading,
        reloadIpad,
        changeIpadLang,
        feathersApp,
        endpointLgpd,
        getLgpdAuthentication,
        authenticationLgpd,
        syncDeviceVersion,
        appVersionData,
        setAppVersionData,
        updateAppVersion,
      }}
    >
      <StageBanner isLogged={!!ipad} signout={signout}>
        {children}
      </StageBanner>
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
