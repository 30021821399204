import { IpadIntegrationsModels } from '@w3lcome/types';
import { VisitsModelCheckin } from '_/interfaces/VisitsModelCheckin';
import axios from 'axios';

export async function runSecullumIntegration(
  visit: Partial<VisitsModelCheckin>,
  integration: IpadIntegrationsModels
) {
  const url = integration.content.url;

  const headers = {
    'Content-Type': 'application/json',
  };

  await axios.post(url, visit, { headers });
}
