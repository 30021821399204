import { colors } from '_/config/theme';
import React from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  TextInput,
  Platform,
  KeyboardTypeOptions,
} from 'react-native';

import Text from '../Text';

interface CodeInputProps {
  pin: string;
  inputRef: React.MutableRefObject<any>;
  onChangeText(value: string): void;
  setIsFocusedInput?: (value: boolean) => void;
  keyboardType?: KeyboardTypeOptions;
}

const CodeInput: React.FC<CodeInputProps> = ({
  pin,
  inputRef,
  onChangeText,
  setIsFocusedInput,
  keyboardType = 'ascii-capable',
}) => {
  function onPressCode() {
    inputRef.current?.focus();
  }

  function Item({ index }: { index: number }) {
    return (
      <View style={styles.codeBorder}>
        <Text
          uppercase
          size={32}
          fontType="semibold"
          color={colors.dark1}
          style={styles.codeNumber}
        >
          {pin[index]}
        </Text>
      </View>
    );
  }

  return (
    <View>
      <View style={styles.codeContainer}>
        <TouchableOpacity
          hitSlop={{ top: 12, right: 12, bottom: 12, left: 12 }}
          onPress={onPressCode}
          style={{ alignItems: 'stretch' }}
          activeOpacity={1}
        >
          <View style={styles.itemsContainer}>
            {[0, 1, 2, 3, 4, 5].map((i) => (
              <Item key={`input-code-${i}`} index={i} />
            ))}
          </View>
        </TouchableOpacity>
      </View>
      <TextInput
        autoCapitalize="characters"
        autoFocus={Platform.OS === 'web'}
        caretHidden
        keyboardType={keyboardType}
        value={pin}
        maxLength={6}
        ref={inputRef}
        style={styles.textInput}
        onChangeText={onChangeText}
        onFocus={() => setIsFocusedInput?.(true)}
        onBlur={() => setIsFocusedInput?.(false)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  codeContainer: {
    marginVertical: 20,
  },
  codeBorder: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 76,
    borderWidth: 1,
    borderColor: colors.light3,
    borderRadius: 4,
    marginHorizontal: 4,
  },
  codeNumber: {
    lineHeight: 42,
  },
  textInput: {
    position: 'absolute',
    height: 110,
    opacity: 0,
    width: '100%',
  },
  itemsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default CodeInput;
