import { useAuth } from '_/hooks/AuthContext';
import { useObjectState } from '_/hooks/useObjectState';
import { VisitLgpdModel } from '_/interfaces/VisitLgpdTotem';
import logger from '_/services/logger';
import axios from 'axios';
import * as FileSystem from 'expo-file-system';
import * as ImageManipulator from 'expo-image-manipulator';
import React, { createContext, useContext, useState } from 'react';
import { Platform } from 'react-native';
import uuid from 'react-native-uuid';

interface VisitLgpdContextData {
  visit: Partial<VisitLgpdModel>;
  setVisit: (value: Partial<VisitLgpdModel>) => void;
  resetData: () => void;
  getVisitDataByField: (value: 'IdNumber' | 'FirstName' | 'LastName') => string | number;
  visitCheckin: (visit: Partial<VisitLgpdModel>) => Promise<void>;
  getVisitorByDocument: () => Promise<void>;
  setIsAllowText(value: boolean): void;
  isAllowText: boolean;
}

interface SignatureFile {
  uri: string;
  name: string;
  type: string;
}

const VisitLgpdContext = createContext<VisitLgpdContextData>({} as VisitLgpdContextData);

type VisitLgpdType = {
  children: React.ReactNode;
};

export const VisitLgpdProvider: React.FC<VisitLgpdType> = ({ children }) => {
  const [visit, setVisit, resetVisit] = useObjectState<Partial<VisitLgpdModel>>({});
  const { endpointLgpd, authenticationLgpd } = useAuth();
  const [isAllowText, setIsAllowText] = useState(false);

  const headers = {
    WAccessAuthentication: authenticationLgpd ?? '',
    WAccessUtcOffset: '-180',
  };

  function resetData() {
    resetVisit();
    setIsAllowText(false);
  }

  function getVisitDataByField(field: 'IdNumber' | 'FirstName' | 'LastName') {
    if (!field) {
      return '';
    }

    return visit?.[field] || '';
  }

  async function getVisitorByDocument() {
    try {
      const { data } = await axios.get<VisitLgpdModel[]>(
        `${endpointLgpd}/W-AccessAPI/v1/cardholders`,
        {
          params: {
            offset: 0,
            limit: 1,
            idNumber: visit.IdNumber,
            chType: 1,
          },
          headers,
        }
      );

      if (data[0]) {
        setVisit(data[0]);
      }
    } catch (error) {
      logger(error);
    }
  }

  async function base64ToBlob(base64: string = '') {
    try {
      const filename = `${visit.FirstName}-${visit.LastName}-${uuid.v4().toString()}.png`;

      if (Platform.OS === 'web') {
        const base64Response = await fetch(base64);
        const blob = await base64Response.blob();
        const file = new File([blob], filename, { type: blob.type });

        return file;
      } else {
        const { uri } = await ImageManipulator.manipulateAsync(base64);

        return {
          uri,
          name: filename,
          type: 'image/png',
        };
      }
    } catch (error) {
      logger(error);
    }

    return null;
  }

  async function deleteLocalFile(file: SignatureFile) {
    if (file.uri) {
      await FileSystem.deleteAsync(file.uri);
    }
  }

  async function updateCHGDPRS(visit: Partial<VisitLgpdModel>) {
    try {
      const formData = new FormData();

      const signature = await base64ToBlob(visit.signatureBase64);

      formData.append('chid', `${visit.CHID}`);
      if (signature) {
        formData.append('photoJpegData', signature as Blob);
      }

      await axios.post(
        `${endpointLgpd}/W-AccessAPI/v1/cardholders/${visit.CHID}/UpdateCHGDPRSubscription`,
        formData,
        {
          headers: {
            ...headers,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      deleteLocalFile(signature as SignatureFile);
    } catch (error) {
      logger(error);
    }
  }

  async function addCHWithGDPR(visit: Partial<VisitLgpdModel>) {
    try {
      const formData = new FormData();

      const signature = await base64ToBlob(visit.signatureBase64);

      if (signature) {
        formData.append('photoJpegData', signature as Blob);
      }

      const url = new URLSearchParams();
      url.append('IdNumber', visit.IdNumber as string);
      url.append('FirstName', visit.FirstName as string);
      url.append('LastName', visit.LastName as string);

      await axios.post(
        `${endpointLgpd}/W-AccessAPI/v1/cardholders/AddCHWithGDPRSubscription?${url.toString()}`,
        formData,
        {
          headers: {
            ...headers,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      deleteLocalFile(signature as SignatureFile);
    } catch (error) {
      logger(error);
    }
  }

  async function visitCheckin(visit: Partial<VisitLgpdModel>) {
    if (visit.CHID) {
      if (visit.signatureBase64) {
        await updateCHGDPRS(visit);
      }
    } else {
      await addCHWithGDPR(visit);
    }
  }

  return (
    <VisitLgpdContext.Provider
      value={{
        visit,
        setVisit,
        resetData,
        getVisitDataByField,
        visitCheckin,
        getVisitorByDocument,
        setIsAllowText,
        isAllowText,
      }}
    >
      {children}
    </VisitLgpdContext.Provider>
  );
};

export function useVisitLgpdContext(): VisitLgpdContextData {
  const context = useContext(VisitLgpdContext);

  if (!context) {
    throw new Error('useVisitLgpdContext must be used within an VisitLgpdContextProvider');
  }

  return context;
}
