import { Platform } from 'react-native';

let db: any = null;

if (Platform.OS !== 'web') {
  const SQLite = require('expo-sqlite/legacy');
  db = SQLite.openDatabase('db.db');
}

export default db;
