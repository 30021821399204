import appConfig from '../config/app';

export default function getVisitQRCodeId(data: string) {
  const prefix = `${appConfig.company}://visit/`.toLocaleLowerCase();
  const lastIndexOf = data.lastIndexOf('/');
  const prefixData = data.substring(0, lastIndexOf + 1).toLocaleLowerCase();
  const visitId = data.slice(lastIndexOf + 1);

  if (prefixData !== prefix) {
    return null;
  }

  return visitId;
}
