import { images } from '_/assets';
import { colors, fontSizes } from '_/config/theme';
import { useCustomization } from '_/hooks/CustomizationContext';
import { BlurView } from 'expo-blur';
import React, { useState, useMemo } from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  Image,
  TouchableOpacityProps,
  View,
  Platform,
} from 'react-native';

import Text from '../Text';
import { HostsVisitsType } from './HostsVisitsType';

interface DataCellProps extends TouchableOpacityProps {
  data: HostsVisitsType;
  isHosts?: boolean;
}

const DataCell: React.FC<DataCellProps> = ({ data, isHosts, style, ...rest }) => {
  const [hasError, setError] = useState(false);
  const { customization } = useCustomization();
  const names: string[] | undefined | null = useMemo(() => data?.name?.split(' ') ?? null, [data]);

  const isHideLastNameEnabled = customization?.hideLastNameEnabled && isHosts;

  return (
    <TouchableOpacity
      style={[styles.button, style]}
      {...rest}
      activeOpacity={isHideLastNameEnabled ? 1 : 0.2}
    >
      <Image
        source={
          !hasError && !!data.pictureUrl
            ? {
                uri: data.pictureUrl,
              }
            : images.noPicture
        }
        onError={() => setError(true)}
        style={styles.image}
      />
      <View>
        {isHideLastNameEnabled ? (
          <View style={{ flexDirection: 'row' }}>
            <Text color={colors.dark2} fontType="medium" size={fontSizes.xl2}>
              {names?.[0]}
            </Text>
            <View style={styles.lastNamesContainer}>
              <Text
                style={styles.lastNames}
                color={Platform.OS === 'android' ? colors.transparent : colors.dark2}
                fontType="medium"
                size={fontSizes.xl2}
              >
                {names?.slice(1)?.join(' ')}
              </Text>
              <BlurView intensity={Platform.OS === 'ios' ? 100 : 35} style={styles.blurViewStyle} />
            </View>
          </View>
        ) : (
          <Text color={colors.dark2} fontType="medium" size={fontSizes.xl2}>
            {data.name}
          </Text>
        )}

        {!!data.sector && (
          <Text color={colors.light0} fontType="medium" size={fontSizes.lg}>
            {data.sector}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default DataCell;

const styles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    paddingBottom: 12,
    marginBottom: 12,
    borderBottomColor: colors.light3,
    borderBottomWidth: 2,
  },
  image: {
    borderRadius: 4,
    height: 56,
    width: 56,
    marginRight: 16,
  },
  blurViewStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: colors.white,
  },
  lastNamesContainer: {
    position: 'relative',
    paddingHorizontal: 8,
  },
  lastNames: {
    textShadowColor: Platform.OS === 'android' ? 'rgba(34,35,38,0.75)' : undefined,
    textShadowRadius: 30,
  },
});
