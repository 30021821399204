import { IpadIntegrationsModels } from '@w3lcome/types';
import { VisitsModelCheckin } from '_/interfaces/VisitsModelCheckin';
import axios from 'axios';
import { format } from 'date-fns';
import * as Crypto from 'expo-crypto';

export async function runTapsScanIntegration(
  visit: Partial<VisitsModelCheckin>,
  integration: IpadIntegrationsModels
) {
  if (integration.content.type === 'server') {
    await serverSendRequest(visit, integration);
  }

  if (integration.content.type === 'device') {
    await deviceSendRequest(visit, integration);
  }
}

async function getToken(token: string, currentTime: string) {
  return Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.MD5, `${token}${currentTime}`);
}

async function serverSendRequest(
  visit: Partial<VisitsModelCheckin>,
  integration: IpadIntegrationsModels
) {
  const url = `${integration.content.url}/sendPerson`;

  const headers = {
    'Content-Type': 'application/json',
  };

  const currentTime = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
  const token = await getToken(integration.content.secret, currentTime);

  await axios.post(
    url,
    {
      token,
      currentTime,
      data: {
        state: '0',
        ptype: '1',
        ResignationDate: '2030-12-09 12:34:56',
        areaId: integration.content.areaId,
        EntryDate: currentTime,
        nickName: visit.name,
        name: visit.name,
        company: visit.companyname ?? '-',
        department: visit.sector ?? '-',
        PersonalNr: visit.id,
        TelNr: visit.phone,
      },
    },
    { headers }
  );
}

async function deviceSendRequest(
  visit: Partial<VisitsModelCheckin>,
  integration: IpadIntegrationsModels
) {
  const url = `${integration.content.url}/LAPI/V1.0/PeopleLibraries/4/People`;

  const headers = {
    'Content-Type': 'application/json',
  };

  await axios.post(
    url,
    {
      Num: 1,
      PersonInfoList: [
        {
          PersonID: 0,
          LastChange: new Date().getTime() / 1000,
          PersonCode: '',
          PersonName: visit?.name,
          Remarks: '',
          TimeTemplateNum: 1,
          TimeTemplateList: [
            {
              BeginTime: 0,
              EndTime: BigInt(4294967295.0),
              Index: '0',
            },
          ],
          IdentificationNum: 0,
          ImageNum: 1,
          ImageList: [
            {
              FaceID: 0,
              Size: visit?.picture?.base64?.length,
              Data: visit?.picture?.base64,
            },
          ],
        },
      ],
    },
    { headers }
  );
}
