import { useState, useEffect, useRef } from 'react';

export default function useSafeState<T>(initialState: T | (() => T)) {
  const [data, setData] = useState<T>(initialState);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  function setSafeData(value: React.SetStateAction<T>) {
    if (mounted.current) {
      setData(value);
    }
  }

  return [data, setSafeData] as const;
}
