import * as Sentry from '@sentry/react-native';

export default function logger(error: any, name?: string) {
  if (__DEV__) {
    if (error?.response) {
      console.log(error?.response, name);
    } else {
      console.log(error, name);
    }
  } else {
    if (error?.response?.data) {
      Sentry?.addBreadcrumb({ data: error?.response?.data });
    }
    Sentry?.captureException(error);
  }
}
