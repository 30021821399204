import { images } from '_/assets';
import Text from '_/components/Text';
import { colors } from '_/config/theme';
import { hp, wp } from '_/helpers/dimension';
import React from 'react';
import { View, StyleSheet, Image, ActivityIndicator } from 'react-native';

interface LoadingViewProps {
  title?: string;
  subtitle?: string;
}

const LoadingView: React.FC<LoadingViewProps> = ({ title, subtitle }) => {
  return (
    <View style={styles.container}>
      <View style={[styles.container, { flex: 2 }]}>
        <Image source={images.icon} style={styles.image} resizeMode="contain" />
        {title && (
          <Text fontType="semibold" center size={38} color={colors.white} style={styles.title}>
            {title}
          </Text>
        )}
        {subtitle && (
          <Text center size={20} color={colors.white}>
            {subtitle}
          </Text>
        )}
      </View>
      <View style={styles.container}>
        <ActivityIndicator color={colors.white} size="large" />
      </View>
    </View>
  );
};

export default LoadingView;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.primary,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: wp(300),
    height: hp(300),
  },
  title: {
    marginTop: hp(8),
  },
});
