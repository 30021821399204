import { colors } from '_/config/theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text } from 'react-native';

interface CheckVisitorIsForeignerProps {
  onClick: (value: boolean) => void;
  buttonKey: string;
  foreignerDocumentSelected: boolean;
}

const CheckVisitorIsForeigner: React.FC<CheckVisitorIsForeignerProps> = ({
  onClick,
  buttonKey,
  foreignerDocumentSelected,
}) => {
  const { t } = useTranslation();

  return foreignerDocumentSelected ? (
    <Text style={styles.textStyles}>
      {t('foreignerDocument.part.1', { lng: 'pt-BR' })}{' '}
      <Text
        style={{ color: colors.secondary, fontWeight: 'bold' }}
        onPress={() => onClick(!foreignerDocumentSelected)}
      >
        {t('foreignerDocument.part.2', { lng: 'pt-BR', buttonKey })}
      </Text>{' '}
      {t('foreignerDocument.part.3', { lng: 'pt-BR' })}
    </Text>
  ) : (
    <Text style={styles.textStyles}>
      {t('foreignerDocument.part.1', { lng: 'en' })}{' '}
      <Text
        style={{ color: colors.secondary, fontWeight: 'bold' }}
        onPress={() => onClick(!foreignerDocumentSelected)}
      >
        {t('foreignerDocument.part.2', { lng: 'en', buttonKey })}
      </Text>{' '}
      {t('foreignerDocument.part.3', { lng: 'en' })}
    </Text>
  );
};
export default CheckVisitorIsForeigner;

const styles = StyleSheet.create({
  textStyles: {
    fontSize: 16,
    marginTop: 40,
    width: '100%',
  },
});
