import { useChildrenCompanies } from './ChildrenCompaniesContex';
import { useCustomization } from './CustomizationContext';
import { useHost } from './HostsContext';

export const useCentralizedValuesFallback = () => {
  const {
    selectedCustomization,
    selectedGDPR,
    selectedNDA,
    selectedCompany,
    childrenHostsList,
    searchChildrenHosts,
    selectedCompanyId,
  } = useChildrenCompanies();
  const { customization, gdpr, nda, company } = useCustomization();

  const { hostsList, searchHost } = useHost();

  const selectedOrFallbackCustomization = selectedCustomization || customization;
  const selectedOrFallbackGDPR = selectedGDPR || gdpr;
  const selectedOrFallbackNDA = selectedNDA || nda;
  const selectedOrFallbackCompany = selectedCompany || company;

  const selectedHosts = selectedCompanyId ? childrenHostsList : hostsList;

  const searchSelectedHost = selectedCompanyId ? searchChildrenHosts : searchHost;

  return {
    selectedOrFallbackCustomization,
    selectedOrFallbackGDPR,
    selectedOrFallbackNDA,
    selectedOrFallbackCompany,
    searchSelectedHost,
    selectedHosts,
  };
};
