import { colors } from '_/config/theme';
import { meetsContrastGuidelines } from 'polished';

export function getColorContrast(color1: string, color2: string) {
  const contrast = meetsContrastGuidelines(color1, color2);
  return +contrast.AA + +contrast.AAA + +contrast.AAALarge + +contrast.AALarge;
}

export function getColorByContrast(backgroundColor: string, textColor?: string | null) {
  textColor = textColor || colors.dark3;

  const contrastWhite = getColorContrast(backgroundColor, colors.white);
  const contrastDark = getColorContrast(backgroundColor, colors.dark3);
  const contrastText = getColorContrast(backgroundColor, textColor);

  if (contrastWhite > contrastDark && contrastWhite > contrastText) {
    return colors.white;
  }

  if (contrastDark > contrastWhite && contrastDark > contrastText) {
    return colors.dark3;
  }

  return textColor;
}
