import { Feather } from '@expo/vector-icons';
import { useNetInfo } from '@react-native-community/netinfo';
import { colors, fontSizes } from '_/config/theme';
import { countries } from '_/helpers/countryDials';
import { useCustomization } from '_/hooks/CustomizationContext';
import { useVisit } from '_/hooks/VisitContext';
import { smsOtpValidations } from '_/services/api';
import logger from '_/services/logger';
import useCountdownStore from '_/store/useCountdownStore';
import { parsePhoneNumber } from 'libphonenumber-js';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableOpacity, View, StyleSheet } from 'react-native';
import { showMessage } from 'react-native-flash-message';

import Button from '../Button';
import CodeInput from '../CodeInput';
import ModalView from '../Modal';
import Text from '../Text';
import { useChildrenCompanies } from '_/hooks/ChildrenCompaniesContex';

interface ModalPhoneNumberValidatorProps {
  isModalVisible: boolean;
  closeModal(value: boolean): void;
  successModal(): void;
}

const ModalPhoneNumberValidator: React.FC<ModalPhoneNumberValidatorProps> = ({
  isModalVisible,
  closeModal,
  successModal,
}) => {
  const [pin, setPin] = useState('');
  const { visit, setVisit } = useVisit();
  const { customization } = useCustomization();
  const inputRef = useRef<any>();
  const [counter, startCountdown, stopCountdown] = useCountdownStore((state) => [
    state.counter,
    state.startCountdown,
    state.stopCountdown,
  ]);

  const { t } = useTranslation();

  const { selectedCompanyId } = useChildrenCompanies();

  const netInfo = useNetInfo();

  const [resendCodeEnabled, setResendCodeEnabled] = useState(false);

  useEffect(() => {
    if (counter === 0) {
      setResendCodeEnabled(true);
    }
  }, [counter]);

  async function onChangeText(value: string) {
    setPin(value.toUpperCase());
  }

  async function handleSubmit() {
    if (!visit.phone) {
      return;
    }

    if (pin.length === 6) {
      try {
        const otpValidated = await (selectedCompanyId
          ? smsOtpValidations.childrenValidatetOtp(selectedCompanyId, {
              code: pin,
              phone: parsePhoneNumber(visit.phone, visit.countryCode).number,
            })
          : smsOtpValidations.validateOtp(null, {
              code: pin,
              phone: parsePhoneNumber(visit.phone, visit.countryCode).number,
            }));

        stopCountdown();
        setVisit({ smsOtpValidationId: otpValidated.id });
        closeModal(false);
        successModal();
      } catch (error) {
        logger(error);
        showMessage({
          message: t('invalidCode'),
          backgroundColor: colors.danger,
        });
      }
    }
  }

  async function handleResendCode() {
    try {
      if (visit.phone) {
        await smsOtpValidations.insert({
          phone: parsePhoneNumber(visit.phone, visit.countryCode).number,
        });
        setResendCodeEnabled(false);
        startCountdown(customization?.otpValidationTimeToExpireInMinutes || 2);
      }
    } catch (error) {
      logger(error);
      showMessage({
        message: t('errorSendingCode'),
        backgroundColor: colors.danger,
      });
    }
  }

  function formatTime(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  function getCountryCodeByCode(code: string | undefined) {
    if (!code) {
      return null;
    }

    const country = countries.find((country) => country.code === code);
    return country ? country.countryCode : null;
  }

  return (
    <ModalView isVisible={isModalVisible}>
      <TouchableOpacity onPress={() => closeModal(false)} style={styles.buttonClose}>
        <Feather name="x" size={18} color={colors.light1} />
      </TouchableOpacity>
      <View style={styles.content}>
        <Text size={18} center>
          {t('enterCodeMessage')}
        </Text>

        <Text size={22} center fontType="bold" style={{ marginTop: 16 }}>
          {`${getCountryCodeByCode(visit.countryCode)} ${visit.phone}`}
        </Text>

        {!netInfo.isConnected && (
          <Text
            size={fontSizes.lg}
            color={colors.danger}
            style={{ marginBottom: -10, marginTop: 8 }}
          >
            {t('homeScreen.Connection')}
          </Text>
        )}

        <View style={{ width: '100%', marginVertical: 8 }}>
          <CodeInput
            pin={pin}
            inputRef={inputRef}
            onChangeText={onChangeText}
            keyboardType="numeric"
          />
        </View>

        <View style={styles.buttonsContainer}>
          {pin.length === 6 && (
            <Button
              backgroundColor={colors.white}
              labelColor={colors.primary}
              containerStyles={styles.buttonContainer}
              buttonStyles={[
                styles.button,
                {
                  justifyContent: 'center',
                  borderWidth: 2,
                  borderColor: colors.primary,
                },
              ]}
              onPress={() => setPin('')}
            >
              {t('NDAScreen.Clear')}
            </Button>
          )}

          <Button
            backgroundColor={!netInfo.isConnected ? colors.light2 : colors.primary}
            labelColor={colors.white}
            containerStyles={{
              flex: 1,
            }}
            buttonStyles={[styles.button, { justifyContent: 'space-between' }]}
            rightIcon="arrow-right"
            onPress={handleSubmit}
            disabled={!netInfo.isConnected}
          >
            {t('Submit')}
          </Button>
        </View>

        <View style={styles.footer}>
          <View>
            <Text size={16}>{t('enteredWrongPhoneNumber')}</Text>

            <TouchableOpacity onPress={() => closeModal(false)}>
              <Text size={16} center style={styles.textButton}>
                {t('changePhoneNumber')}
              </Text>
            </TouchableOpacity>
          </View>

          <View>
            <Text size={16}>{t('didNotReceiveCode')}</Text>

            <TouchableOpacity onPress={handleResendCode} disabled={!resendCodeEnabled}>
              <Text
                size={16}
                center
                style={[styles.textButton, { opacity: !resendCodeEnabled ? 0.5 : 1 }]}
              >
                {counter === 0 ? t('resendCode') : t('resendCodeIn', { time: formatTime(counter) })}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </ModalView>
  );
};

export default ModalPhoneNumberValidator;

const styles = StyleSheet.create({
  buttonClose: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  content: {
    width: '95%',
    alignItems: 'center',
    padding: 12,
  },
  textButton: {
    color: colors.primary,
  },
  footer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonContainer: {
    width: '20%',
  },
  buttonsContainer: {
    flexDirection: 'row',
    gap: 12,
    width: '100%',
  },
  button: {
    marginBottom: 24,
    height: 40,
  },
});
