import { Feather } from '@expo/vector-icons';
import { colors } from '_/config/theme';
import React, { useEffect, useState } from 'react';
import { Animated, Dimensions, ScrollView, StyleSheet, View } from 'react-native';

const { height } = Dimensions.get('window');

interface ChevronDownProps {
  scrollY: Animated.Value;
  isVisible: boolean;
  scrollSize: number;
  extraPadding: number;
  children?: React.ReactNode;
  scrollViewRef: React.RefObject<ScrollView>;
}

const ChevronDown: React.FC<ChevronDownProps> = ({
  scrollY,
  isVisible,
  scrollSize,
  extraPadding,
  children,
  scrollViewRef,
}) => {
  const [isScrollEnd, setIsScrollEnd] = useState(false);

  useEffect(() => {
    scrollY.addListener((event) => {
      if (event.value > 50) {
        setIsScrollEnd(true);
        return;
      }
      setIsScrollEnd(false);
    });
  }, []);

  return (
    <>
      {children}

      {isVisible && (
        <Animated.View
          pointerEvents="none"
          style={[
            styles.arrowContainer,
            { bottom: scrollSize - extraPadding - height },
            {
              opacity: scrollY.interpolate({
                inputRange: [-1, 0, 200],
                outputRange: [1, 1, 0],
                extrapolate: 'extend',
              }),
            },
            {
              transform: [
                {
                  translateY: scrollY.interpolate({
                    inputRange: [-1, 0, 200, 201],
                    outputRange: [-1, 0, 260, 260],
                    extrapolate: 'extend',
                  }),
                },
              ],
            },
          ]}
        >
          <View pointerEvents={isScrollEnd ? 'none' : 'auto'}>
            <Feather
              name="chevron-down"
              size={64}
              color={colors.dark3}
              onPress={() => scrollViewRef.current?.scrollToEnd({ animated: true })}
            />
          </View>
        </Animated.View>
      )}
    </>
  );
};

export default ChevronDown;

const styles = StyleSheet.create({
  arrowContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 60,
    zIndex: 900,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    overflow: 'hidden',
  },
});
