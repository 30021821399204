import { StackNavigationProp } from '@react-navigation/stack';

export enum AppRoute {
  PIN = 'Pin',
  LOADING = 'Loading',
  HOME = 'Home',
  CHECKIN = 'Checkin',
  CHECKOUT = 'Checkout',
  DELIVERY = 'Delivery',
  PAYMENT_ERROR = 'PaymentError',
  CHECKIN_COMPANY_SELECT = 'CheckinCompanySelect',
  CHECKIN_HOST = 'CheckinHost',
  CHECKIN_EXTRA_HOST_FORM = 'CheckinExtraHostForm',
  CHECKIN_PICTURE = 'CheckinPicture',
  CHECKIN_NDA = 'CheckinNDA',
  CHECKIN_MESSAGE = 'CheckinMessage',
  CHECKIN_QUESTIONNARIE = 'CheckinQuestionnarie',
  CHECKIN_INFORMATION = 'CheckinInformation',
  DELIVERY_HOST = 'DeliveryHost',
  DELIVERY_MESSAGE = 'DeliveryMessage',
  CHECKOUT_VISITOR = 'CheckoutVisitor',
  CHECKOUT_FEEDBACK = 'CheckoutFeedback',
  CHECKOUT_MESSAGE = 'CheckoutMessage',
  LGPD_TOTEM_CHECKIN = 'LGPDCheckin',
  LGPD_TOTEM_CHECKIN_INFORMATION = 'LGPDCheckinInformation',
  LGPD_TOTEM_CHECKIN_NDA = 'LGPDCheckinNDA',
  LGPD_TOTEM_CHECKIN_MESSAGE = 'LGPDCheckinMessage',
  ROOT_STACK_HOME = 'RootStackHome',
  MAIN_STACK_CHECKIN = 'MainStackCheckin',
  MAIN_STACK_DELIVERY_HOST = 'MainStackDeliveryHost',
  MAIN_STACK_CHECKOUT_VISITOR = 'MainStackCheckoutVisitor',
}

type RootStackParamList = {
  [AppRoute.PIN]: undefined;
  [AppRoute.LOADING]: undefined;
  [AppRoute.HOME]: undefined;
  [AppRoute.CHECKIN]?: { screen: AppRoute };
  [AppRoute.CHECKOUT]: undefined;
  [AppRoute.DELIVERY]: undefined;
  [AppRoute.PAYMENT_ERROR]: undefined;
  [AppRoute.CHECKIN_COMPANY_SELECT]: undefined;
  [AppRoute.CHECKIN_HOST]: undefined;
  [AppRoute.CHECKIN_EXTRA_HOST_FORM]: undefined;
  [AppRoute.CHECKIN_PICTURE]: undefined;
  [AppRoute.CHECKIN_NDA]: undefined;
  [AppRoute.CHECKIN_MESSAGE]: undefined;
  [AppRoute.CHECKIN_QUESTIONNARIE]: undefined;
  [AppRoute.CHECKIN_INFORMATION]: undefined;
  [AppRoute.DELIVERY_HOST]: undefined;
  [AppRoute.DELIVERY_MESSAGE]: undefined;
  [AppRoute.CHECKOUT_VISITOR]: undefined;
  [AppRoute.CHECKOUT_FEEDBACK]: undefined;
  [AppRoute.CHECKOUT_MESSAGE]: undefined;
  [AppRoute.LGPD_TOTEM_CHECKIN]: undefined;
  [AppRoute.LGPD_TOTEM_CHECKIN_INFORMATION]: undefined;
  [AppRoute.LGPD_TOTEM_CHECKIN_NDA]: undefined;
  [AppRoute.LGPD_TOTEM_CHECKIN_MESSAGE]: undefined;
  [AppRoute.ROOT_STACK_HOME]: undefined;
  [AppRoute.MAIN_STACK_CHECKIN]?: { screen?: AppRoute; params?: unknown };
  [AppRoute.MAIN_STACK_DELIVERY_HOST]: undefined;
  [AppRoute.MAIN_STACK_CHECKOUT_VISITOR]: undefined;
};

export type PinProps = StackNavigationProp<RootStackParamList, AppRoute.PIN>;

export type LoadingProps = StackNavigationProp<RootStackParamList, AppRoute.LOADING>;

export type HomeProps = StackNavigationProp<RootStackParamList, AppRoute.HOME>;

export type CheckinProps = StackNavigationProp<RootStackParamList, AppRoute.CHECKIN>;

export type CheckoutProps = StackNavigationProp<RootStackParamList, AppRoute.CHECKOUT>;

export type DeliveryProps = StackNavigationProp<RootStackParamList, AppRoute.DELIVERY>;

export type PaymentErrorProps = StackNavigationProp<RootStackParamList, AppRoute.PAYMENT_ERROR>;

export type CompanySelectProps = StackNavigationProp<
  RootStackParamList,
  AppRoute.CHECKIN_COMPANY_SELECT
>;

export type HostProps = StackNavigationProp<RootStackParamList, AppRoute.CHECKIN_HOST>;

export type ExtraHostFormProps = StackNavigationProp<
  RootStackParamList,
  AppRoute.CHECKIN_EXTRA_HOST_FORM
>;

export type PhotoHostProps = StackNavigationProp<RootStackParamList, AppRoute.CHECKIN_PICTURE>;

export type NDAProps = StackNavigationProp<RootStackParamList, AppRoute.CHECKIN_NDA>;

export type MessageProps = StackNavigationProp<RootStackParamList, AppRoute.CHECKIN_MESSAGE>;

export type QuestionnarieProps = StackNavigationProp<
  RootStackParamList,
  AppRoute.CHECKIN_QUESTIONNARIE
>;

export type InformationProps = StackNavigationProp<
  RootStackParamList,
  AppRoute.CHECKIN_INFORMATION
>;

export type DeliveryHostProps = StackNavigationProp<RootStackParamList, AppRoute.DELIVERY_HOST>;

export type DeliveryMessageProps = StackNavigationProp<
  RootStackParamList,
  AppRoute.DELIVERY_MESSAGE
>;

export type CheckoutVisitorProps = StackNavigationProp<
  RootStackParamList,
  AppRoute.CHECKOUT_VISITOR
>;

export type CheckoutFeedbackProps = StackNavigationProp<
  RootStackParamList,
  AppRoute.CHECKOUT_FEEDBACK
>;

export type CheckoutMessageProps = StackNavigationProp<
  RootStackParamList,
  AppRoute.CHECKOUT_MESSAGE
>;

export type LGPDCheckinProps = StackNavigationProp<RootStackParamList, AppRoute.LGPD_TOTEM_CHECKIN>;

export type LGPDCheckinInformationProps = StackNavigationProp<
  RootStackParamList,
  AppRoute.LGPD_TOTEM_CHECKIN_INFORMATION
>;

export type LGPDCheckinNDAProps = StackNavigationProp<
  RootStackParamList,
  AppRoute.LGPD_TOTEM_CHECKIN_NDA
>;

export type LGPDCheckinMessageProps = StackNavigationProp<
  RootStackParamList,
  AppRoute.LGPD_TOTEM_CHECKIN_MESSAGE
>;
