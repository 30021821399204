import { IpadsModel, IpadLang } from '@w3lcome/types';
import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as Network from 'expo-network';

import logger from '../logger';
import RequestCreator from './BaseRequest';

interface SigninResponse {
  accessToken: string;
  ipad: IpadsModel;
}

class Auth extends RequestCreator<IpadsModel> {
  private getIpadData = async (lang: string) => {
    const ip = await Network.getIpAddressAsync();

    return {
      deviceName: Device.deviceName,
      deviceSystem: Device.osName,
      deviceVersion: Device.osVersion,
      deviceType: Device.brand,
      deviceVendor: Device.manufacturer,
      currentLanguage: lang,
      currentVersion: Constants.expoConfig?.version,
      wifiIp: ip,
    };
  };

  signin = async (pin: string, lang: string = IpadLang.EN) => {
    try {
      const ipadData = await this.getIpadData(lang);
      const result = await this.api.post<SigninResponse>(this.path, {
        strategy: 'pin',
        pin,
        ipadData: {
          name: Device.deviceName,
          ...ipadData,
        },
      });
      const { accessToken } = result.data;

      this.setToken(accessToken);

      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  signout = () => {
    this.removeToken();
  };
}

const api = new Auth('/authentication-ipads');
export default api;
