import { colors } from '_/config/theme';
import { useCustomization } from '_/hooks/CustomizationContext';
import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { StyleSheet, Dimensions, ImageBackground } from 'react-native';
import { SwiperFlatList } from 'react-native-swiper-flatlist';

const SlideshowImages: React.FC = () => {
  const { customization } = useCustomization();

  if (!customization?.ipadSlideshowImages?.length) {
    return null;
  }

  return (
    <>
      <SwiperFlatList
        autoplay
        keyboardShouldPersistTaps="never"
        style={styles.list}
        data={customization.ipadSlideshowImages}
        autoplayDelay={customization.ipadSlideshowDuration || 6}
        autoplayLoop
        renderItem={({ item }) => <ImageBackground source={{ uri: item }} style={styles.image} />}
      />

      <LinearGradient
        colors={['rgba(0,0,0,0.4)', 'transparent']}
        style={[styles.gradient, { top: 0, height: 60 }]}
      />

      <LinearGradient
        colors={['transparent', 'rgba(0,0,0,0.8)']}
        style={[styles.gradient, { bottom: 0, height: 200 }]}
      />
    </>
  );
};

export default SlideshowImages;

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  list: {
    flex: 1,
    backgroundColor: colors.dark1,
  },
  image: {
    flex: 1,
    width,
    height: '100%',
    minWidth: '100%',
    minHeight: '100%',
    opacity: 0.8,
  },
  gradient: {
    position: 'absolute',
    left: 0,
    right: 0,
  },
});
