import { Feather } from '@expo/vector-icons';
import { Button, Text } from '_/components';
import { colors, fontSizes } from '_/config/theme';
import { useCustomization } from '_/hooks/CustomizationContext';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, Animated, Easing, TouchableOpacity, Platform } from 'react-native';

interface BottomModalContainerProps {
  onRetakePress: () => void;
  onSkipPress: () => void;
  onNextPress: () => void;
  isVisible: boolean;
  cameraAllowed: boolean;
}

const BottomModalContainer: React.FC<BottomModalContainerProps> = ({
  onNextPress,
  onRetakePress,
  onSkipPress,
  isVisible,
  cameraAllowed,
}) => {
  const { t } = useTranslation();
  const [containerHeight, setContainerHeight] = useState(200);

  const bottomContainerAnimation = useRef(new Animated.Value(0));

  const { customization } = useCustomization();

  useEffect(() => {
    Animated.timing(bottomContainerAnimation.current, {
      toValue: isVisible ? 1 : 0,
      duration: 400,
      useNativeDriver: Platform.OS !== 'web',
      easing: Easing.inOut(Easing.ease),
    }).start();
  }, [isVisible]);

  const bottomContainerAnimationTranslateY = bottomContainerAnimation.current.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -containerHeight],
    extrapolate: 'clamp',
  });

  return (
    <Animated.View
      onLayout={(e) => setContainerHeight(e.nativeEvent.layout.height)}
      style={[
        styles.container,
        { bottom: -containerHeight },
        { transform: [{ translateY: bottomContainerAnimationTranslateY }] },
      ]}
    >
      <View style={{ width: '100%' }}>
        <Button
          backgroundColor={customization?.mainColor || colors.primary}
          labelColor={customization?.backgroundColor || colors.white}
          buttonStyles={{ justifyContent: 'space-between', height: 64 }}
          onPress={onNextPress}
          rightIcon="arrow-right"
        >
          {cameraAllowed ? t('Next') : t('pictureScreen.SkipPicture')}
        </Button>
      </View>

      {cameraAllowed && (
        <View
          style={[
            styles.buttonBottomModal,
            { justifyContent: customization?.pictureCanSkip ? 'space-between' : 'center' },
          ]}
        >
          <TouchableOpacity onPress={onRetakePress}>
            <View style={styles.buttonModal}>
              <Text color={colors.dark1} size={fontSizes.xl} fontType="medium">
                {t('pictureScreen.retakePicture')}
              </Text>
              <Feather style={styles.icon} name="repeat" size={fontSizes.xl} color={colors.dark1} />
            </View>
          </TouchableOpacity>

          {customization?.pictureCanSkip && (
            <TouchableOpacity onPress={onSkipPress}>
              <View style={styles.buttonModal}>
                <Text color={colors.primary} size={fontSizes.xl} fontType="medium">
                  {t('pictureScreen.removeAndSkipPicture')}
                </Text>
                <Feather
                  style={styles.icon}
                  name="fast-forward"
                  size={fontSizes.xl}
                  color={colors.dark1}
                />
              </View>
            </TouchableOpacity>
          )}
        </View>
      )}
    </Animated.View>
  );
};

export default BottomModalContainer;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    paddingHorizontal: 32,
    alignItems: 'center',
    backgroundColor: colors.white,
    paddingVertical: 18,
    elevation: 4,
    shadowColor: colors.white,
  },
  buttonBottomModal: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 8,
  },
  buttonModal: {
    flex: 1,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginLeft: 12,
  },
});
