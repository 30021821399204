import { Companies } from '_/constants/companies';
import Constants from 'expo-constants';

const company = Constants.expoConfig?.extra?.company as string;

export const images = {
  ...(company === Companies.W3LCOME && {
    logo: require('./images/w3lcome/logo.png'),
    icon: require('./images/w3lcome/icon.png'),
    paymentError: require('./images/w3lcome/error.png'),
    poweredByLogo: require('./images/w3lcome/logo.png'),
  }),
  ...(company === Companies.INVENZI && {
    logo: require('./images/invenzi/logo.png'),
    icon: require('./images/invenzi/icon.png'),
    paymentError: require('./images/invenzi/error.png'),
    poweredByLogo: require('./images/invenzi/invenzi.png'),
  }),
  ...(company === Companies.ENIDMA && {
    logo: require('./images/enidma/logo.png'),
    icon: require('./images/enidma/icon.png'),
    paymentError: require('./images/enidma/error.png'),
    poweredByLogo: require('./images/enidma/enidma.png'),
  }),
  qrcodeIcon: require('./images/qrcode_icon.png'),
  scanIcon: require('./images/scan.png'),
  noPicture: require('./images/no_picture.png'),
  picturePlaceholder: require('./images/picture_placeholder.png'),
  picturePlaceholderHorizontal: require('./images/picture_placeholderHorizontal.png'),

  enFlag: require('_/assets/images/flags/en.png'),
  enUkFlag: require('_/assets/images/flags/en-uk.png'),
  ptBRFlag: require('_/assets/images/flags/pt-BR.png'),
  esFlag: require('_/assets/images/flags/es.png'),
  frFlag: require('_/assets/images/flags/fr.png'),
  zhHans: require('_/assets/images/flags/zh-Hans.png'),
  taFlag: require('_/assets/images/flags/india.png'),
  viFlag: require('_/assets/images/flags/vietnam.png'),
  msFlag: require('_/assets/images/flags/malasya.png'),
  idFlag: require('_/assets/images/flags/indonesia.png'),
  deFlag: require('_/assets/images/flags/de.png'),
};
