import { BadgeConfig } from '@w3lcome/types';
import { badgeScale } from '_/constants/badgeScale';

export const paper62mmx29mmTest: BadgeConfig = {
  width: 620 * badgeScale,
  height: 290 * badgeScale,
  isTest: true,
  fields: [
    {
      type: 'text',
      subType: 'text',
      value: 'Printer working',
      styles: {
        top: 100 * badgeScale,
        left: 100 * badgeScale,
        width: 620 * badgeScale,
        height: 80 * badgeScale,
        fontSize: 60 * badgeScale,
        fontWeight: 'bold',
      },
    },
  ],
};
