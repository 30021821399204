import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import * as Animatable from 'react-native-animatable';

export const DURATION = 600;

interface PropsAnimateble {
  style?: StyleProp<ViewStyle>;
  duration: number;
  upInOrOutDown: boolean;
  children?: React.ReactNode;
}

export const AnimatableViewFadeInUpBigOrFadeOutDown: React.FC<PropsAnimateble> = ({
  children,
  style,
  duration,
  upInOrOutDown,
}) => {
  return (
    <Animatable.View
      animation={upInOrOutDown ? 'fadeInUpBig' : 'fadeOutDown'}
      duration={upInOrOutDown ? duration : DURATION}
      style={style}
    >
      {children}
    </Animatable.View>
  );
};
