import { createStackNavigator } from '@react-navigation/stack';
import ModalCountryPicker from '_/components/ModalCountryPicker';
import LgpdCheckinScreen from '_/screens/LgpdCheckinFlow/Checkin';
import LgpdCheckinInformationScreen from '_/screens/LgpdCheckinFlow/Checkin/Information';
import LgpdCheckinMessageScreen from '_/screens/LgpdCheckinFlow/Checkin/Message';
import LgpdCheckinNDAScreen from '_/screens/LgpdCheckinFlow/Checkin/NDA';
import React from 'react';
import { Platform } from 'react-native';

import { AppRoute } from './types';

const LgpdCheckin = createStackNavigator();

export default function LgpdCheckinStackScreen() {
  return (
    <>
      <LgpdCheckin.Navigator
        screenOptions={{
          animationEnabled: Platform.select({
            ios: true,
            android: false,
          }),
          headerShown: false,
          presentation: 'card',
        }}
      >
        <LgpdCheckin.Screen name={AppRoute.LGPD_TOTEM_CHECKIN} component={LgpdCheckinScreen} />
        <LgpdCheckin.Screen
          name={AppRoute.LGPD_TOTEM_CHECKIN_INFORMATION}
          component={LgpdCheckinInformationScreen}
        />

        <LgpdCheckin.Screen
          name={AppRoute.LGPD_TOTEM_CHECKIN_NDA}
          component={LgpdCheckinNDAScreen}
        />
        <LgpdCheckin.Screen
          name={AppRoute.LGPD_TOTEM_CHECKIN_MESSAGE}
          component={LgpdCheckinMessageScreen}
        />
      </LgpdCheckin.Navigator>
      <ModalCountryPicker />
    </>
  );
}
