import { Feather } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { colors } from '_/config/theme';
import React from 'react';
import { View, StyleSheet, TouchableOpacity, Image, ImageSourcePropType } from 'react-native';

import { PoweredBy } from '../PoweredBy';
import { useCentralizedValuesFallback } from '_/hooks/useCentralizedValuesFallback';

interface HeaderInterface {
  onGoBack?: () => void;
  source?: ImageSourcePropType;
}

const Header: React.FC<HeaderInterface> = ({ onGoBack, source }) => {
  const navigation = useNavigation();

  const { selectedOrFallbackCustomization } = useCentralizedValuesFallback();

  const returnRoute = () => {
    navigation.goBack();
    onGoBack?.();
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.buttonBack} onPress={returnRoute}>
        <Feather name="chevron-left" size={42} color={colors.light1} />
      </TouchableOpacity>
      <View style={{ flex: 1 }}>
        {selectedOrFallbackCustomization?.pictureUrl ? (
          <Image
            source={{ uri: selectedOrFallbackCustomization.pictureUrl }}
            style={{ height: 75, width: '100%' }}
            resizeMode="contain"
          />
        ) : (
          !!source && (
            <Image source={source} style={{ height: 22, width: '100%' }} resizeMode="contain" />
          )
        )}
      </View>
      <PoweredBy />
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: 32,
    paddingVertical: 32,
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
    height: 140,
  },
  buttonBack: {
    borderWidth: 1.5,
    borderColor: colors.light2,
    borderRadius: 6,
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    height: 80,
  },
});
