import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import Header from '_/components/Header';
import { colors } from '_/config/theme';
import { useVisit } from '_/hooks/VisitContext';
import { useNextCheckinScreen } from '_/hooks/useNextCheckinScreen';
import { AppRoute } from '_/navigation/types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Animated, Platform, ScrollView, Dimensions } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import Signature, { SignatureViewRef } from 'react-native-signature-canvas';
import SignatureCanvas from 'react-signature-canvas';

import ContinueButton from './ContinueButton';
import Paper from './Paper';
import ChevronDown from '_/components/ChevronDown';

const { height } = Dimensions.get('window');
const contentPaddingHorizontal = 32;

const CheckinNDAScreen: React.FC = () => {
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const signatureRef = useRef<SignatureViewRef>({} as SignatureViewRef);
  const signatureWeb = useRef<SignatureCanvas>(null);
  const [isClearVisible, setClearVisible] = useState(false);
  const [signatureImage, setSignatureImage] = useState(null as any);
  const { goToNextScreen } = useNextCheckinScreen(AppRoute.CHECKIN_NDA);

  const scrollY = useRef(new Animated.Value(0)).current;
  const scrollViewRef = useRef<ScrollView>(null);

  const [scrollSize, setScrollSize] = useState(0);

  const { t } = useTranslation();

  const { setVisit } = useVisit();

  const handleOK = (signature: string) => {
    setSignatureImage(signature);
  };

  const handleClear = () => {
    setClearVisible(false);
    setSignatureImage(null);
    signatureRef.current?.clearSignature();
  };

  const handleClearWeb = () => {
    setClearVisible(false);
    setSignatureImage(null);
    signatureWeb.current?.clear();
  };

  async function handleSaveNda() {
    if (!signatureImage) {
      showMessage({
        message: t('errorSignature'),
        backgroundColor: colors.danger,
      });
      return;
    }

    setVisit({ signedNda: signatureImage });
    goToNextScreen();
  }

  function handleSaveSigntureToImage() {
    const signatue = signatureWeb.current?.toDataURL();
    setSignatureImage(signatue);
  }

  const handleEnd = () => {
    setScrollEnabled(true);
    signatureRef.current?.readSignature();
  };

  const handleStart = () => {
    setScrollEnabled(false);
    setClearVisible(true);
  };

  return (
    <Animated.ScrollView
      scrollEnabled={scrollEnabled}
      style={[{ flex: 1, backgroundColor: colors.white }, StyleSheet.absoluteFill]}
      onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], {
        useNativeDriver: Platform.OS !== 'web',
      })}
      ref={scrollViewRef}
      onContentSizeChange={(w, h) => setScrollSize(h)}
      scrollEventThrottle={16}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      <View style={styles.center}>
        <ContainerWidthLimit>
          <View style={styles.container}>
            <Header />
            <View style={styles.content}>
              <ChevronDown
                scrollY={scrollY}
                isVisible={height < scrollSize}
                extraPadding={32}
                scrollSize={scrollSize}
                scrollViewRef={scrollViewRef}
              >
                <Paper
                  signatureImage={signatureImage}
                  onClearSignature={Platform.OS === 'web' ? handleClearWeb : handleClear}
                  isClearVisible={isClearVisible}
                  paperPaddingHorizontal={contentPaddingHorizontal}
                >
                  {Platform.OS === 'web' ? (
                    <SignatureCanvas
                      ref={signatureWeb}
                      onEnd={handleSaveSigntureToImage}
                      penColor={colors.dark1}
                      backgroundColor={colors.white}
                      canvasProps={{ style: { width: '100%', height: 300 } }}
                      onBegin={() => setClearVisible(true)}
                    />
                  ) : (
                    <Signature
                      androidHardwareAccelerationDisabled
                      style={styles.signature}
                      ref={signatureRef}
                      onOK={handleOK}
                      onEnd={handleEnd}
                      backgroundColor={colors.white}
                      penColor={colors.dark1}
                      scrollable={false}
                      webStyle={webSignatureStyle}
                      onBegin={handleStart}
                      webviewContainerStyle={{ opacity: 0.99 }}
                    />
                  )}
                </Paper>
              </ChevronDown>
            </View>
            <ContinueButton
              onButtonPress={handleSaveNda}
              paddingHorizontal={contentPaddingHorizontal}
            />
          </View>
        </ContainerWidthLimit>
      </View>
    </Animated.ScrollView>
  );
};

export default CheckinNDAScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    paddingBottom: 112,
  },
  content: {
    flex: 1,
    paddingHorizontal: contentPaddingHorizontal,
    backgroundColor: colors.white,
  },
  center: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  signature: {
    height: 300,
    width: '100%',
    borderWidth: 1,
    borderColor: colors.light3,
    borderRadius: 12,
  },
});

const webSignatureStyle = `
.m-signature-pad {box-shadow: none; border: 0; margin: 0px; }
.m-signature-pad--body {border: 0; margin: 0px; background-color: #ffffff;}
.m-signature-pad--footer {display: none; margin: 0px;}
body,html {
  width: 100%;
  height: 300px;
}
`;
