import AsyncStorage from '@react-native-async-storage/async-storage';
import { CompaniesModel, CustomizationsModel, GDPRModel, NDAModel } from '@w3lcome/types';
import appConfig from '_/config/app';
import { useUpdateCustomization } from '_/hooks/useUpdateCustomization';
import { companyApi, customizationApi, ipadGdpr, ipadNdaApi } from '_/services/api';
import logger from '_/services/logger';
import DeliveryDB from '_/services/sqlite/DeliveryDB';
import VisitDB from '_/services/sqlite/VisitDB';
import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import * as Sentry from '@sentry/react-native';

import { useAuth } from './AuthContext';
import { useLanguage } from './LanguageContext';
import db from '_/services/sqlite/SqliteDatabase';

export interface CustomizationContextData {
  company: CompaniesModel | null;
  customization: CustomizationsModel | null;
  gdpr: null | GDPRModel;
  nda: null | NDAModel;
  isVideoPlaying: boolean;
  loadData: () => Promise<void>;
  setIsVideoPlaying(value: boolean): void;
}

const CustomizationContext = createContext<CustomizationContextData>(
  {} as CustomizationContextData
);

type CustomizationType = {
  children: React.ReactNode;
};

export const CustomizationProvider: React.FC<CustomizationType> = ({ children }) => {
  const { signout, feathersApp, token } = useAuth();
  const [company, setCompany] = useState<CompaniesModel | null>(null);
  const [customization, setCustomization] = useState<CustomizationsModel | null>(null);
  const { languages, changeLanguage, language, getIpadLanguages } = useLanguage();
  const [gdprList, setGdprList] = useState<GDPRModel[]>([]);
  const [ndaList, setNdaList] = useState<NDAModel[]>([]);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const { updateFields } = useUpdateCustomization();

  useEffect(() => {
    feathersApp?.service('customizations').on('patched', loadData);
    feathersApp?.service('custom-fields').on('patched', loadData);
    feathersApp?.service('custom-fields').on('created', loadData);
    feathersApp?.service('custom-fields').on('removed', loadData);
    feathersApp?.service('ipads').on('removed', () => removeData);

    return () => {
      feathersApp?.service('customizations').off('patched', loadData);
      feathersApp?.service('custom-fields').off('patched', loadData);
      feathersApp?.service('custom-fields').off('created', loadData);
      feathersApp?.service('custom-fields').off('removed', loadData);
      feathersApp?.service('ipads').off('removed', () => removeData);
    };
  }, [feathersApp]);

  const country = useMemo(() => {
    return company?.country?.toLocaleLowerCase();
  }, [company]);

  const gdpr = useMemo(() => {
    if (gdprList.length !== 0) {
      const findCurrentLang = gdprList.find((e) => e.lang === language.code);
      const findLangEn = gdprList.find((e) => e.lang === 'en');

      if (findCurrentLang) {
        return findCurrentLang;
      }

      if (findLangEn) {
        return findLangEn;
      }

      return gdprList[0];
    }

    return null;
  }, [gdprList, language]);

  const nda = useMemo(() => {
    if (ndaList.length !== 0) {
      const findCurrentLang = ndaList.find((e) => e.lang === language.code);
      const findLangEn = ndaList.find((e) => e.lang === 'en');

      if (findCurrentLang) {
        return findCurrentLang;
      }

      if (findLangEn) {
        return findLangEn;
      }

      return ndaList[0];
    }

    return null;
  }, [ndaList, language]);

  useEffect(() => {
    async function defaultLanguage() {
      const storedLanguage = await AsyncStorage.getItem(appConfig.languageKey);
      if (!storedLanguage && country) {
        if (country === 'brazil') {
          changeLanguage(languages[2]);
        } else {
          changeLanguage(languages[0]);
        }
      }
    }
    defaultLanguage();
  }, [country]);

  useEffect(() => {
    const user = company ? { id: company.id, username: company.name } : null;

    Sentry.setUser(user);
  }, [company]);

  function removeData() {
    setCompany(null);
    setCustomization(null);
  }

  async function loadData() {
    try {
      const [{ data: companyDataList }, { data: customizationDataList }] = await Promise.all([
        companyApi.getList(),
        customizationApi.getList(),
      ]);

      const [companyData] = companyDataList;
      const customizationData = updateFields(customizationDataList[0]);

      if (companyData && customizationData) {
        const pastCompany = await AsyncStorage.getItem(appConfig.companyKey);

        if (pastCompany) {
          if (JSON.parse(pastCompany).id !== companyData.id) {
            if (db) {
              await Promise.all([
                VisitDB.deleteTable().catch(() => null),
                DeliveryDB.deleteTable().catch(() => null),
              ]);

              VisitDB.init();
              DeliveryDB.init();
            }
          }
        }
        await Promise.all([
          AsyncStorage.setItem(appConfig.companyKey, JSON.stringify(companyData)),
          AsyncStorage.setItem(appConfig.customizationKey, JSON.stringify(customizationData)),
        ]);
        setCompany(companyData);
        setCustomization(customizationData);

        const [{ data }, { data: ndaData }] = await Promise.all([
          ipadGdpr.getList({
            customizationId: customizationData.id,
          }),
          ipadNdaApi.getList({
            customizationId: customizationData.id,
          }),
        ]);

        setGdprList(data);
        getIpadLanguages(customizationData.id);
        setNdaList(ndaData);
      } else {
        signout();
      }
    } catch (error) {
      logger(error);
      if ((error as any)?.response?.status === 401) {
        signout();
      } else {
        const [storedCompany, storedCustomization] = await Promise.all([
          AsyncStorage.getItem(appConfig.companyKey),
          AsyncStorage.getItem(appConfig.customizationKey),
        ]);

        if (storedCompany && storedCustomization) {
          setCompany(JSON.parse(storedCompany));
          setCustomization(JSON.parse(storedCustomization));
        } else {
          signout();
        }
      }
    }
  }

  useEffect(() => {
    if (token) {
      loadData();
    }
  }, [token]);

  return (
    <CustomizationContext.Provider
      value={{
        company,
        customization,
        gdpr,
        nda,
        isVideoPlaying,
        loadData,
        setIsVideoPlaying,
      }}
    >
      {children}
    </CustomizationContext.Provider>
  );
};

export function useCustomization(): CustomizationContextData {
  const context = useContext(CustomizationContext);

  if (!context) {
    throw new Error('useCustomization must be used within an CustomizationProvider');
  }

  return context;
}
