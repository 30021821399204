import { createStackNavigator } from '@react-navigation/stack';
import Inactivity from '_/components/Inactivity';
import ModalCountryPicker from '_/components/ModalCountryPicker';
import { useCustomization } from '_/hooks/CustomizationContext';
import CheckinScreen from '_/screens/Checkin';
import CheckinCompanySelectScreen from '_/screens/Checkin/CompanySelect';
import { ExtraHostForm } from '_/screens/Checkin/ExtraHostForm';
import CheckinHostScreen from '_/screens/Checkin/Host';
import CheckinInformationScreen from '_/screens/Checkin/Information';
import CheckinMessageScreen from '_/screens/Checkin/Message';
import CheckinNDAScreen from '_/screens/Checkin/NDA';
import CheckinPictureScreen from '_/screens/Checkin/Picture';
import CheckinQuestionnaireScreen from '_/screens/Checkin/Questionnaire';
import React from 'react';
import { Platform } from 'react-native';

import { AppRoute } from './types';

const CheckinStack = createStackNavigator();

export default function CheckinStackScreen() {
  const { company } = useCustomization();

  return (
    <Inactivity>
      <CheckinStack.Navigator
        screenOptions={{
          animationEnabled: Platform.select({
            ios: true,
            android: false,
          }),
          headerShown: false,
          presentation: 'card',
        }}
      >
        {company?.type === 'PARENT' && (
          <CheckinStack.Screen
            name={AppRoute.CHECKIN_COMPANY_SELECT}
            component={CheckinCompanySelectScreen}
          />
        )}
        <CheckinStack.Screen name={AppRoute.CHECKIN} component={CheckinScreen} />
        <CheckinStack.Screen
          name={AppRoute.CHECKIN_INFORMATION}
          component={CheckinInformationScreen}
        />
        <CheckinStack.Screen name={AppRoute.CHECKIN_HOST} component={CheckinHostScreen} />
        <CheckinStack.Screen name={AppRoute.CHECKIN_EXTRA_HOST_FORM} component={ExtraHostForm} />
        <CheckinStack.Screen name={AppRoute.CHECKIN_PICTURE} component={CheckinPictureScreen} />
        <CheckinStack.Screen name={AppRoute.CHECKIN_NDA} component={CheckinNDAScreen} />
        <CheckinStack.Screen name={AppRoute.CHECKIN_MESSAGE} component={CheckinMessageScreen} />
        <CheckinStack.Screen
          name={AppRoute.CHECKIN_QUESTIONNARIE}
          component={CheckinQuestionnaireScreen}
        />
      </CheckinStack.Navigator>
      <ModalCountryPicker />
    </Inactivity>
  );
}
