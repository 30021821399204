import AsyncStorage from '@react-native-async-storage/async-storage';
import { IpadLang } from '@w3lcome/types';
import appConfig from '_/config/app';
import { LanguageProps, languages } from '_/helpers/ipadLanguagesList';
import { ipadLanguages } from '_/services/api';
import logger from '_/services/logger';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from './AuthContext';

interface LanguageContextData {
  languages: LanguageProps[];
  language: LanguageProps;
  changeLanguage(item: LanguageProps): void;
  getIpadLanguages(customizationId?: string | null): void;
}

const LanguageContext = createContext<LanguageContextData>({} as LanguageContextData);

type LanguageType = {
  children: React.ReactNode;
};

export const LanguageProvider: React.FC<LanguageType> = ({ children }) => {
  const { i18n } = useTranslation();
  const { changeIpadLang, feathersApp } = useAuth();

  useEffect(() => {
    // Vou pegar o IpadLanguagesModel do types

    feathersApp
      ?.service('ipad-languages')
      .on('created', (data: any) => getIpadLanguages(data.customizationId));
    feathersApp
      ?.service('ipad-languages')
      .on('removed', (data: any) => getIpadLanguages(data.customizationId));

    return () => {
      feathersApp
        ?.service('ipad-languages')
        .off('created', (data: any) => getIpadLanguages(data.customizationId));
      feathersApp
        ?.service('ipad-languages')
        .off('removed', (data: any) => getIpadLanguages(data.customizationId));
    };
  }, [feathersApp]);

  const [language, setLanguage] = useState<LanguageProps>(languages[0]);
  const [ipadLanguagesAvailable, setIpadLanguagesAvailable] = useState<LanguageProps[]>(languages);

  async function getIpadLanguages(customizationId = null) {
    try {
      if (customizationId) {
        const { data } = await ipadLanguages.getList({ customizationId });

        const codes = data.map((e) => e.ipadLang);
        setIpadLanguagesAvailable(languages.filter((e) => codes.includes(e.code)));
      }
    } catch (error) {
      logger(error);
    }
  }

  async function getLanguage(): Promise<void> {
    const storedLanguage = await AsyncStorage.getItem(appConfig.languageKey);

    if (storedLanguage) {
      setLanguage(JSON.parse(storedLanguage));
    }
  }

  useEffect(() => {
    getLanguage();
  }, []);

  const changeLanguage = async (item: LanguageProps) => {
    try {
      i18n.changeLanguage(item.code);
      changeIpadLang(item.code as IpadLang);
      await AsyncStorage.setItem(appConfig.languageKey, JSON.stringify(item));
      getLanguage();
    } catch (error) {
      logger(error);
    }
  };

  return (
    <LanguageContext.Provider
      value={{ languages: ipadLanguagesAvailable, language, changeLanguage, getIpadLanguages }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export function useLanguage() {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error('useLanguage must be used within an LanguageProvider');
  }

  return context;
}
