import { Feather } from '@expo/vector-icons';
import { colors, fontSizes } from '_/config/theme';
import { useLanguage } from '_/hooks/LanguageContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, Dimensions, TouchableOpacity, FlatList, Image } from 'react-native';

import Text from '../Text';

const { width, height } = Dimensions.get('window');

interface Language {
  key: number;
  lang: string;
  code: string;
  flag: any;
}

interface LanguagesScreenProps {
  close: React.Dispatch<React.SetStateAction<boolean>>;
}

const LanguagesChoose: React.FC<LanguagesScreenProps> = ({ close }) => {
  const { languages, changeLanguage } = useLanguage();

  const { t } = useTranslation();

  const handleLanguage = (item: Language) => {
    changeLanguage(item);
    close(false);
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={() => close(false)} style={styles.buttonClose}>
        <Feather name="x" size={32} color="black" />
      </TouchableOpacity>
      <View style={styles.header}>
        <Text fontType="bold" center size={fontSizes.xxl} color={colors.black}>
          {t('chooseLanguage')}
        </Text>
      </View>
      <View style={styles.content}>
        <FlatList
          data={languages}
          keyExtractor={(item) => item.key.toString()}
          numColumns={2}
          showsVerticalScrollIndicator={false}
          columnWrapperStyle={{
            justifyContent: 'space-around',
            paddingBottom: 12,
            paddingTop: 12,
          }}
          renderItem={({ item }) => (
            <View>
              <TouchableOpacity
                onPress={() => handleLanguage(item)}
                style={{ alignItems: 'center' }}
              >
                <Image source={item.flag} resizeMode="cover" style={{ width: 90, height: 90 }} />
                <Text size={fontSizes.md2} fontType="semibold" color={colors.black}>
                  {item.lang}
                </Text>
              </TouchableOpacity>
            </View>
          )}
        />
      </View>
    </View>
  );
};

export default LanguagesChoose;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    height,
    width,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  header: {
    marginTop: 120,
  },
  content: {
    flex: 1,
    paddingVertical: 24,
    paddingHorizontal: 64,
    marginTop: 24,
  },
  buttonClose: {
    position: 'absolute',
    top: 62,
    left: 42,
  },
});
