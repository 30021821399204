import { useNavigation } from '@react-navigation/native';
import { HostsModel } from '@w3lcome/types';
import { Text, HostsVisitsFlatList, Header, Input } from '_/components';
import { AnimatableViewFadeInUpBigOrFadeOutDown, DURATION } from '_/components/Animatable';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import KeyboardContainer from '_/components/KeyboardContainer';
import { colors, fontSizes } from '_/config/theme';
import { useCustomization } from '_/hooks/CustomizationContext';
import { useDelivery } from '_/hooks/DeliveryContext';
import { useHost } from '_/hooks/HostsContext';
import { AppRoute, DeliveryProps } from '_/navigation/types';
import React, { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TextInput, Keyboard, ActivityIndicator } from 'react-native';

import ConfirmHostModal from './ConfirmHostModal';
import ModalDeliveriesPicture from './ModalDeliveriesPicture';

const DeliveryHostScreen: React.FC = () => {
  const { hostsList, searchHost } = useHost();
  const [textSearch, setTextSearch] = useState('');
  const inputSearchRef = useRef<TextInput>(null);
  const { customization } = useCustomization();

  const { resetDelivery, delivery, setDelivery, getDeliveryNotificationHosts } = useDelivery();
  const [upInOrOutDown, setUpInOrOutDown] = useState(true);
  const [isFocusedInput, setIsFocusedInput] = useState(false);
  const [cameraDeliveriesPictureEnabled, setCameraDeliveriesPictureEnabled] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigation = useNavigation<DeliveryProps>();
  const { t } = useTranslation();

  async function getHosts() {
    const hosts = await getDeliveryNotificationHosts().finally(() => setLoading(false));

    if (hosts && hosts?.length > 0) {
      navigation.navigate(AppRoute.DELIVERY_MESSAGE);
    } else {
      resetDelivery();
    }
  }

  useEffect(() => {
    if (customization?.deliveriesResponsibleHosts) {
      getHosts();
      return;
    }

    setLoading(false);
    resetDelivery();
  }, [customization?.deliveriesResponsibleHosts]);

  const handleNotify = (message: string) => {
    setUpInOrOutDown(false);
    Keyboard.dismiss();
    setDelivery((delivery) => ({
      ...delivery,
      message,
    }));
    setTimeout(() => {
      navigation.navigate(AppRoute.DELIVERY_MESSAGE);
    }, DURATION);
  };

  const handleSelectAndConfirmHost = (host: HostsModel) => {
    setDelivery((delivery) => ({ ...delivery, hosts: [host] }));
    Keyboard.dismiss();
  };

  useEffect(() => {
    setTimeout(() => {
      if (inputSearchRef && inputSearchRef.current) {
        return inputSearchRef.current?.focus();
      }
    }, DURATION);

    // To reset hostsList when unmounting
    return () => searchHost();
  }, []);

  function onChange(text: string) {
    searchHost(text);
    setTextSearch(text);
  }

  if (loading) {
    return (
      <View style={[styles.container, { justifyContent: 'center' }]}>
        <ActivityIndicator size={22} color={colors.dark1} />
      </View>
    );
  }

  return (
    <KeyboardContainer
      keyboardOpen={isFocusedInput}
      onChange={onChange}
      inputName="search"
      keyboardType="default"
    >
      <View style={styles.container}>
        <ContainerWidthLimit>
          <Header />

          <View style={styles.content}>
            <View style={styles.innerContent}>
              <AnimatableViewFadeInUpBigOrFadeOutDown
                duration={DURATION}
                upInOrOutDown={upInOrOutDown}
              >
                <Text center color={colors.dark1} size={fontSizes.xl2}>
                  {t('deliveryScreen.SelectHostNotified')}
                </Text>
              </AnimatableViewFadeInUpBigOrFadeOutDown>

              <AnimatableViewFadeInUpBigOrFadeOutDown
                duration={DURATION}
                upInOrOutDown={upInOrOutDown}
                style={styles.contentSearch}
              >
                <Input
                  icon="search"
                  label={t('hostScreen.Search')}
                  ref={inputSearchRef}
                  value={textSearch}
                  onChangeText={onChange}
                  onFocus={() => setIsFocusedInput(true)}
                  onBlur={() => setIsFocusedInput(false)}
                />
              </AnimatableViewFadeInUpBigOrFadeOutDown>

              {(textSearch.length > 2 || customization?.ipadDeliveriesDisplayAll) && (
                <HostsVisitsFlatList
                  data={hostsList}
                  onPressFunction={handleSelectAndConfirmHost}
                />
              )}

              {textSearch.length < 2 && !customization?.ipadDeliveriesDisplayAll && (
                <AnimatableViewFadeInUpBigOrFadeOutDown
                  duration={DURATION}
                  upInOrOutDown={upInOrOutDown}
                  style={styles.contentText}
                >
                  <Text center size={fontSizes.xl2} color={colors.dark2} fontType="medium">
                    {t('deliveryScreen.SearchHostName')}
                  </Text>
                </AnimatableViewFadeInUpBigOrFadeOutDown>
              )}
            </View>
          </View>
        </ContainerWidthLimit>
        <ConfirmHostModal
          host={delivery.hosts?.[0]}
          onNotify={handleNotify}
          onHide={resetDelivery}
          ipadDeliveriesIsPictureEnabled={customization?.ipadDeliveriesIsPictureEnabled}
          onDeliveriesPictureEnabled={setCameraDeliveriesPictureEnabled}
          pictureUrl={delivery.pictureUrl}
        />

        <ModalDeliveriesPicture
          isVisible={cameraDeliveriesPictureEnabled}
          onCancel={() => setCameraDeliveriesPictureEnabled(false)}
          onTakePicture={(pictureUrl: string | undefined) =>
            setDelivery((delivery) => ({ ...delivery, pictureUrl }))
          }
        />
      </View>
    </KeyboardContainer>
  );
};

export default DeliveryHostScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    alignItems: 'center',
  },
  content: {
    flex: 1,
    alignItems: 'center',
  },
  innerContent: {
    flex: 1,
    width: '80%',
  },
  contentSearch: {
    marginTop: 32,
  },
  contentText: {
    marginTop: 32,
  },
});
