import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { HomeScreen } from '_/screens';

import CheckinStackScreen from './CheckinStackScreen';
import CheckoutStackScreen from './CheckoutStackScreen';
import DeliveryStackScreen from './DeliveryStackScreen';
import { AppRoute } from './types';

const MainStack = createStackNavigator();

export default function MainStackScreen() {
  return (
    <MainStack.Navigator
      screenOptions={{ animationEnabled: true, headerShown: false, presentation: 'modal' }}
    >
      <MainStack.Screen name={AppRoute.HOME} component={HomeScreen} />
      <MainStack.Screen name={AppRoute.MAIN_STACK_CHECKIN} component={CheckinStackScreen} />
      <MainStack.Screen name={AppRoute.MAIN_STACK_DELIVERY_HOST} component={DeliveryStackScreen} />
      <MainStack.Screen
        name={AppRoute.MAIN_STACK_CHECKOUT_VISITOR}
        component={CheckoutStackScreen}
      />
    </MainStack.Navigator>
  );
}
