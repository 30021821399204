import { colors, whiteOpacity } from '_/config/theme';
import { BlurView } from 'expo-blur';
import React, { useEffect, useRef } from 'react';
import { Animated, Easing, Platform, StyleSheet, TouchableWithoutFeedback } from 'react-native';

interface ModalBackgroundProps {
  isVisible: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
}

const ModalBackground: React.FC<ModalBackgroundProps> = ({ isVisible, onClose, children }) => {
  const animation = useRef(new Animated.Value(0));

  useEffect(() => {
    Animated.timing(animation.current, {
      duration: 400,
      toValue: isVisible ? 1 : 0,
      easing: Easing.linear,
      useNativeDriver: Platform.OS !== 'web',
    }).start();
  }, [isVisible]);

  return (
    <Animated.View
      pointerEvents={isVisible ? 'auto' : 'none'}
      style={[
        styles.container,
        {
          opacity: animation.current.interpolate({
            inputRange: [0, 0.2],
            outputRange: [0, 1],
            extrapolate: 'clamp',
          }),
          transform: [
            {
              scale: animation.current.interpolate({
                inputRange: [0, 0.1],
                outputRange: [0, 1],
                extrapolate: 'clamp',
              }),
            },
          ],
        },
      ]}
    >
      <TouchableWithoutFeedback style={{ flex: 1 }} onPress={onClose}>
        <BlurView intensity={90} style={styles.innerContainer}>
          {children}
        </BlurView>
      </TouchableWithoutFeedback>
    </Animated.View>
  );
};

export default ModalBackground;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 900,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: Platform.OS === 'android' ? whiteOpacity : colors.transparent,
  },
  innerContainer: {
    padding: 40,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
