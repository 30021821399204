import React, { useEffect, useRef } from 'react';
import { TouchableOpacity, TouchableOpacityProps } from 'react-native';

interface MultiTapProps extends TouchableOpacityProps {
  numTaps?: number;
  delay?: number;
  onPress?: () => void;
}

const MultiTap: React.FC<MultiTapProps> = ({
  children,
  numTaps = 2,
  delay = 400,
  onPress,
  ...rest
}) => {
  const taps = useRef(0);
  const timeoutId = useRef<NodeJS.Timeout>();

  function handlePress() {
    taps.current += 1;
    if (taps.current >= numTaps) {
      onPress?.();
      taps.current = 0;
    } else {
      clearTimeout((timeoutId.current || 0) as any);
      timeoutId.current = setTimeout(() => {
        taps.current = 0;
      }, delay);
    }
  }

  useEffect(() => {
    return () => {
      clearTimeout((timeoutId.current || 0) as any);
    };
  });

  return (
    <TouchableOpacity activeOpacity={1} onPress={handlePress} {...rest}>
      {children}
    </TouchableOpacity>
  );
};

export default MultiTap;
