import { IpadIntegrationsModels } from '@w3lcome/types';
import { VisitsModelCheckin } from '_/interfaces/VisitsModelCheckin';
import { ipadIntegrationsApi } from '_/services/api';
import { approval, checkin } from '_/services/integrations';
import logger from '_/services/logger';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { useAuth } from './AuthContext';

export interface IntegrationsContextData {
  runApprovalIntegrations: (visit: Partial<VisitsModelCheckin>) => Promise<void>;
  runCheckinIntegrations: (visit: Partial<VisitsModelCheckin>) => Promise<void>;
}

const IntegrationsContext = createContext<IntegrationsContextData>({} as IntegrationsContextData);

type IntegrationsType = {
  children: React.ReactNode;
};

export const IntegrationsProvider: React.FC<IntegrationsType> = ({ children }) => {
  const [integrations, setIntegrations] = useState<IpadIntegrationsModels[]>([]);
  const { feathersApp, ipad } = useAuth();

  useEffect(() => {
    getIntegrations();
  }, [ipad?.id]);

  useEffect(() => {
    feathersApp?.service('ipad-integrations').on('created', getIntegrations);
    feathersApp?.service('ipad-integrations').on('removed', getIntegrations);

    return () => {
      feathersApp?.service('ipad-integrations').off('created', getIntegrations);
      feathersApp?.service('ipad-integrations').off('removed', getIntegrations);
    };
  }, [feathersApp]);

  async function getIntegrations() {
    if (!ipad?.id) {
      setIntegrations([]);
      return;
    }

    try {
      const { data } = await ipadIntegrationsApi.getList();
      setIntegrations(data);
    } catch (error) {
      logger(error);
    }
  }

  async function runApprovalIntegrations(visit: Partial<VisitsModelCheckin>) {
    integrations.forEach((integration) => {
      approval(visit, integration);
    });
  }

  async function runCheckinIntegrations(visit: Partial<VisitsModelCheckin>) {
    integrations.forEach((integration) => {
      checkin(visit, integration);
    });
  }

  return (
    <IntegrationsContext.Provider value={{ runApprovalIntegrations, runCheckinIntegrations }}>
      {children}
    </IntegrationsContext.Provider>
  );
};

export function useIntegrations(): IntegrationsContextData {
  const context = useContext(IntegrationsContext);

  if (!context) {
    throw new Error('useIntegrations must be used within an IntegrationsProvider');
  }

  return context;
}
