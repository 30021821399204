import { FontAwesome } from '@expo/vector-icons';
import { CustomFieldType } from '@w3lcome/types';
import { colors, defaultShadow, fontFamily, fontSizes } from '_/config/theme';
import { CustomFieldWithError } from '_/interfaces/CustomFieldWithError';
import { AsYouType, CountryCode } from 'libphonenumber-js';
import { transparentize } from 'polished';
import React, { forwardRef, useMemo, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextInputProps,
  StyleSheet,
  TextInput,
  View,
  Platform,
  NativeSyntheticEvent,
  TextInputFocusEventData,
  KeyboardTypeOptions,
  TouchableOpacity,
  Animated,
  Easing,
  Switch,
  TextInputKeyPressEventData,
} from 'react-native';

import CountryPicker from '../CountryPicker';
import Text from '../Text';
import { DropdownList } from './DropdownList';
import { useCentralizedValuesFallback } from '_/hooks/useCentralizedValuesFallback';
interface CustomFieldInputProps extends TextInputProps {
  customField?: CustomFieldWithError;
  countryCode?: CountryCode;
  isDisabled?: boolean;
  onSubmit?: () => void;
}

const CustomFieldInput: React.ForwardRefRenderFunction<TextInput, CustomFieldInputProps> = (
  {
    customField,
    style,
    countryCode,
    value,
    onFocus,
    onBlur,
    onChangeText,
    isDisabled,
    onSubmit,
    ...rest
  },
  ref
) => {
  const { t, i18n } = useTranslation();
  const [isFieldActive, setIsFieldActive] = useState(false);
  const animation = useRef(new Animated.Value(0));

  const { selectedOrFallbackCustomization } = useCentralizedValuesFallback();

  const isIdentityCard = customField?.type === CustomFieldType.IDENTITY_CARD;
  const hasNumberOfCharacters = !!selectedOrFallbackCustomization?.numberOfCharacters;

  useEffect(() => {
    Animated.timing(animation.current, {
      toValue: isFieldActive ? 1 : 0,
      duration: 250,
      easing: Easing.out(Easing.linear),
      useNativeDriver: Platform.OS !== 'web',
    }).start();
  }, [isFieldActive]);

  const scales = useMemo(() => {
    return Array.from({ length: 10 }, (_, i) => `${i + 1}`);
  }, []);

  const handleFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    setIsFieldActive(true);
    onFocus?.(e);
  };

  const handleBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    setIsFieldActive(false);
    onBlur?.(e);
  };

  const handleChangeText = (text: string) => {
    if (
      customField?.type === CustomFieldType.PHONE ||
      customField?.type === CustomFieldType.HOST_PHONE
    ) {
      const formattedNumber = new AsYouType(countryCode).input(text);
      onChangeText?.(formattedNumber);
    } else {
      onChangeText?.(text);
    }
  };

  const keyboardType: KeyboardTypeOptions = useMemo(() => {
    switch (customField?.type) {
      case CustomFieldType.PHONE:
      case CustomFieldType.HOST_PHONE:
      case CustomFieldType.CPF:
      case CustomFieldType.RG:
        return 'numeric';
      case CustomFieldType.IDENTITY_CARD:
        return Platform.OS === 'ios' ? 'numbers-and-punctuation' : 'visible-password';
      case CustomFieldType.EMAIL:
        return 'email-address';
    }
    return 'ascii-capable';
  }, [customField]);

  const autoCapitalize = useMemo(() => {
    switch (customField?.type) {
      case CustomFieldType.PLATE:
      case CustomFieldType.DOCUMENT:
      case CustomFieldType.RG:
        return 'characters';
      case CustomFieldType.NAME:
      case CustomFieldType.COMPANYNAME:
        return 'words';
      case CustomFieldType.EMAIL:
        return 'none';
    }
    return 'sentences';
  }, [customField]);

  const name: string = useMemo(() => {
    if (typeof customField?.name === 'string') {
      return t(customField?.name);
    }

    const localizedName = customField?.name?.[i18n.language];
    if (localizedName) {
      return localizedName;
    }

    const firstLocale = Object.keys(customField?.name || {})[0];
    const firstLocalizedName = customField?.name?.[firstLocale];
    if (firstLocalizedName) {
      return firstLocalizedName;
    }

    return '';
  }, [customField, i18n]);

  const placeholder: string = useMemo(() => {
    if (!customField?.placeholder) {
      return name;
    }

    if (customField.type === CustomFieldType.NAME) {
      return `${t('Name')} (${
        selectedOrFallbackCustomization?.fullNameRequired ? t('fullName') : t('firstName')
      })`;
    }

    if (typeof customField?.placeholder === 'string') {
      return customField?.placeholder;
    }

    const localizedPlaceholder = customField.placeholder?.[i18n.language];

    if (localizedPlaceholder) {
      return localizedPlaceholder;
    }

    return name;
  }, [customField, i18n, name]);

  const scale = animation.current.interpolate({
    inputRange: [0, 1],
    outputRange: [0.95, 1],
    extrapolate: 'clamp',
  });

  const opacity = animation.current.interpolate({
    inputRange: [0, 1],
    outputRange: [0.9, 1],
    extrapolate: 'clamp',
  });

  const identityCardDescription = () => {
    return hasNumberOfCharacters
      ? t('IdentityCardNumberOfCharacters', {
          numberOfCharacters: selectedOrFallbackCustomization?.numberOfCharacters,
        })
      : t('IdentityCardAllCharacters');
  };

  const handlePressEnter = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    e.persist();
    if (e.nativeEvent.key === 'Enter' && onSubmit) {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <Animated.View
      pointerEvents={isDisabled ? 'none' : 'auto'}
      style={[
        styles.inputView,
        customField?.hasError && { borderBottomWidth: 6, borderColor: colors.danger },
        {
          backgroundColor: isDisabled ? transparentize(0.6, colors.light3) : colors.white,
          opacity,
          transform: [{ scale }],
        },
      ]}
    >
      <View style={styles.innerContainer}>
        <Text fontType="medium" size={fontSizes.xl2} color={colors.dark3}>
          {placeholder ?? name}
        </Text>
        {isIdentityCard && (
          <Text
            fontType="medium"
            size={fontSizes.md2}
            color={colors.dark3}
            style={{ marginLeft: 4 }}
          >
            ({identityCardDescription()})
          </Text>
        )}
        {customField?.required && !isDisabled && (
          <Text fontType="bold" style={styles.asterisk}>
            *
          </Text>
        )}
        {isDisabled && (
          <FontAwesome style={{ marginLeft: 8 }} name="lock" size={16} color={colors.light2} />
        )}
      </View>
      <View style={[styles.innerContainer, styles.borderContainer]}>
        {isIdentityCard && hasNumberOfCharacters && <Text size={fontSizes.xxl}>...</Text>}

        {(customField?.type === CustomFieldType.PHONE ||
          customField?.type === CustomFieldType.HOST_PHONE) && (
          <CountryPicker countryCode={countryCode} />
        )}
        {customField?.type !== CustomFieldType.DROPDOWN &&
          customField?.type !== CustomFieldType.SCALE &&
          customField?.type !== CustomFieldType.BOOLEAN &&
          customField?.type !== CustomFieldType.VISIT_REASON && (
            <TextInput
              {...rest}
              ref={ref}
              value={value}
              autoCorrect={false}
              placeholder={placeholder}
              keyboardType={keyboardType}
              autoCapitalize={autoCapitalize}
              onChangeText={handleChangeText}
              placeholderTextColor={colors.light3}
              style={[styles.input, style, { color: isDisabled ? colors.light2 : colors.dark3 }]}
              selectionColor={colors.primary}
              underlineColorAndroid="transparent"
              onFocus={handleFocus}
              onBlur={handleBlur}
              onKeyPress={handlePressEnter}
              onSubmitEditing={onSubmit}
            />
          )}
        {(customField?.type === CustomFieldType.DROPDOWN ||
          customField?.type === CustomFieldType.VISIT_REASON) && (
          <DropdownList customField={customField} value={value} onChangeText={onChangeText} />
        )}

        {customField?.type === CustomFieldType.BOOLEAN && (
          <Switch
            style={{ marginVertical: 24, height: 36 }}
            trackColor={{ false: colors.light2, true: colors.primary }}
            thumbColor={value ? colors.primary : colors.light2}
            value={!!value}
            onValueChange={(e) => handleChangeText(`${e}`)}
          />
        )}

        {customField?.type === CustomFieldType.SCALE && (
          <View style={styles.scaleContainer}>
            {scales.map((scale) => (
              <TouchableOpacity
                key={scale}
                onPress={() => onChangeText?.(scale)}
                style={[
                  styles.scaleValue,
                  {
                    backgroundColor: scale === value ? colors.primary : colors.white,
                  },
                ]}
              >
                <Text
                  fontType="medium"
                  color={scale === value ? colors.white : colors.primary}
                  size={fontSizes.xl}
                >
                  {scale}
                </Text>
              </TouchableOpacity>
            ))}
          </View>
        )}
      </View>
    </Animated.View>
  );
};

export default forwardRef(CustomFieldInput);

const styles = StyleSheet.create({
  inputView: {
    ...defaultShadow,
    width: '80%',
    marginTop: 32,
    paddingHorizontal: 32,
    paddingVertical: 24,
    paddingBottom: 28,
    borderRadius: 8,
    backgroundColor: colors.white,
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  borderContainer: {
    borderBottomColor: colors.light3,
    borderBottomWidth: 2,
    borderRadius: 4,
  },
  asterisk: {
    fontSize: fontSizes.xl,
    color: colors.danger,
    marginLeft: 8,
  },
  input: {
    fontFamily: fontFamily.medium,
    color: colors.dark3,
    marginTop: 4,
    paddingLeft: 4,
    width: '100%',
    height: 60,
    fontSize: fontSizes.xxl,
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
    }),
  },
  scaleContainer: {
    marginTop: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  scaleValue: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    aspectRatio: 0.9,
    borderWidth: 1,
    borderColor: colors.primary,
    borderRadius: 4,
    marginHorizontal: 4,
  },
});
