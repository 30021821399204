import { Feather } from '@expo/vector-icons';
import { Text } from '_/components';
import { fontSizes } from '_/config/theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Dimensions, TouchableOpacity } from 'react-native';

const { width } = Dimensions.get('window');

interface DeliveryButtonProps {
  onPress: () => void;
  textColor: string;
}

const DeliveryButton: React.FC<DeliveryButtonProps> = ({ onPress, textColor }) => {
  const { t } = useTranslation();

  return (
    <View style={styles.viewBottomContent}>
      <TouchableOpacity hitSlop={{ top: 12, right: 12, bottom: 12, left: 12 }} onPress={onPress}>
        <View style={styles.buttonBottomContent}>
          {width > 700 && <Feather name="package" size={24} color={textColor} />}
          <Text
            style={{ marginHorizontal: 8 }}
            color={textColor}
            fontType="medium"
            size={fontSizes.lg}
          >
            {t('homeScreen.Delivery')}?
          </Text>
          <Text color={textColor} size={fontSizes.lg}>
            {t('homeScreen.clickHere')}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default DeliveryButton;

const styles = StyleSheet.create({
  viewBottomContent: {
    height: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
  },
  buttonBottomContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
});
