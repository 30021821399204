import { CustomizationsModel, IpadLang, HostsModel, VisitsModel } from '@w3lcome/types';
import { format } from 'date-fns';

import { VisitsModelCheckin } from './../interfaces/VisitsModelCheckin';
import { getDateLocale } from './getDateLocale';

export function textReplace(
  text: string,
  lang: IpadLang,
  customization: CustomizationsModel | null,
  visit?: VisitsModel | Partial<VisitsModelCheckin> | undefined,
  host?: HostsModel
) {
  const locale = getDateLocale(lang);
  const now = new Date();
  const date = format(now, 'P', { locale });
  const time = format(now, 'p', { locale });

  return text
    .replace(/@company-name/g, customization?.displayName || '')
    .replace(/@empresa-nome/g, customization?.displayName || '')
    .replace(/@visitante-nome/g, visit?.name || '')
    .replace(/@visitante-email/g, visit?.email || '')
    .replace(/@host-name/g, visit?.host?.name || host?.name || '')
    .replace(/@visitante-anfitriao/g, visit?.host?.name || '')
    .replace(/@visitor-name/g, visit?.name || '')
    .replace(/@visitor-email/g, visit?.email || '')
    .replace(/@visitor-identity_card/g, visit?.identity_card || '')
    .replace(/@visitor-phone/g, visit?.phone || '')
    .replace(/@visitor-address/g, visit?.address || '')
    .replace(/@visitor-company/g, visit?.companyname || '')
    .replace(/@visitor-companyname/g, visit?.companyname || '')
    .replace(/@visitor-sector/g, visit?.sector || '')
    .replace(/@visitor-department/g, visit?.sector || '')
    .replace(/@visitor-position/g, visit?.position || '')
    .replace(/@visitor-document/g, visit?.document || '')
    .replace(/@visitor-cpf/g, visit?.cpf || '')
    .replace(/@visitor-rg/g, visit?.rg || '')
    .replace(/@visitor-licence-plate/g, visit?.plate || '')
    .replace(/@visitor-plate/g, visit?.plate || '')
    .replace(/@today-date/g, date)
    .replace(/@hoje-dia/g, date)
    .replace(/@today-time/g, time)
    .replace(/@hoje-hora/g, time);
}
