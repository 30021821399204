import { createStackNavigator } from '@react-navigation/stack';
import Inactivity from '_/components/Inactivity';
import CheckoutFeedbackScreen from '_/screens/Checkout/Feedback';
import CheckoutMessageScreen from '_/screens/Checkout/Message';
import CheckoutVisitSelectScreen from '_/screens/Checkout/VisitSelect';
import React from 'react';

import { AppRoute } from './types';

const CheckoutStack = createStackNavigator();

export default function CheckoutStackScreen() {
  return (
    <Inactivity>
      <CheckoutStack.Navigator
        screenOptions={{ animationEnabled: true, headerShown: false, presentation: 'card' }}
      >
        <CheckoutStack.Screen
          name={AppRoute.CHECKOUT_VISITOR}
          component={CheckoutVisitSelectScreen}
        />
        <CheckoutStack.Screen
          name={AppRoute.CHECKOUT_FEEDBACK}
          component={CheckoutFeedbackScreen}
        />
        <CheckoutStack.Screen name={AppRoute.CHECKOUT_MESSAGE} component={CheckoutMessageScreen} />
      </CheckoutStack.Navigator>
    </Inactivity>
  );
}
