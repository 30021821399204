import { CustomizationsModel } from '@w3lcome/types';
import { VisitsModelCheckin } from '_/interfaces/VisitsModelCheckin';
import { isAfter, isBefore, subHours } from 'date-fns';

export function checkVisitValidity(
  visit: Partial<VisitsModelCheckin>,
  customization: CustomizationsModel | null
) {
  if (visit.checkinAt || !visit.meeting) {
    return false;
  }

  const now = new Date();
  const endCheckinAt = new Date(visit.meeting?.end);
  const startCheckinAt = subHours(
    new Date(visit.meeting.start),
    customization?.timeToCheckinBeforeMeetingInHours || 1
  );

  if (isBefore(now, startCheckinAt)) {
    return false;
  }

  if (isAfter(now, endCheckinAt)) {
    return false;
  }

  return true;
}
