import { Feather } from '@expo/vector-icons';
import { CustomFieldType } from '@w3lcome/types';
import { CustomFieldInput, Text } from '_/components';
import KeyboardContainer from '_/components/KeyboardContainer';
import { colors, fontSizes, defaultShadow } from '_/config/theme';
import { useAuth } from '_/hooks/AuthContext';
import { CustomFieldWithError } from '_/interfaces/CustomFieldWithError';
import { ipadApi } from '_/services/api';
import { validate } from 'email-validator';
import { BlurView } from 'expo-blur';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, StyleSheet, TextInput, TouchableOpacity, View } from 'react-native';
import { showMessage } from 'react-native-flash-message';

interface LogoutModalProps {
  setShow: (show: boolean) => void;
}

const LogoutModal: React.FC<LogoutModalProps> = ({ setShow }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [isFocusedInput, setIsFocusedInput] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { ipad, signout } = useAuth();

  const inputRef = useRef<TextInput>(null);
  const { t } = useTranslation();

  const field = {
    name: 'Email',
    placeholder: 'Email',
    required: true,
    type: CustomFieldType.EMAIL,
  } as CustomFieldWithError;

  function hideModal() {
    setShow(false);
  }

  async function logout() {
    setLoading(true);
    try {
      if (validate(email)) {
        await ipadApi.delete(ipad?.id as string, { params: { email } });
        signout();
      } else {
        setHasError(true);
      }
    } catch (error) {
      setShow(false);
      showMessage({
        message: t('homeScreen.logout.dontHavePermission'),
        type: 'danger',
      });
      console.error(error.message);
    }
    setLoading(false);
  }

  function validateEmail() {
    setIsFocusedInput(false);
    setHasError(!validate(email));
  }

  useEffect(() => {
    if (inputRef.current) {
      setTimeout(() => {
        setIsFocusedInput(true);
        inputRef.current?.focus();
      }, 800);
    }
  }, [inputRef.current]);

  return (
    <Modal animationType="fade" onRequestClose={hideModal} visible transparent>
      <BlurView intensity={80} style={styles.background}>
        <View style={styles.modal}>
          <TouchableOpacity style={styles.closeButton} activeOpacity={1} onPress={hideModal}>
            <Feather name="x" size={24} color={colors.dark3} />
          </TouchableOpacity>
          <KeyboardContainer
            keyboardOpen={isFocusedInput}
            inputName={field.name.toLocaleLowerCase()}
            onChange={setEmail}
            keyboardType="default"
          >
            <View style={styles.container}>
              <Text center size={fontSizes.xl} color={colors.danger}>
                {t('homeScreen.logout.message')}
              </Text>
              <CustomFieldInput
                editable={!loading}
                value={email}
                onChangeText={setEmail}
                customField={{ ...field, hasError }}
                ref={inputRef}
                onFocus={() => setIsFocusedInput(true)}
                onBlur={validateEmail}
              />
              <Text color={colors.danger} size={fontSizes.md2}>
                {hasError && t('infoScreen.missingFieldsError')}
              </Text>

              <View style={styles.buttons}>
                <TouchableOpacity style={styles.button} onPress={hideModal}>
                  <Text color={colors.dark3} size={fontSizes.xl2} fontType="bold">
                    {t('Cancel')}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity disabled={hasError} style={styles.button} onPress={logout}>
                  <Text color={colors.danger} size={fontSizes.xl2} fontType="bold">
                    {t('Confirm')}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </KeyboardContainer>
        </View>
      </BlurView>
    </Modal>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    height: '80%',
    width: '90%',
  },
  container: {
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: 8,
    paddingBottom: 32,
    ...defaultShadow,
  },
  closeButton: {
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 999,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 16,
    width: '80%',
  },
  button: {
    padding: 16,
  },
});

export default LogoutModal;
