import { images } from '_/assets';
import { Header, Text } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import { colors, defaultShadow, fontSizes } from '_/config/theme';
import { useAuth } from '_/hooks/AuthContext';
import { useVisit } from '_/hooks/VisitContext';
import { useNextCheckinScreen } from '_/hooks/useNextCheckinScreen';
import { AppRoute } from '_/navigation/types';
import logger from '_/services/logger';

import { CameraView, CameraType, CameraCapturedPicture, useCameraPermissions } from 'expo-camera';

import Constants from 'expo-constants';
import * as ImageManipulator from 'expo-image-manipulator';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyleSheet,
  View,
  ImageBackground,
  Animated,
  TouchableOpacity,
  Image,
  Platform,
  useWindowDimensions,
  Easing,
} from 'react-native';
import { showMessage } from 'react-native-flash-message';

import BottomModalContainer from './BottomModalContainer';
import faceApi from '_/helpers/faceScore';

const CheckinPictureScreen: React.FC = () => {
  const { t } = useTranslation();
  const { visit, setVisit } = useVisit();
  const { ipad } = useAuth();
  const { goToNextScreen } = useNextCheckinScreen(AppRoute.CHECKIN_PICTURE);
  const { width, height } = useWindowDimensions();

  const [startCameraState, setStartCameraState] = useState(false);
  const [isStartPicture, setIsStartPicture] = useState(false);

  const [countdown, setCountdown] = useState(6);
  const [isFaceDetected, setIsFaceDetected] = useState(!ipad?.hasFaceRecognitionIntegration);

  const takePictureTimeoutRef = useRef<number>();
  const animatedLabel = useRef(new Animated.Value(0)).current;
  const camera = useRef<CameraView>(null);

  const [permission, requestPermission] = useCameraPermissions();

  useEffect(() => {
    if (!visit.picture) {
      startCamera();
    }
  }, []);

  const startCamera = async () => {
    if (visit.picture) {
      return;
    }

    if (permission?.status !== 'granted') {
      const permission = await requestPermission();
      if (permission.status !== 'granted') {
        showMessage({
          message: t('error'),
          description: t('pictureScreen.CameraPermissionDenied'),
          backgroundColor: colors.danger,
        });
        return;
      }
    }

    setStartCameraState(true);
    toggleLabel(true);

    setTimeout(() => setIsStartPicture(true), 1000);
  };

  function startTimer() {
    if (ipad?.hasFaceRecognitionIntegration) {
      const timerId = window.setInterval(async () => {
        if (camera.current) {
          const picture = (await camera.current?.takePictureAsync()) as CameraCapturedPicture;
          const faceWasDetected = await validateFaceDetection(picture.uri);
          setIsFaceDetected(faceWasDetected);
        }

        if (visit.picture || !camera.current) {
          clearInterval(timerId);
        }
      }, 1500);
      return;
    }

    let countdown = 6;
    const timer = () => {
      if (countdown > 1) {
        setCountdown(countdown - 1);
        takePictureTimeoutRef.current = window.setTimeout(timer, 1000);
        countdown -= 1;
      } else {
        takePictureTimeoutRef.current = window.setTimeout(() => {
          takePicture();
        }, 1000);
      }
    };

    setCountdown(countdown);
    takePictureTimeoutRef.current = window.setTimeout(timer, 2000);
  }

  const toggleLabel = (isVisible: boolean) => {
    Animated.timing(animatedLabel, {
      toValue: isVisible ? 1 : 0,
      duration: 600,
      useNativeDriver: Platform.OS !== 'web',
      easing: Easing.inOut(Easing.cubic),
    }).start();
  };

  const takePicture = async () => {
    if (!camera.current || visit.picture) {
      return;
    }

    try {
      toggleLabel(false);
      setCountdown(6);
      clearTimeout(takePictureTimeoutRef.current);

      const picture = await camera.current?.takePictureAsync();

      if (picture?.uri) {
        if (ipad?.hasFaceRecognitionIntegration) {
          const faceWasDetected = await validateFaceDetection(picture.uri);

          if (!faceWasDetected) {
            showMessage({
              message: t('error'),
              description: t('pictureScreen.centerYourFace'),
              backgroundColor: colors.danger,
            });
            return;
          }
        }

        setIsStartPicture(false);

        const flippedPicture = await ImageManipulator.manipulateAsync(
          picture.uri,
          [{ flip: ImageManipulator.FlipType.Horizontal }],
          { compress: 1, base64: true }
        );
        setVisit({ picture: flippedPicture });
      } else {
        setVisit({ picture });
      }
    } catch (error) {
      logger(error);
      showMessage({
        message: t('error'),
        description: t('AccessDenied'),
        backgroundColor: colors.danger,
      });
    }
  };

  const retakePicture = () => {
    setVisit({ picture: null });
    startCamera();
  };

  const handleSkipPress = () => {
    setVisit({ picture: null });
    setTimeout(goToNextScreen, 400);
  };

  const handleNextPress = () => {
    goToNextScreen();
  };

  const handlePressScreen = () => {
    if (!isStartPicture || !camera.current || visit.picture || !isFaceDetected) {
      return;
    }

    takePicture();
    clearTimeout(takePictureTimeoutRef.current);
  };

  const PicturePreview = ({ photo }: { photo: CameraCapturedPicture }) => {
    return (
      <View style={styles.preview}>
        <ImageBackground source={{ uri: photo.uri }} style={{ flex: 1 }} />
      </View>
    );
  };

  const handleGoBack = () => {
    setVisit({ picture: null });
  };

  const validateFaceDetection = async (pictureUri: string) => {
    const faceOnScreen = await faceApi.validateFaceScore(pictureUri, 0.8);

    return faceOnScreen;
  };

  const renderCameraOrPreview = () => {
    if (visit.picture) {
      return <PicturePreview photo={visit.picture} />;
    }

    if (startCameraState) {
      return (
        <>
          <CameraView
            onCameraReady={startTimer}
            facing={(ipad?.settingsPictureCamera.toLowerCase() as CameraType) || 'front'}
            style={{ flex: 1, width: '100%', height: '100%' }}
            ref={camera}
          >
            <TouchableOpacity style={styles.touch} onPress={handlePressScreen}>
              {ipad?.hasFaceRecognitionIntegration || height >= width && (
                <>
                  <View style={styles.picturePlaceholderBorder} />
                  <Image
                    source={
                      height >= width
                        ? images.picturePlaceholder
                        : images.picturePlaceholderHorizontal
                    }
                    style={{
                      ...styles.picturePlaceholder,
                      ...(isFaceDetected &&
                        ipad?.hasFaceRecognitionIntegration &&
                        styles.detectSuccess),
                      ...(!isFaceDetected &&
                        ipad?.hasFaceRecognitionIntegration &&
                        styles.detectFailed),
                    }}
                  />
                  <View style={styles.picturePlaceholderBorder} />
                </>
              )}
            </TouchableOpacity>
          </CameraView>

          <View pointerEvents="none" style={styles.labelContainer}>
            <Text center size={80} style={[styles.label, { marginTop: -12 }]}>
              {countdown < 6 && countdown.toString()}
            </Text>
            <Animated.View
              style={{
                opacity: animatedLabel.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, 0.9],
                  extrapolate: 'clamp',
                }),
                transform: [
                  {
                    scale: animatedLabel.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0.5, 1],
                    }),
                  },
                  {
                    translateY: animatedLabel.interpolate({
                      inputRange: [0, 1],
                      outputRange: [500, 0],
                    }),
                  },
                ],
              }}
            >
              <Text center size={Platform.OS === 'web' ? 80 : 120} style={styles.label}>
                {t(`pictureScreen.${Constants.expoConfig?.extra?.labelPicture}`)}
              </Text>
              <Text center size={30} style={[styles.label, styles.bottomLabel]}>
                {t(`pictureScreen.${Constants.expoConfig?.extra?.labelBottomPicture}`)}
              </Text>
            </Animated.View>
          </View>
        </>
      );
    }

    return (
      <View style={{ width: '100%', height: '100%', flex: 1 }}>
        <View style={styles.center}>
          <Text size={fontSizes.xl} color={colors.dark2} fontType="semibold">
            {t('pictureScreen.CameraPermissionDenied')}
          </Text>
          <Text size={fontSizes.xl} color={colors.dark2} fontType="semibold">
            {t('pictureScreen.ContinueButton')}
          </Text>
        </View>
        <View style={styles.touch}>
          <View style={styles.picturePlaceholderBorder} />
          <Image
            source={
              height >= width ? images.picturePlaceholder : images.picturePlaceholderHorizontal
            }
            style={styles.picturePlaceholder}
          />
          <View style={styles.picturePlaceholderBorder} />
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <ContainerWidthLimit>
        <Header onGoBack={handleGoBack} />
        <View style={styles.content}>
          <View style={styles.innerContentShadow}>
            <View style={styles.innerContent}>{renderCameraOrPreview()}</View>
          </View>
        </View>
        <BottomModalContainer
          onNextPress={handleNextPress}
          onRetakePress={retakePicture}
          onSkipPress={handleSkipPress}
          cameraAllowed={startCameraState}
          isVisible={!!visit.picture || !startCameraState}
        />
      </ContainerWidthLimit>
    </View>
  );
};

export default CheckinPictureScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    alignItems: 'center',
  },
  content: {
    flex: 1,
    paddingHorizontal: 32,
    paddingBottom: 100,
  },
  innerContentShadow: {
    flex: 1,
    borderRadius: 8,
    ...defaultShadow,
  },
  innerContent: {
    flex: 1,
    borderRadius: 8,
    overflow: 'hidden',
  },
  touch: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 10,
    flexDirection: 'row',
  },
  labelContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  preview: {
    backgroundColor: 'transparent',
    flex: 1,
    width: '100%',
    height: '100%',
  },
  label: {
    color: colors.white,
    textTransform: 'uppercase',
    textShadowColor: '#222',
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 30,
    paddingHorizontal: 20,
  },
  bottomLabel: {
    marginTop: -20,
    marginBottom: 20,
  },
  picturePlaceholderBorder: {
    flex: Platform.OS === 'web' ? 1 : 0,
    backgroundColor: '#323438',
    opacity: 0.3,
  },
  picturePlaceholder: {
    ...Platform.select({
      web: {
        width: '100%',
      },
      ios: {
        flex: 1,
      },
      android: {
        flex: 1,
      },
    }),
    height: '100%',
  },
  center: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  detectSuccess: {
    borderWidth: 4,
    borderColor: colors.success,
  },
  detectFailed: {
    borderWidth: 4,
    borderColor: colors.warning,
  },
});
