import { BadgeConfig } from '@w3lcome/types';
import { colors } from '_/config/theme';
import React, { useRef } from 'react';
import { Image, View, Text } from 'react-native';
import ViewShot from 'react-native-view-shot';

interface BadgeProps {
  badge?: BadgeConfig;
  onPrint?: (uri: string) => void;
}

const Badge = ({ badge, onPrint }: BadgeProps) => {
  const ref = useRef<ViewShot>(null);

  const handlePrintBadge = () => {
    setTimeout(() => {
      ref.current?.capture?.().then((uri) => {
        onPrint?.(uri);
      });
    }, 1000);
  };

  if (!badge) {
    return null;
  }

  return (
    <View
      pointerEvents="none"
      style={{
        opacity: 0,
        transform: [{ scale: 0 }],
        position: 'absolute',
        top: -200,
        left: -200,
      }}
      onLayout={handlePrintBadge}
    >
      <ViewShot
        ref={ref}
        style={{
          width: badge.width,
          height: badge.height,
          position: 'relative',
          backgroundColor: colors.white,
          borderRadius: 8,
          borderWidth: badge.isTest ? 2 : 0,
          borderColor: colors.black,
        }}
      >
        {badge.fields.map((field, index) => {
          if (field.type === 'text') {
            return (
              <Text
                numberOfLines={1}
                ellipsizeMode="tail"
                allowFontScaling
                adjustsFontSizeToFit
                minimumFontScale={0.8}
                key={index}
                style={[{ position: 'absolute' }, field.styles]}
              >
                {field.value}
              </Text>
            );
          } else if (field.type === 'image') {
            return (
              <Image
                key={index}
                style={[{ position: 'absolute', resizeMode: 'cover' }, field.styles]}
                source={{ uri: field.value }}
                resizeMethod="scale"
              />
            );
          }

          return null;
        })}
      </ViewShot>
    </View>
  );
};

export default Badge;
