import { IpadIntegrationsModels } from '@w3lcome/types';
import { VisitsModelCheckin } from '_/interfaces/VisitsModelCheckin';
import axios from 'axios';
import { format } from 'date-fns';

export async function runAccessIntegration(
  visit: Partial<VisitsModelCheckin>,
  integration: IpadIntegrationsModels
) {
  const url = `${integration.content.url}/vms/checkin`;

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Basic ${integration.content.token}`,
  };

  await axios.post(
    url,
    {
      id: visit.id,
      DataVisita: format(new Date(visit.checkinAt || ''), 'yyyy-MM-dd HH:mm:ss'),
      PeriodoVisita: 600,
      VisitadoId: visit.host?.id,
      Visitado: visit.host,
      Visitante: {
        Nome: visit.name,
        Documento: visit.document,
        Telefone: visit.phone,
        CPF: visit.cpf,
        RG: visit.rg,
        Empresa: visit.companyname,
        Cargo: visit.position,
        Setor: visit.sector,
        Foto: visit?.picture?.base64,
      },
    },
    { headers }
  );
}
