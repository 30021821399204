export enum PrinterPaper {
  Paper17mmx54mm = '17mmx54mm',
  Paper17mmx87mm = '17mmx87mm',
  Paper23mmx23mm = '23mmx23mm',
  Paper29mmx42mm = '29mmx42mm',
  Paper29mmx90mm = '29mmx90mm',
  Paper38mmx90mm = '38mmx90mm',
  Paper39mmx48mm = '39mmx48mm',
  Paper52mmx29mm = '52mmx29mm',
  Paper62mmx29mm = '62mmx29mm',
  Paper62mmx100mm = '62mmx100mm',
  Paper60mmx86mm = '60mmx86mm',
  Paper54mmx29mm = '54mmx29mm',
  Paper102mmx51mm = '102mmx51mm',
  Paper102mmx152mm = '102mmx152mm',
  Paper103mmx164mm = '103mmx164mm',
  Paper12mm = '12mm',
  Paper29mm = '29mm',
  Paper38mm = '38mm',
  Paper50mm = '50mm',
  Paper54mm = '54mm',
  Paper62mm = '62mm',
  Paper62mmRB = '62mmRB',
  Paper102mm = '102mm',
  Paper103mm = '103mm',
  PaperDT90mm = 'DT90mm',
  PaperDT102mm = 'DT102mm',
  PaperDT102mmx51mm = 'DT102mmx51mm',
  PaperDT102mmx152mm = 'DT102mmx152mm',
}
