import Constants from 'expo-constants';

const appConfig = {
  apiUrl: Constants.expoConfig?.extra?.apiUrl as string,
  apiStgUrl: Constants.expoConfig?.extra?.apiStgUrl as string,
  dashboardUrl: Constants.expoConfig?.extra?.dashboardUrl as string,
  company: Constants.expoConfig?.extra?.company as string,
  apiTimeout: 20000,
  environmentKey: '@app:environment',
  tokenKey: '@app:token',
  ipadKey: '@app:ipad',
  companyKey: '@app:company',
  customizationKey: '@app:customization',
  languageKey: '@app:language',
  hosts: '@app:hosts',
  defaultCountry: 'BR',
  localElectronUrl: 'http://localhost:8080',
  lgpdEndpoint: '@app:endpoint',
  lgpdAuthentication: '@app:authentication',
  lgpdCustomizationKey: '@app:lgpdCustomizationKey',
  //TODO: Remove it. This company will not be able to display to print the label and do not print automatically.
  companyIdSbt: '9501fedf-3f2f-4e9c-b20e-d8d39097f919',
};

export default appConfig;
