import AsyncStorage from '@react-native-async-storage/async-storage';
import { IpadLang } from '@w3lcome/types';
import appConfig from '_/config/app';
import Constants from 'expo-constants';
import i18n, { InitOptions } from 'i18next';
import Backend from 'i18next-chained-backend';
import HttpApi from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { initReactI18next } from 'react-i18next';

const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: async (language: any) => {
    const persistedLocale = await AsyncStorage.getItem(appConfig.languageKey);
    if (!persistedLocale) {
      return language(IpadLang.EN);
    }
    return language(JSON.parse(persistedLocale).code);
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

async function initI18n() {
  const customization = await AsyncStorage.getItem(appConfig.customizationKey);

  const translationId = customization && JSON.parse(customization).translationId;

  const timestamp = new Date().getTime();
  const loadPath = translationId
    ? `${Constants.expoConfig?.extra?.assetsURL}/locales/${translationId}/totem/{{lng}}/translation.json?timestamp=${timestamp}`
    : `${Constants.expoConfig?.extra?.assetsURL}/locales/totem/{{lng}}/translation.json?timestamp=${timestamp}`;

  const initConfig: InitOptions = {
    fallbackLng: IpadLang.EN,
    debug: false,
    backend: {
      backends: [LocalStorageBackend, HttpApi],
      backendOptions: [
        {
          prefix: 'myApp_',
          expirationTime: 3000 * 24 * 60 * 60 * 1000,
        },
        {
          loadPath,
        },
      ],
      cacheHitMode: 'refresh',
    },
    cache: {
      enabled: true,
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  };

  i18n
    .use(languageDetector as any)
    .use(initReactI18next)
    .use(Backend)
    .init(initConfig);
}

initI18n();

export default i18n;
