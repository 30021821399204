import { images } from '_/assets';
import { colors, fontSizes } from '_/config/theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import Text from '../Text';

interface PoweredByProps {
  style?: StyleProp<ViewStyle>;
}

export const PoweredBy: React.FC<PoweredByProps> = ({ style }) => {
  const { t } = useTranslation();

  return (
    <View style={[styles.container, style]}>
      <Text center color={colors.light2} fontType="medium" size={fontSizes.sm}>
        {t('PoweredBy')}
      </Text>
      <Image resizeMode="contain" source={images.poweredByLogo} style={styles.logo} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  logo: {
    height: 32,
    width: 80,
  },
});
