import AsyncStorage from '@react-native-async-storage/async-storage';
import appConfig from '_/config/app';
import { useAuth } from '_/hooks/AuthContext';
import { CustomizationLgpdModel } from '_/interfaces/CustomizationLgpdTotem';
import logger from '_/services/logger';
import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';

export interface CustomizationLgpdContextData {
  setDataLgpd: (data: CustomizationLgpdModel) => void;
  loadDataLgpd: () => void;
  customizationLgpd: CustomizationLgpdModel | null;
  getOrganization(value?: string | null): void;
}

const CustomizationLgpdContext = createContext<CustomizationLgpdContextData>(
  {} as CustomizationLgpdContextData
);

type CustomizationLgpdType = {
  children: React.ReactNode;
};

export const CustomizationLgpdProvider: React.FC<CustomizationLgpdType> = ({ children }) => {
  const [customizationLgpd, setCustomizationLgpd] = useState<CustomizationLgpdModel | null>(null);

  const { endpointLgpd, authenticationLgpd } = useAuth();

  async function setDataLgpd(data: CustomizationLgpdModel) {
    await AsyncStorage.setItem(appConfig.lgpdCustomizationKey, JSON.stringify(data));
    setCustomizationLgpd(data);
  }

  async function loadDataLgpd() {
    const customizationLgpdData = await AsyncStorage.getItem(appConfig.lgpdCustomizationKey);
    if (customizationLgpdData) {
      setCustomizationLgpd(JSON.parse(customizationLgpdData));
      return;
    }

    getOrganization();
  }

  async function getOrganization(value = endpointLgpd) {
    try {
      const { data } = await axios.get(`${value}/W-AccessAPI/v1/companies/gdpr`, {
        headers: {
          WAccessAuthentication: authenticationLgpd ?? '',
          WAccessUtcOffset: '-180',
          'Referer': '',
          "Access-Control-Allow-Origin": "*",
        },
      });
      setDataLgpd(data);
    } catch (error) {
      logger(error);
    }
  }

  useEffect(() => {
    if (!endpointLgpd) {
      return;
    }

    const halfHour = 30 * 60 * 1000;
    const intervalId = setInterval(() => {
      getOrganization();
    }, halfHour);

    return () => clearInterval(intervalId);
  }, [endpointLgpd]);

  return (
    <CustomizationLgpdContext.Provider
      value={{
        loadDataLgpd,
        setDataLgpd,
        customizationLgpd,
        getOrganization,
      }}
    >
      {children}
    </CustomizationLgpdContext.Provider>
  );
};

export function useCustomizationLgpd(): CustomizationLgpdContextData {
  const context = useContext(CustomizationLgpdContext);

  if (!context) {
    throw new Error('useCustomizationLgpd must be used within an CustomizationLgpdProvider');
  }

  return context;
}
