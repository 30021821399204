import React, { useMemo } from 'react';
import { View, useWindowDimensions, ViewProps } from 'react-native';

const ContainerWidthLimit: React.FC<ViewProps> = ({ children, style, ...rest }) => {
  const { width, height } = useWindowDimensions();

  const viewWidth = useMemo(() => {
    if (height > width) {
      return width;
    }

    return height + (width - height) / 2;
  }, [width, height]);

  return (
    <View
      {...rest}
      style={[
        {
          flex: 1,
          width: viewWidth,
        },
        style,
      ]}
    >
      {children}
    </View>
  );
};

export default ContainerWidthLimit;
