import { useNavigation } from '@react-navigation/native';
import { images } from '_/assets';
import { Button, Input, LanguageButton, MultiTap, Text } from '_/components';
import { Feather } from '@expo/vector-icons';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import KeyboardContainer from '_/components/KeyboardContainer';
import LanguagesChoose from '_/components/LanguagesChoose';
import { PoweredBy } from '_/components/PoweredBy';
import { colors, fontSizes } from '_/config/theme';
import { useAuth } from '_/hooks/AuthContext';
import { AppRoute, LGPDCheckinProps } from '_/navigation/types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, StyleSheet, View } from 'react-native';

import { useCustomizationLgpd } from '../hooks/CustomizationLgpdContext';
import { useVisitLgpdContext } from '../hooks/VisitLgpdContext';
import { CheckBox } from 'react-native-elements';

const LgpdCheckin: React.FC = () => {
  const { signout } = useAuth();
  const { customizationLgpd, getOrganization } = useCustomizationLgpd();

  const [isFocusedInput, setIsFocusedInput] = useState(false);
  const [closeLanguageScreen, setCloseLanguageScreen] = useState(false);
  const { t } = useTranslation();

  const customizationLabel = customizationLgpd?.CompanyAuxText02?.toLocaleUpperCase();

  const {
    setVisit,
    resetData,
    getVisitDataByField,
    visit,
    getVisitorByDocument,
    isAllowText,
    setIsAllowText,
  } = useVisitLgpdContext();

  const { navigate } = useNavigation<LGPDCheckinProps>();

  useEffect(() => {
    resetData();
  }, []);

  async function handleSubmit() {
    await getVisitorByDocument();
    navigate(AppRoute.LGPD_TOTEM_CHECKIN_INFORMATION);
  }

  const keyboardType = useMemo(() => {
    if (isAllowText) {
      return 'default';
    }

    if (customizationLgpd?.CompanyAuxChk01) {
      return 'numeric';
    }

    return 'default';
  }, [customizationLgpd, isAllowText]);

  function onChange(value: string | number) {
    setVisit({ IdNumber: value });
  }

  return (
    <KeyboardContainer
      keyboardOpen={isFocusedInput}
      onSubmit={handleSubmit}
      onChange={(text) => onChange(text)}
      inputName={customizationLabel ?? ''}
      keyboardType={keyboardType}
    >
      <View style={[styles.container, { alignItems: 'center' }]}>
        <ContainerWidthLimit>
          <View style={styles.header}>
            <PoweredBy style={{ opacity: 0 }} />

            <MultiTap numTaps={3} onPress={getOrganization}>
              <View style={styles.viewLogo}>
                <Image
                  resizeMode="contain"
                  source={
                    customizationLgpd?.CompanyLogo
                      ? { uri: customizationLgpd?.CompanyLogo }
                      : images.poweredByLogo
                  }
                  style={styles.logo}
                />
              </View>
            </MultiTap>

            <MultiTap numTaps={5} onPress={signout}>
              <PoweredBy />
            </MultiTap>
          </View>

          <View style={styles.content}>
            <Text center size={fontSizes.xl}>
              {t('lgpdHomeMessage')}
            </Text>
            <View style={{ width: '80%', marginTop: 24 }}>
              <View style={styles.innerContainer}>
                <Input
                  label={customizationLabel ?? ''}
                  onChangeText={(text) => onChange(text)}
                  onFocus={() => setIsFocusedInput(true)}
                  onBlur={() => setIsFocusedInput(false)}
                  value={getVisitDataByField('IdNumber') as string}
                  placeholder={customizationLabel}
                  keyboardType={keyboardType}
                  containerStyle={{ flex: 1 }}
                  style={{ letterSpacing: 2 }}
                  labelSize={fontSizes.xl}
                  required
                  staticLabel
                />
              </View>

              {customizationLgpd?.CompanyAuxChk01 && (
                <View style={{ flexDirection: 'row' }}>
                  <CheckBox
                    key="foreigner"
                    title={t('clickHereForeigner')}
                    checkedIcon={<Feather name="check-square" size={26} />}
                    uncheckedIcon={<Feather name="square" size={26} />}
                    textStyle={styles.checkboxTextStyles}
                    containerStyle={styles.checkboxContainerStyles}
                    checkedColor={colors.primary}
                    checked={isAllowText}
                    onPress={() => setIsAllowText(!isAllowText)}
                  />
                </View>
              )}

              <Button
                backgroundColor={colors.primary}
                labelColor={colors.white}
                onPress={handleSubmit}
                disabled={!visit.IdNumber}
                buttonStyles={styles.button}
                rightIcon="arrow-right"
              >
                {t('Confirm')}
              </Button>
            </View>
          </View>
        </ContainerWidthLimit>
        <View style={styles.bottomContainer}>
          {!isFocusedInput && (
            <LanguageButton textColor={colors.dark2} onPress={() => setCloseLanguageScreen(true)} />
          )}
        </View>
        {closeLanguageScreen && <LanguagesChoose close={setCloseLanguageScreen} />}
      </View>
    </KeyboardContainer>
  );
};

export default LgpdCheckin;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  content: {
    alignItems: 'center',
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 32,
    marginBottom: 64,
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  viewLogo: {
    height: 44,
    width: 155,
    marginVertical: 48,
    flex: 1,
    alignItems: 'center',
  },
  logo: {
    height: 44,
    width: 155,
  },
  bottomContainer: {
    paddingVertical: 12,
  },
  button: {
    marginTop: 26,
    marginBottom: 22,
    height: 64,
    justifyContent: 'space-between',
    width: '60%',
    alignSelf: 'center',
  },
  checkboxContainerStyles: {
    marginTop: 12,
    backgroundColor: colors.white,
    borderWidth: 0,
  },
  checkboxTextStyles: {
    fontSize: fontSizes.md2,
    color: colors.dark1,
  },
});
