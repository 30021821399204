import { Feather } from '@expo/vector-icons';
import { Text } from '_/components';
import { colors, fontSizes } from '_/config/theme';
import { usePrinters } from '_/hooks/PrintersContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, Pressable } from 'react-native';

const PrinterButton: React.FC = () => {
  const { t } = useTranslation();
  const { printer, printerConnected, checkPrinter } = usePrinters();

  if (Platform.OS !== 'ios' || !printer || printerConnected === undefined) {
    return null;
  }

  return (
    <Pressable
      style={styles.printer}
      onPress={checkPrinter}
      hitSlop={{ top: 12, bottom: 12, left: 12, right: 12 }}
    >
      <Feather
        name="printer"
        size={26}
        color={printerConnected ? colors.dark1 : colors.danger}
        style={{ opacity: printerConnected ? 0.1 : 1 }}
      />

      {!printerConnected && (
        <Text
          fontType="semibold"
          size={fontSizes.md}
          color={colors.danger}
          style={{ marginLeft: 8 }}
        >
          {t('homeScreen.printerNotFoundNetwork')}
        </Text>
      )}
    </Pressable>
  );
};

export default PrinterButton;

const styles = StyleSheet.create({
  printer: {
    position: 'absolute',
    left: 12,
    top: 36,
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 100,
    backgroundColor: colors.white,
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 6,
  },
});
