import { Feather } from '@expo/vector-icons';
import { colors, fontSizes } from '_/config/theme';
import { useCustomization } from '_/hooks/CustomizationContext';
import { CustomFieldWithError } from '_/interfaces/CustomFieldWithError';
import { transparentize } from 'polished';
import React, { useRef } from 'react';
import { StyleSheet, View, Platform, TouchableOpacity, Animated } from 'react-native';

import CircleCheck from '../CircleCheck';
import Text from '../Text';
import { useTranslation } from 'react-i18next';

interface DropdownListProps {
  customField?: CustomFieldWithError;
  value: string | undefined;
  onChangeText: ((text: string) => void) | undefined;
}

export const DropdownList: React.FC<DropdownListProps> = ({ customField, value, onChangeText }) => {
  const scrollY = useRef(new Animated.Value(0)).current;
  const { customization } = useCustomization();
  const { i18n } = useTranslation();

  if (!customField?.options) {
    return null;
  }

  const totalItems = customField.options.length;

  return (
    <View style={styles.contentOption}>
      <Animated.FlatList
        data={customField.options}
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], {
          useNativeDriver: Platform.OS !== 'web',
        })}
        keyExtractor={(item) => item.id}
        persistentScrollbar
        showsVerticalScrollIndicator
        nestedScrollEnabled
        renderItem={({ item, index }) => {
          const itemHeight = 62 + 12;

          const getItemOffset = (index: number) => {
            if (index < 0) return index;

            if (index >= totalItems) return (totalItems - 1) * itemHeight;

            return index * itemHeight;
          };

          const inputRange = [
            getItemOffset(index - 3),
            getItemOffset(index - 2),
            getItemOffset(index),
            getItemOffset(index + 1),
          ];

          const scale = scrollY.interpolate({
            inputRange,
            outputRange: [0.95, 1, 1, 0.95],
            extrapolate: 'clamp',
          });

          const opacity = scrollY.interpolate({
            inputRange,
            outputRange: [0.8, 1, 1, 0.8],
            extrapolate: 'clamp',
          });

          return (
            <Animated.View style={{ transform: [{ scale }], opacity }} key={item.id}>
              <TouchableOpacity style={styles.option} onPress={() => onChangeText?.(item.name)}>
                <CircleCheck isSelect={value === item.name} />

                <Text
                  style={{ marginLeft: 8 }}
                  color={
                    value === item.name ? customization?.mainColor || colors.primary : colors.dark3
                  }
                  fontType={value === item.name ? 'bold' : 'medium'}
                  size={fontSizes.lg2}
                >
                  {item.localizedName?.[i18n.language] || item.name}
                </Text>
              </TouchableOpacity>
            </Animated.View>
          );
        }}
      />
      {customField.options.length > 3 && (
        <Animated.View pointerEvents="none" style={styles.arrowContainer}>
          <Animated.View
            style={{
              transform: [
                {
                  translateY: scrollY.interpolate({
                    inputRange: [0, 2],
                    outputRange: [0, 0.5],
                  }),
                },
              ],
            }}
          >
            <Feather name="chevron-down" size={34} color={colors.dark3} />
          </Animated.View>
        </Animated.View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  contentOption: {
    maxHeight: 250,
    width: '100%',
    marginTop: 12,
  },
  option: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 62,
    paddingHorizontal: 24,
    paddingVertical: 16,
    backgroundColor: transparentize(0.5, colors.light3),
    width: '100%',
    borderRadius: 4,
    marginBottom: 12,
  },
  arrowContainer: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    overflow: 'hidden',
  },
});
