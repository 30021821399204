import { Feather } from '@expo/vector-icons';
import { colors } from '_/config/theme';
import { getColorByContrast } from '_/helpers/getColorContrast';
import React from 'react';
import {
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator,
  StyleProp,
  ViewStyle,
  TextStyle,
} from 'react-native';
import * as Animatable from 'react-native-animatable';

import Text from '../Text';

export interface ButtonProps {
  loading?: boolean;
  outline?: boolean;
  link?: boolean;
  small?: boolean;
  large?: boolean;
  animation?: string;
  delay?: number;
  onPress: () => void;
  buttonStyles?: StyleProp<ViewStyle>;
  textStyles?: TextStyle;
  containerStyles?: StyleProp<ViewStyle>;
  leftIconStyles?: StyleProp<TextStyle>;
  leftIcon?: string | React.ReactElement;
  rightIconStyles?: StyleProp<TextStyle>;
  rightIcon?: string | React.ReactElement;
  disabled?: boolean;
  backgroundColor?: string;
  labelColor?: string;
  children: any;
}

const Button: React.FC<ButtonProps> = ({
  loading,
  outline,
  link,
  small,
  large,
  animation,
  delay,
  onPress,
  buttonStyles,
  textStyles,
  containerStyles,
  leftIconStyles,
  leftIcon,
  rightIconStyles,
  rightIcon,
  disabled,
  backgroundColor,
  labelColor,
  children,
}) => {
  if (backgroundColor && labelColor) {
    labelColor = getColorByContrast(backgroundColor, labelColor);
  }

  return (
    <Animatable.View style={containerStyles} animation={animation} delay={delay}>
      <TouchableOpacity
        onPress={onPress}
        delayPressIn={25}
        disabled={loading || disabled}
        hitSlop={{ top: 4, right: 4, bottom: 4, left: 4 }}
        style={[
          styles.button,
          !!backgroundColor && { backgroundColor, shadowColor: backgroundColor },
          outline && styles.outline,
          small && styles.small,
          large && styles.large,
          link && styles.link,
          disabled && styles.disabled,
          buttonStyles,
        ]}
      >
        {!loading && (
          <>
            {typeof leftIcon === 'string' ? (
              <Feather
                name={leftIcon as any}
                style={[
                  styles.icon,
                  styles.iconLeft,
                  !!labelColor && { color: labelColor },
                  (outline || disabled) && styles.colorOutline,
                  small && styles.iconSmall,
                  large && styles.iconLarge,
                  link && styles.textLink,
                  leftIconStyles,
                ]}
              />
            ) : (
              leftIcon
            )}

            {typeof children === 'string' ? (
              <Text
                center
                size={18}
                color={colors.white}
                fontType="medium"
                style={[
                  !!labelColor && { color: labelColor },
                  (outline || disabled) && styles.colorOutline,
                  link && styles.textLink,
                  small && styles.textSmall,
                  large && styles.textLarge,
                  textStyles,
                ]}
              >
                {children}
              </Text>
            ) : (
              children
            )}
            {typeof rightIcon === 'string' ? (
              <Feather
                name={rightIcon as any}
                style={[
                  styles.icon,
                  styles.iconRight,
                  !!labelColor && { color: labelColor },
                  (outline || disabled) && styles.colorOutline,
                  small && styles.iconSmall,
                  large && styles.iconLarge,
                  link && styles.textLink,
                  rightIconStyles,
                ]}
              />
            ) : (
              rightIcon
            )}
          </>
        )}
        {loading && (
          <ActivityIndicator
            size={small ? 12 : large ? 22 : 18}
            color={link || outline ? colors.dark1 : colors.white}
          />
        )}
      </TouchableOpacity>
    </Animatable.View>
  );
};

export default Button;

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: colors.primary,
    paddingHorizontal: 24,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: 'transparent',
    height: 54,
    shadowColor: colors.primary,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.3,
    shadowRadius: 12,
  },
  outline: {
    shadowOpacity: 0,
    backgroundColor: 'transparent',
    borderColor: colors.light3,
  },
  disabled: {
    shadowColor: colors.light1,
    backgroundColor: colors.light3,
  },
  link: {
    backgroundColor: 'transparent',
    paddingHorizontal: 0,
  },
  textLink: {
    color: colors.dark1,
  },
  colorOutline: {
    color: colors.dark1,
  },
  small: {
    paddingHorizontal: 42,
    borderRadius: 6,
    height: 42,
  },
  large: {
    paddingHorizontal: 24,
    borderRadius: 6,
    height: 72,
  },
  textSmall: {
    fontSize: 14,
  },
  textLarge: {
    fontSize: 22,
  },
  icon: {
    color: colors.white,
    fontSize: 20,
  },
  iconLeft: {
    marginRight: 8,
  },
  iconRight: {
    marginLeft: 8,
  },
  iconSmall: {
    fontSize: 16,
  },
  iconLarge: {
    fontSize: 24,
  },
});
