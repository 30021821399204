import Constants from 'expo-constants';
import { Platform } from 'react-native';
import * as Sentry from '@sentry/react-native';

// function getSentryIntegrations() {
//   if (Platform.OS !== 'web') {
//     return [new Sentry.ReactNativeTracing()];
//   }
//   return [];
// }

export function initSentry() {
  if (Constants.expoConfig?.extra?.sentryDSN) {
    Sentry.init({
      dsn: Constants.expoConfig?.extra?.sentryDSN,
      debug: false,
      release: Constants.expoConfig?.version,
    });

    Sentry?.setTag('company', Constants.expoConfig?.extra?.company);
  }
}
