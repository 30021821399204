import { images } from '_/assets';

export interface LanguageProps {
  key: number;
  lang: string;
  code: string;
  flag: any;
}

export const languages: LanguageProps[] = [
  {
    key: 0,
    lang: 'English',
    code: 'en',
    flag: images.enFlag,
  },
  {
    key: 1,
    lang: 'English',
    code: 'en-GB',
    flag: images.enUkFlag,
  },
  {
    key: 2,
    lang: 'Português',
    code: 'pt-BR',
    flag: images.ptBRFlag,
  },
  {
    key: 3,
    lang: 'Español',
    code: 'es',
    flag: images.esFlag,
  },
  {
    key: 4,
    lang: 'Français',
    code: 'fr',
    flag: images.frFlag,
  },
  {
    key: 5,
    lang: '中国人',
    code: 'zh-Hans',
    flag: images.zhHans,
  },
  {
    key: 6,
    lang: 'தமிழ்',
    code: 'ta',
    flag: images.taFlag,
  },
  {
    key: 7,
    lang: 'Tiếng Việt',
    code: 'vi',
    flag: images.viFlag,
  },
  {
    key: 8,
    lang: 'Indonesia',
    code: 'id',
    flag: images.idFlag,
  },
  {
    key: 9,
    lang: 'Bahasa Malaysia',
    code: 'ms',
    flag: images.msFlag,
  },
  {
    key: 10,
    lang: 'Deutsch',
    code: 'de',
    flag: images.deFlag,
  },
];
