import AsyncStorage from '@react-native-async-storage/async-storage';
import appConfig from '_/config/app';
import { Environment } from '_/interfaces/Environment';
import axios, { AxiosInstance } from 'axios';

import { ConfigEventEmitter, Events } from '../ConfigEventEmitter';

class API {
  protected api: AxiosInstance;

  constructor() {
    this.api = axios.create({});
    this.createApi(appConfig.apiUrl);
    ConfigEventEmitter.subscribe(Events.ENVIRONMENT_CHANGED, this.updateConfig);
  }

  private updateConfig = (env: Environment) => {
    this.createApi(env === 'prod' ? appConfig.apiUrl : appConfig.apiStgUrl);
  };

  private createApi = (baseURL: string) => {
    this.api = axios.create({
      baseURL,
      timeout: appConfig.apiTimeout,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.api.interceptors.request.use(
      async (config) => {
        const userToken = await this.getStorageItem(appConfig.tokenKey);
        if (userToken) {
          config.headers['authorization'] = `Bearer ${userToken}`;
        }

        return config;
      },
      (error) => Promise.reject(error)
    );
  };

  public setToken = (accessToken: string) => {
    this.api.defaults.headers.common['authorization'] = `Bearer ${accessToken}`;
  };

  public removeToken = () => {
    delete this.api.defaults.headers.common['authorization'];
  };

  private getStorageItem = async (key: string) => {
    return await AsyncStorage.getItem(key);
  };
}

export default API;
