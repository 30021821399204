import {
  CustomFieldsModel,
  CustomizationsModel,
  CustomFieldType,
  CustomFieldLocation,
} from '@w3lcome/types';
import { capitalize } from 'lodash';

export function getPrimaryKeyField(customization: CustomizationsModel): CustomFieldsModel {
  return {
    customType: false,
    id: 'primaryKey',
    name: customization.primaryKeyPlaceholder || capitalize(customization.primaryKey),
    placeholder: customization.primaryKeyPlaceholder || capitalize(customization.primaryKey),
    required: true,
    sort: -2,
    type: customization.primaryKey as unknown as CustomFieldType,
    location: CustomFieldLocation.FIELD,
    createdAt: new Date(),
    updatedAt: new Date(),
    customizationId: customization.id,
  };
}

export function getNameField(
  customization: CustomizationsModel,
  placeholder: string
): CustomFieldsModel {
  return {
    customType: false,
    id: 'name',
    name: customization.namePlaceholder || placeholder,
    required: customization.nameRequired,
    sort: -1,
    type: CustomFieldType.NAME,
    placeholder: customization.namePlaceholder || placeholder,
    location: CustomFieldLocation.FIELD,
    createdAt: new Date(),
    updatedAt: new Date(),
    customizationId: customization.id,
  };
}
