import React, { useState } from 'react';
import { CustomFieldValuesModel, Lang } from '@w3lcome/types';
import { Button, Text } from '_/components';
import ChevronDown from '_/components/ChevronDown';
import { colors, defaultShadow, fontSizes } from '_/config/theme';
import { useCustomization } from '_/hooks/CustomizationContext';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Dimensions, ScrollView } from 'react-native';
import { CheckBox } from 'react-native-elements';
import uuid from 'react-native-uuid';

const { height } = Dimensions.get('window');

const HEIGHT_ITEM = 195;

interface QuestionnaireQuestionsProps {
  onPressNext: (questionnaireFields?: CustomFieldValuesModel[] | undefined) => void;
  scrollY: any;
  scrollSize: number;
  scrollViewRef: React.RefObject<ScrollView>;
}

export const QuestionnaireQuestions: React.FC<QuestionnaireQuestionsProps> = ({
  onPressNext,
  scrollY,
  scrollSize,
  scrollViewRef,
}) => {
  const { customization } = useCustomization();
  const { t, i18n } = useTranslation();
  const [answers, setAnswers] = useState<{ [key: string]: string }>({});

  function handleNextPress() {
    const questionnaireFields: CustomFieldValuesModel[] = Object.keys(answers).map((key) => ({
      createdAt: new Date(),
      updatedAt: new Date(),
      customFieldId: key,
      value: answers[key],
      id: uuid.v4().toString(),
    }));

    onPressNext(questionnaireFields);
  }

  const handleOnChange = (value: string, customFieldId: string) => {
    setAnswers({ ...answers, [customFieldId]: value });
  };

  return (
    <View style={{ flex: 1, position: 'relative' }}>
      <Text
        center
        style={styles.title}
        size={fontSizes.xl2}
        fontType="semibold"
        color={colors.dark3}
      >
        {t('questionnaireScreen.AnswerQuestions')}
      </Text>
      <ChevronDown
        scrollY={scrollY}
        isVisible={height < scrollSize - HEIGHT_ITEM}
        extraPadding={32}
        scrollSize={scrollSize}
        scrollViewRef={scrollViewRef}
      >
        <View style={styles.content}>
          <View style={styles.questionnarie}>
            {customization?.questionnaireFields?.map((item) => {
              const itemName = item.placeholder?.[i18n.language] || item.placeholder?.[Lang.EN];

              return (
                <View style={styles.checkbox} key={item.id}>
                  <Text style={{ marginBottom: 8 }} color={colors.primary} size={fontSizes.xl}>
                    {itemName || item.name}
                  </Text>

                  {item.options?.map(({ name, localizedName }) => {
                    const itemOptionName =
                      localizedName?.[i18n.language] || localizedName?.[Lang.EN];

                    return (
                      <CheckBox
                        key={name}
                        title={itemOptionName || name}
                        checkedIcon="circle-slice-8"
                        uncheckedIcon="circle-outline"
                        iconType="material-community"
                        checked={answers[item.id] === name}
                        textStyle={{
                          fontSize: fontSizes.lg2,
                          color: colors.dark1,
                          textTransform: 'capitalize',
                          paddingVertical: 8,
                        }}
                        onPress={() => handleOnChange(name, item.id)}
                        checkedColor={customization.mainColor || colors.primary}
                      />
                    );
                  })}
                </View>
              );
            })}
            <View style={styles.buttonView}>
              <Button
                large
                backgroundColor={customization?.mainColor || colors.primary}
                labelColor={customization?.backgroundColor || colors.white}
                onPress={handleNextPress}
              >
                {t('Next')}
              </Button>
            </View>
          </View>
        </View>
      </ChevronDown>
    </View>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    justifyContent: 'space-between',
    paddingHorizontal: 32,
  },
  buttonView: {
    width: '100%',
    marginTop: 32,
  },
  questionnarie: {
    flex: 1,
    alignItems: 'center',
  },
  title: {
    paddingHorizontal: 32,
    paddingBottom: 18,
    flexWrap: 'wrap',
  },
  checkbox: {
    marginTop: 14,
    width: '90%',
    paddingHorizontal: 32,
    paddingVertical: 24,
    backgroundColor: colors.white,
    borderRadius: 8,
    ...defaultShadow,
  },
});
