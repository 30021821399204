import { IpadIntegrationsModels } from '@w3lcome/types';
import { VisitsModelCheckin } from '_/interfaces/VisitsModelCheckin';

import logger from '../logger';
import { runAccessIntegration } from './access';
import { runSecullumIntegration } from './secullum';
import { runTapsScanIntegration } from './tapsScan';

export async function checkin(
  visit: Partial<VisitsModelCheckin>,
  integration: IpadIntegrationsModels
) {
  if (visit.authorizationStatus !== 'ALLOWED') {
    return;
  }

  run(visit, integration);
}

export function approval(visit: Partial<VisitsModelCheckin>, integration: IpadIntegrationsModels) {
  run(visit, integration);
}

export async function run(visit: Partial<VisitsModelCheckin>, integration: IpadIntegrationsModels) {
  try {
    if (integration.type === 'access') {
      await runAccessIntegration(visit, integration);
    }

    if (integration.type === 'secullum') {
      await runSecullumIntegration(visit, integration);
    }

    if (integration.type === 'tapsScan') {
      await runTapsScanIntegration(visit, integration);
    }
  } catch (error) {
    logger(error);
  }
}
