import { CustomFieldType } from '@w3lcome/types';
import { CustomFieldWithError } from '_/interfaces/CustomFieldWithError';
import { VisitsModelCheckin } from '_/interfaces/VisitsModelCheckin';
import { TFunction } from 'react-i18next';

export default function formatFieldWhenVisitorIsForeigner(
  field: CustomFieldWithError,
  visit: Partial<VisitsModelCheckin>,
  t: TFunction
) {
  if (
    (field?.type === CustomFieldType.CPF || field?.type === CustomFieldType.RG) &&
    visit.isForeigner
  ) {
    field = {
      ...field,
      type: CustomFieldType.DOCUMENT,
      placeholder: `${t('Document')}`,
      name: `${t('Document')}`,
    };

    return field;
  }

  return field;
}
