import { useNavigation } from '@react-navigation/native';
import { HostsVisitsFlatList, QRCodeButton, Text } from '_/components';
import { AnimatableViewFadeInUpBigOrFadeOutDown, DURATION } from '_/components/Animatable';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import Header from '_/components/Header';
import Input from '_/components/Input';
import KeyboardContainer from '_/components/KeyboardContainer';
import Modal from '_/components/Modal';
import ModalQRCodeCamera from '_/components/ModalQRCodeCamera';
import { colors, fontSizes } from '_/config/theme';
import { useAuth } from '_/hooks/AuthContext';
import { useCustomization } from '_/hooks/CustomizationContext';
import { useVisit } from '_/hooks/VisitContext';
import { useVisitsList } from '_/hooks/VisitsListContext';
import { AppRoute, CheckoutVisitorProps } from '_/navigation/types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TextInput, Keyboard } from 'react-native';

const CheckoutVisitSelectScreen: React.FC = () => {
  const [textSearch, setTextSearch] = useState('');
  const inputSearchRef = useRef<TextInput>(null);
  const { customization } = useCustomization();
  const [isQRCodeReaderVisible, setQRCodeReaderVisible] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState<string>();
  const [isFocusedInput, setIsFocusedInput] = useState(false);

  const { visitsList, searchVisit } = useVisitsList();
  const { setVisit, setLoading, loading, getVisitByQRCode } = useVisit();

  const { t } = useTranslation();

  const navigation = useNavigation<CheckoutVisitorProps>();

  const { ipad } = useAuth();

  useEffect(() => {
    setTimeout(() => {
      if (inputSearchRef && inputSearchRef.current) {
        return inputSearchRef.current?.focus();
      }
    }, DURATION);
  }, []);

  async function handleScannedQRCode(data: string) {
    setLoading(true);
    setQRCodeReaderVisible(false);
    try {
      const visit = await getVisitByQRCode(data);
      if (visit) {
        if (visit.checkinAt) {
          setVisit(visit);
          if (customization?.feedbackEnabled) {
            navigation.navigate(AppRoute.CHECKOUT_FEEDBACK);
          } else {
            navigation.navigate(AppRoute.CHECKOUT_MESSAGE);
          }
        } else {
          setErrorModalMessage(t('CheckedError'));
        }
      } else {
        setErrorModalMessage(t('InvalidQRCode'));
      }
    } catch (error) {
      setErrorModalMessage(t('InvalidQRCode'));
    }
    setLoading(false);
  }

  function onChange(text: string) {
    setTextSearch(text);
    searchVisit(text);
  }

  return (
    <>
      <KeyboardContainer
        keyboardOpen={isFocusedInput}
        onChange={onChange}
        inputName="search"
        keyboardType="default"
      >
        <View style={styles.container}>
          <ContainerWidthLimit>
            <Header />
            <View style={styles.content}>
              <View style={styles.innerContent}>
                <AnimatableViewFadeInUpBigOrFadeOutDown duration={DURATION} upInOrOutDown>
                  <Text center color={colors.dark1} size={fontSizes.xl2}>
                    {t('checkoutScreen.TypingName')}
                  </Text>
                </AnimatableViewFadeInUpBigOrFadeOutDown>

                <AnimatableViewFadeInUpBigOrFadeOutDown
                  duration={DURATION}
                  upInOrOutDown
                  style={styles.searchContent}
                >
                  <Input
                    style={{ color: colors.dark3 }}
                    icon="search"
                    ref={inputSearchRef}
                    label={t('Name')}
                    value={textSearch}
                    onChangeText={onChange}
                    onFocus={() => setIsFocusedInput(true)}
                    onBlur={() => setIsFocusedInput(false)}
                  />
                </AnimatableViewFadeInUpBigOrFadeOutDown>

                {textSearch.length >= 3 ? (
                  <HostsVisitsFlatList
                    data={visitsList}
                    onPressFunction={(visit) => {
                      setVisit(visit);
                      if (
                        customization?.feedbackEnabled &&
                        customization?.feedbacks &&
                        customization?.feedbacks?.length > 0
                      ) {
                        navigation.navigate(AppRoute.CHECKOUT_FEEDBACK);
                      } else {
                        navigation.navigate(AppRoute.CHECKOUT_MESSAGE);
                      }
                    }}
                  />
                ) : (
                  <>
                    {customization?.qrCodeCheckinEnabled && (
                      <AnimatableViewFadeInUpBigOrFadeOutDown duration={DURATION} upInOrOutDown>
                        <QRCodeButton
                          onPress={() => {
                            setQRCodeReaderVisible(true);
                            Keyboard.dismiss();
                          }}
                          loading={loading}
                        />
                      </AnimatableViewFadeInUpBigOrFadeOutDown>
                    )}

                    <AnimatableViewFadeInUpBigOrFadeOutDown
                      duration={DURATION}
                      upInOrOutDown
                      style={styles.textContent}
                    >
                      <Text center size={fontSizes.xl2} color={colors.dark2} fontType="medium">
                        {t('checkoutScreen.SearchVisitName')}
                      </Text>
                    </AnimatableViewFadeInUpBigOrFadeOutDown>
                  </>
                )}
              </View>
            </View>
          </ContainerWidthLimit>
        </View>
      </KeyboardContainer>
      <ModalQRCodeCamera
        cameraType={ipad?.settingsQRCodeCameraPosition.toLowerCase() as any}
        isScannerEnabled={!loading && !errorModalMessage && isQRCodeReaderVisible}
        isVisible={isQRCodeReaderVisible}
        onClose={() => setQRCodeReaderVisible(false)}
        onScan={handleScannedQRCode}
      />
      <Modal
        icon="alert-circle"
        iconColor={colors.danger}
        message={errorModalMessage}
        isVisible={!!errorModalMessage}
        onClose={() => setErrorModalMessage(undefined)}
      />
    </>
  );
};

export default CheckoutVisitSelectScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    alignItems: 'center',
  },
  content: {
    flex: 1,
    alignItems: 'center',
  },
  innerContent: {
    flex: 1,
    width: '80%',
  },
  searchContent: {
    marginTop: 32,
  },
  textContent: {
    marginTop: 32,
  },
});
