import { AntDesign } from '@expo/vector-icons';
import { colors } from '_/config/theme';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Image } from 'react-native';

import Button, { ButtonProps } from '../Button';
import Text from '../Text';

const QRCodeButton: React.FC<Partial<ButtonProps>> = ({ children, loading, onPress, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Button
      {...rest}
      outline
      onPress={() => onPress?.()}
      disabled={loading}
      buttonStyles={styles.qrcodeButton}
      leftIcon={<AntDesign name="qrcode" size={32} style={styles.qrcodeIcon} />}
    >
      <Text fontType="medium" size={18} color={colors.dark3}>
        {t('qrcodeButton')}
      </Text>
    </Button>
  );
};

export default QRCodeButton;

const styles = StyleSheet.create({
  qrcodeButton: {
    backgroundColor: colors.secondary,
    borderRadius: 8,
    marginBottom: 16,
  },
  qrcodeIcon: {
    color: colors.dark3,
    marginRight: 8,
  },
});
