import { useNavigation } from '@react-navigation/native';
import { images } from '_/assets';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import Header from '_/components/Header';
import { colors } from '_/config/theme';
import { AppRoute, LGPDCheckinNDAProps } from '_/navigation/types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, ScrollView, Platform } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import Signature, { SignatureViewRef } from 'react-native-signature-canvas';
import SignatureCanvas from 'react-signature-canvas';

import { useCustomizationLgpd } from '../../hooks/CustomizationLgpdContext';
import { useVisitLgpdContext } from '../../hooks/VisitLgpdContext';
import ContinueButton from './ContinueButton';
import Paper from './Paper';

const contentPaddingHorizontal = 32;

const LgpdCheckinNDAScreen: React.FC = () => {
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const signatureRef = useRef<SignatureViewRef>({} as SignatureViewRef);
  const signatureWeb = useRef<SignatureCanvas>(null);
  const [isClearVisible, setClearVisible] = useState(false);
  const [signatureImage, setSignatureImage] = useState(null as any);
  const { customizationLgpd } = useCustomizationLgpd();
  const { navigate } = useNavigation<LGPDCheckinNDAProps>();

  const { setVisit } = useVisitLgpdContext();

  const { t } = useTranslation();

  const handleOK = (signature: string) => {
    setSignatureImage(signature);
  };

  const handleClear = () => {
    setClearVisible(false);
    setSignatureImage(null);
    signatureRef.current?.clearSignature();
  };

  const handleClearWeb = () => {
    setClearVisible(false);
    setSignatureImage(null);
    signatureWeb.current?.clear();
  };

  async function handleSaveNda() {
    if (!signatureImage) {
      showMessage({
        message: t('errorSignature'),
        backgroundColor: colors.danger,
      });
      return;
    }

    setVisit({ signatureBase64: signatureImage });
    navigate(AppRoute.LGPD_TOTEM_CHECKIN_MESSAGE);
  }

  function handleSaveSigntureToImage() {
    const signatue = signatureWeb.current?.toDataURL();
    setSignatureImage(signatue);
  }

  const handleEnd = () => {
    setScrollEnabled(true);
    signatureRef.current?.readSignature();
  };

  const handleStart = () => {
    setScrollEnabled(false);
    setClearVisible(true);
  };

  return (
    <ScrollView
      scrollEnabled={scrollEnabled}
      style={[{ flex: 1, backgroundColor: colors.white }, StyleSheet.absoluteFill]}
    >
      <View style={styles.center}>
        <ContainerWidthLimit>
          <View style={styles.container}>
            <Header
              source={
                customizationLgpd?.CompanyLogo
                  ? { uri: customizationLgpd?.CompanyLogo }
                  : images.poweredByLogo
              }
            />
            <View style={styles.content}>
              <Paper
                signatureImage={signatureImage}
                onClearSignature={Platform.OS === 'web' ? handleClearWeb : handleClear}
                isClearVisible={isClearVisible}
                paperPaddingHorizontal={contentPaddingHorizontal}
              >
                {Platform.OS === 'web' ? (
                  <SignatureCanvas
                    ref={signatureWeb}
                    onEnd={handleSaveSigntureToImage}
                    penColor={colors.dark1}
                    backgroundColor={colors.white}
                    canvasProps={{ style: { width: '100%', height: 300 } }}
                    onBegin={() => setClearVisible(true)}
                  />
                ) : (
                  <Signature
                    androidHardwareAccelerationDisabled
                    style={styles.signature}
                    ref={signatureRef}
                    onOK={handleOK}
                    onEnd={handleEnd}
                    backgroundColor={colors.white}
                    penColor={colors.dark1}
                    scrollable={false}
                    webStyle={webSignatureStyle}
                    onBegin={handleStart}
                    webviewContainerStyle={{ opacity: 0.99 }}
                  />
                )}
              </Paper>
            </View>
            <ContinueButton
              onButtonPress={handleSaveNda}
              paddingHorizontal={contentPaddingHorizontal}
            />
          </View>
        </ContainerWidthLimit>
      </View>
    </ScrollView>
  );
};

export default LgpdCheckinNDAScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    paddingBottom: 112,
  },
  content: {
    flex: 1,
    paddingHorizontal: contentPaddingHorizontal,
    backgroundColor: colors.white,
  },
  center: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  signature: {
    height: 300,
    width: '100%',
    borderWidth: 1,
    borderColor: colors.light3,
    borderRadius: 12,
  },
});

const webSignatureStyle = `
.m-signature-pad {box-shadow: none; border: 0; margin: 0px; }
.m-signature-pad--body {border: 0; margin: 0px; background-color: #ffffff;}
.m-signature-pad--footer {display: none; margin: 0px;}
body,html {
  width: 100%;
  height: 300px;
}
`;
