import { useIsFocused } from '@react-navigation/native';
import { colors } from '_/config/theme';
import { useAuth } from '_/hooks/AuthContext';
import React, { useEffect, useRef } from 'react';
import { StyleSheet, View } from 'react-native';
import VirtualKeyboard, { KeyboardReactInterface } from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import '../../../web/styles.css';

interface KeyboardContainerProps {
  keyboardOpen: boolean;
  onSubmit?(): void;
  onChange: (value: string) => void;
  inputName: string;
  initialValue?: string;
  keyboardType: 'default' | 'numeric';
  children: React.ReactNode;
}

const KeyboardContainer: React.FC<KeyboardContainerProps> = ({
  children,
  keyboardOpen,
  onSubmit,
  onChange,
  inputName,
  initialValue,
  keyboardType,
}) => {
  const keyboardRef = useRef<KeyboardReactInterface | null>(null);
  const isFocused = useIsFocused();
  const { ipad } = useAuth();

  useEffect(() => {
    keyboardRef.current?.setInput(initialValue || '', inputName);
  }, [inputName, isFocused]);

  if (!ipad?.ipadKeyboardEnabled) {
    return <>{children}</>;
  }

  return (
    <View style={style.container}>
      {children}

      <View
        style={{
          display: keyboardOpen ? 'flex' : 'none',
          position: 'absolute',
          width: '100%',
          bottom: 0,
        }}
      >
        <VirtualKeyboard
          keyboardRef={(r) => (keyboardRef.current = r)}
          onChangeAll={(input) => {
            onChange?.(input[inputName]);
          }}
          inputName={inputName}
          onKeyPress={(buttonType: string) => {
            if (buttonType === '{enter}') {
              onSubmit?.();
            }

            if (buttonType === '{lock}' || buttonType === '{shift}') {
              const shiftToggle =
                keyboardRef.current?.options.layoutName === 'default' ? 'shift' : 'default';

              keyboardRef.current?.setOptions({ layoutName: shiftToggle });
            }
          }}
          theme="hg-theme-default"
          layout={{
            default:
              keyboardType === 'numeric'
                ? ['1 2 3', '4 5 6', '7 8 9', '{bksp} 0 {enter}']
                : [
                    '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
                    '{tab} q w e r t y u i o p [ ] \\',
                    "{lock} a s d f g h j k l ; ' {enter}",
                    '{shift} z x c v b n m , . / {shift}',
                    '.com @ {space}',
                  ],
            shift: [
              '~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}',
              '{tab} Q W E R T Y U I O P { } |',
              '{lock} A S D F G H J K L : " {enter}',
              '{shift} Z X C V B N M &lt; &gt; ? {shift}',
              '.com @ {space}',
            ],
          }}
          layoutCandidates={{
            a: 'á à ã â Á À Ã Â',
            e: 'é ê É Ê',
            i: 'í Í',
            o: 'ó õ ô Ó Õ Ô',
            u: 'ú Ú',
            c: 'ç Ç',
          }}
          display={{
            '{bksp}': '⌫',
            '{enter}': '↩',
            '{shift}': '⇧',
            '{tab}': 'tab',
            '{lock}': '123',
            '{space}': ' ',
          }}
          preventMouseDownDefault
        />
      </View>
    </View>
  );
};

export default KeyboardContainer;

const style = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
});
