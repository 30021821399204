import { create } from 'zustand';

interface CountdownState {
  counter: number;
  startCountdown: (durationInMinutes: number) => void;
  stopCountdown: () => void;
}

const useCountdownStore = create<CountdownState>((set) => ({
  counter: 0,
  startCountdown: (durationInMinutes) => {
    set({ counter: durationInMinutes * 60 });
    const intervalId = setInterval(() => {
      set((state) => {
        if (state.counter === 0) {
          clearInterval(intervalId);
          return { counter: 0 };
        }
        return { counter: state.counter - 1 };
      });
    }, 1000);
  },
  stopCountdown: () => {
    set({ counter: 0 });
  },
}));

export default useCountdownStore;
