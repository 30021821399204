import { useNetInfo } from '@react-native-community/netinfo';
import { useNavigation } from '@react-navigation/native';
import { Button, Text, MultiTap, LanguageButton } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import LanguagesChoose from '_/components/LanguagesChoose';
import { PoweredBy } from '_/components/PoweredBy';
import { colors, fontSizes } from '_/config/theme';
import { checkVisitValidity } from '_/helpers/checkVisitValidity';
import { getColorByContrast } from '_/helpers/getColorContrast';
import { useAuth } from '_/hooks/AuthContext';
import { useCustomization } from '_/hooks/CustomizationContext';
import { useVisit } from '_/hooks/VisitContext';
import useSafeState from '_/hooks/useSafeState';
import { AppRoute, HomeProps } from '_/navigation/types';
import Constants from 'expo-constants';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Image, useWindowDimensions, DimensionValue } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import QRCode from 'react-native-qrcode-svg';

import CheckoutButton from './CheckoutButton';
import DeliveryButton from './DeliveryButton';
import LogoutModal from './LogoutModal';
import PrinterButton from './PrinterButton';
import SlideshowImages from './SlideshowImages';
import { useChildrenCompanies } from '_/hooks/ChildrenCompaniesContex';
import { CompanyType, Lang } from '@w3lcome/types';
import { useIsDesktopConnected } from '_/hooks/useIsDesktopConnected';
import QrCodeCheckinAtHome from '_/components/QrCodeCheckinAtHome';

const HomeScreen: React.FC = () => {
  const { width, height } = useWindowDimensions();
  const {
    resetData: resetVisitData,
    setLoading,
    setVisitParser,
    loading,
    getVisitByQRCode,
  } = useVisit();

  const { reloadIpad, syncDeviceVersion } = useAuth();
  const { customization, loadData, company } = useCustomization();
  const { removeRemoteData } = useChildrenCompanies();

  const { t, i18n } = useTranslation();
  const netInfo = useNetInfo();
  const navigation = useNavigation<HomeProps>();
  const ipadTouchlessShowQRCodeEnable =
    customization?.ipadTouchlessShowQRCodeEnable &&
    !!company?.touchlessId &&
    customization?.ipadTouchlessQRCodeEnable;

  const [openLanguageScreen, setOpenLanguageScreen] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [errorInstantCheckin, setErrorInstantCheckin] = useSafeState(false);

  const textColor = useMemo(() => {
    if (customization?.ipadSlideshowImages?.length) {
      return colors.white;
    }

    return getColorByContrast(
      customization?.backgroundColor || colors.white,
      customization?.textColor
    );
  }, [customization]);

  const buttonCheckinTextColor = useMemo(() => {
    if (customization?.ipadSlideshowImages?.length || !customization?.mainColor) {
      return colors.white;
    }

    return getColorByContrast(customization?.mainColor || colors.primary, customization?.textColor);
  }, [customization]);

  function reloadData() {
    loadData();
    reloadIpad();
    syncDeviceVersion(true);
    showMessage({
      message: t('homeScreen.updatingCustomization'),
      backgroundColor: colors.dark3,
    });
  }

  const desktopConnected = useIsDesktopConnected();

  function handleStartCheckin() {
    resetVisitData();
    removeRemoteData();
    navigation.navigate(AppRoute.MAIN_STACK_CHECKIN);
  }

  function handleStartCheckout() {
    navigation.navigate(AppRoute.MAIN_STACK_CHECKOUT_VISITOR);
  }

  function handleStartDelivery() {
    navigation.navigate(AppRoute.MAIN_STACK_DELIVERY_HOST);
  }

  const ipadLogoTopHeight = useMemo(() => {
    if (customization?.ipadLogoTopHeight && customization?.ipadLogoTopHeight < 100) {
      return `${customization.ipadLogoTopHeight}%` as DimensionValue;
    }
    return '10%' as DimensionValue;
  }, [customization?.ipadLogoTopHeight]);

  const ipadLogoHeight = useMemo(() => {
    if (customization?.ipadLogoHeight && customization?.ipadLogoHeight < 100) {
      return `${customization.ipadLogoHeight}%` as DimensionValue;
    }
    return '30%' as DimensionValue;
  }, [customization?.ipadLogoHeight]);

  const ipadMessage = useMemo(() => {
    if (!customization?.ipadMessage) return '';

    if (typeof customization?.ipadMessage === 'string') return customization?.ipadMessage;

    const langValue =
      customization.ipadMessage[i18n.language as Lang] ||
      customization.ipadMessage[Lang.EN] ||
      customization.ipadMessage[Object.keys(customization.ipadMessage)[0] as Lang];

    return (langValue || '').trim();
  }, [i18n.language, customization?.ipadMessage]);

  async function handleScannedQRCode(data: string) {
    setLoading(true);
    try {
      const visit = await getVisitByQRCode(data) 

      if (visit) {
        const isValidVisit = checkVisitValidity(visit, customization);
        if (isValidVisit) {
          setVisitParser(visit);

          if (!!customization?.ipadInstantCheckinEnabled) {
            navigation.navigate(AppRoute.MAIN_STACK_CHECKIN, { screen: AppRoute.CHECKIN_MESSAGE });
            return;
          }

          navigation.navigate(AppRoute.MAIN_STACK_CHECKIN, {
            screen: AppRoute.CHECKIN,
            params: { meetingModalVisible: true },
          });
        } else {
          showMessage({
            duration: 6000,
            message: t('QRCodeAlreadyUsed'),
            backgroundColor: colors.danger,
          });
          setErrorInstantCheckin(true);
        }
      } else {
        showMessage({
          duration: 6000,
          message: t('InvalidQRCode'),
          backgroundColor: colors.danger,
        });
        setErrorInstantCheckin(true);
      }
    } catch (error) {
      showMessage({
        duration: 6000,
        message: t('InvalidQRCode'),
        backgroundColor: colors.danger,
      });
      setErrorInstantCheckin(true);
    }
    setTimeout(() => {
      setLoading(false);
      setErrorInstantCheckin(false);
    }, 500);
  }

  return (
    <MultiTap style={{ flex: 1 }} onPress={reloadData} numTaps={3}>
      <View
        style={[
          styles.container,
          { backgroundColor: customization?.backgroundColor || colors.white },
        ]}
      >
        {!openLanguageScreen && <PrinterButton />}
        <SlideshowImages />
        {showLogoutModal && <LogoutModal setShow={setShowLogoutModal} />}
        <View style={styles.contentAbsolute}>
          <ContainerWidthLimit>
            <View style={styles.header}>
              <Text uppercase color={textColor} size={fontSizes.lg} style={{ opacity: 0.5 }}>
                {customization?.displayName || ''}
              </Text>

              {Constants.expoConfig?.extra?.poweredByAvailable === 'true' && (
                <View style={styles.poweredBy}>
                  <MultiTap numTaps={5} onPress={() => setShowLogoutModal(true)}>
                    <PoweredBy />
                  </MultiTap>
                </View>
              )}
            </View>

            <View style={styles.content}>
              {!!customization?.pictureUrl && (
                <View
                  style={[
                    styles.pictureContent,
                    { marginTop: ipadLogoTopHeight, height: ipadLogoHeight },
                  ]}
                >
                  <Image
                    style={{ height: '100%', width: '100%' }}
                    source={{ uri: customization.pictureUrl }}
                    resizeMode="contain"
                  />
                </View>
              )}

              {!!ipadMessage && (
                <View style={styles.messageContent}>
                  <Text
                    style={{
                      textAlign:
                        (customization?.ipadMessageAlign?.toLowerCase() as any) || 'center',
                      color: textColor,
                      paddingBottom: 24,
                    }}
                    size={customization?.ipadMessageSize || fontSizes.md}
                  >
                    {ipadMessage}
                  </Text>
                </View>
              )}

              {!!customization?.qrCodeCheckinAtHomeEnabled && (
                <QrCodeCheckinAtHome
                  isScannerEnabled={!loading && !errorInstantCheckin}
                  onScan={handleScannedQRCode}
                />
              )}

              <View style={styles.viewBottom}>
                <View style={styles.viewAbsoluteBottom}>
                  <View style={styles.buttonContent}>
                    {(!netInfo.isConnected || !desktopConnected) && (
                      <View style={styles.offline}>
                        <Text size={fontSizes.md2} color={colors.danger}>
                          {t('homeScreen.Connection')}
                        </Text>
                      </View>
                    )}

                    <Button
                      large
                      buttonStyles={[
                        {
                          width:
                            height > width && !!ipadTouchlessShowQRCodeEnable
                              ? width * 0.7
                              : width / 2 + 24,
                        },
                        !!ipadTouchlessShowQRCodeEnable && styles.buttonShowQRCode,
                        !!customization?.ipadSlideshowImages?.length &&
                          styles.checkinButtonSlideshow,
                      ]}
                      onPress={handleStartCheckin}
                      backgroundColor={customization?.mainColor || colors.primary}
                    >
                      {!!ipadTouchlessShowQRCodeEnable && (
                        <QRCode
                          size={80}
                          backgroundColor="transparent"
                          value={`${Constants.expoConfig?.extra?.preCheckinUrl}/touchless/${company?.touchlessId}`}
                          color={buttonCheckinTextColor}
                        />
                      )}
                      <Text
                        size={fontSizes.xl}
                        style={!!ipadTouchlessShowQRCodeEnable && styles.textShowQRCode}
                        color={buttonCheckinTextColor}
                        fontType="bold"
                      >
                        {ipadTouchlessShowQRCodeEnable
                          ? t('homeScreen.touchlessCheckin')
                          : t('homeScreen.Check-in')}
                      </Text>
                    </Button>
                  </View>

                  <View style={styles.bottomContainer}>
                    {customization?.deliveriesEnabled && company?.type !== CompanyType.PARENT && (
                      <DeliveryButton textColor={textColor} onPress={handleStartDelivery} />
                    )}
                    {customization?.signOutEnabled && customization?.showCheckoutButtonOnKiosk && (
                      <CheckoutButton textColor={textColor} onPress={handleStartCheckout} />
                    )}
                    <LanguageButton
                      textColor={textColor}
                      onPress={() => setOpenLanguageScreen(true)}
                      containerStyle={{ flex: 1 }}
                    />
                  </View>
                </View>
              </View>
            </View>
          </ContainerWidthLimit>
        </View>
        {openLanguageScreen && <LanguagesChoose close={setOpenLanguageScreen} />}
      </View>
    </MultiTap>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  header: {
    paddingVertical: 16,
    alignItems: 'center',
    position: 'relative',
  },
  poweredBy: {
    position: 'absolute',
    right: 32,
    top: 32,
  },
  contentAbsolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
  },
  content: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  bottomContainer: {
    justifyContent: 'space-around',
    flexDirection: 'row',
    width: '100%',
    paddingVertical: 18,
  },
  pictureContent: {
    width: '100%',
    paddingHorizontal: 48,
  },
  messageContent: {
    width: '100%',
    paddingHorizontal: 24,
    flex: 1,
    justifyContent: 'center',
  },
  buttonContent: {
    width: '100%',
    alignItems: 'center',
    paddingVertical: 12,
    position: 'relative',
    marginBottom: 24,
  },
  checkinButtonSlideshow: {
    backgroundColor: '#ffffff22',
    borderColor: colors.white,
    borderWidth: 2,
  },
  offline: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
  },
  viewBottom: {
    flex: 1,
    width: '100%',
  },
  buttonShowQRCode: {
    height: 118,
    paddingLeft: 19,
    paddingRight: 0,
  },
  textShowQRCode: {
    flex: 1,
    textAlign: 'center',
  },
  viewAbsoluteBottom: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
  },
});
