import { CheckinInterface } from '_/interfaces/CheckinInterface';

import db from './SqliteDatabase';

const init = () => {
  db?.transaction((tx) => {
    tx.executeSql('CREATE TABLE IF NOT EXISTS visits_table (id TEXT PRIMARY KEY, visit TEXT);');
  });
};

const create = (obj: CheckinInterface) => {
  return new Promise((resolve, reject) => {
    db?.transaction((tx) => {
      tx.executeSql(
        'INSERT INTO visits_table (id, visit) VALUES (?, ?);',
        [obj.visit.id as string, JSON.stringify(obj)],
        (_, { rowsAffected, insertId }) => {
          if (rowsAffected > 0) resolve(insertId);
          else reject(new Error(`Error inserting visit with id: ${obj.visit.id}`));
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

const deleteOne = (id: string) => {
  return new Promise((resolve, reject) => {
    db?.transaction((tx) => {
      tx.executeSql(
        'DELETE FROM visits_table WHERE id = ?',
        [id],
        (_, { rowsAffected }) => {
          if (rowsAffected > 0) resolve(true);
          else reject(new Error('Error removing visit'));
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

const all = (): Promise<CheckinInterface[]> => {
  return new Promise((resolve, reject) => {
    db?.transaction((tx) => {
      tx.executeSql(
        'SELECT * FROM visits_table;',
        [],
        (_, { rows }) => {
          const visits: CheckinInterface[] = [];
          for (let i = 0; i < rows.length; ++i) {
            const element = rows.item(i);
            visits.push(JSON.parse(element.visit));
          }
          resolve(visits);
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

const deleteTable = () => {
  return new Promise((resolve, reject) => {
    db?.transaction((tx) => {
      tx.executeSql(
        'DROP TABLE visits_table;',
        [],
        (_, { rowsAffected }) => {
          resolve(rowsAffected);
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

export default {
  create,
  all,
  deleteTable,
  deleteOne,
  init,
};
