import { IpadLang } from '@w3lcome/types';
import appConfig from '_/config/app';
import { CountryCode } from 'libphonenumber-js';

export default function getCountryByLang(lang: IpadLang): CountryCode {
  switch (lang) {
    case IpadLang.PTBR:
      return 'BR';
    case IpadLang.DE:
      return 'DE';
    case IpadLang.EN:
      return 'US';
    case IpadLang.FR:
      return 'FR';
    case IpadLang.ZHHANS:
      return 'CN';
    default:
      return appConfig.defaultCountry as CountryCode;
  }
}
