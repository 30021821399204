import { Button, Text } from '_/components';
import { colors, fontSizes } from '_/config/theme';
import { useCustomization } from '_/hooks/CustomizationContext';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Animated, Dimensions, Platform } from 'react-native';

const { height } = Dimensions.get('window');

interface ContinueButtonProps {
  onButtonPress: () => void;
  paddingHorizontal: number;
}

const ContinueButton: React.FC<ContinueButtonProps> = ({ paddingHorizontal, onButtonPress }) => {
  const { customization } = useCustomization();
  const { t } = useTranslation();

  const buttonAnimation = useRef(new Animated.Value(height / 2));

  const showButton = () => {
    Animated.sequence([
      Animated.timing(buttonAnimation.current, {
        toValue: 0,
        duration: 1000,
        useNativeDriver: Platform.OS !== 'web',
      }),
    ]).start();
  };

  useEffect(() => {
    showButton();
  }, []);

  return (
    <Animated.View
      style={[
        styles.container,
        {
          paddingHorizontal,
          transform: [{ translateY: buttonAnimation.current }],
        },
      ]}
    >
      <View style={{ width: '100%' }}>
        <Button
          large
          buttonStyles={{ backgroundColor: customization?.mainColor || colors.primary }}
          onPress={onButtonPress}
        >
          <Text color={colors.white} size={fontSizes.xl2} fontType="bold">
            {t('Next')}
          </Text>
        </Button>
      </View>
    </Animated.View>
  );
};

export default ContinueButton;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 12,
    left: 0,
    right: 0,
    width: '100%',
    alignItems: 'center',
    backgroundColor: colors.white,
    height: 100,
    justifyContent: 'center',
  },
});
