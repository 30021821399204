import AsyncStorage from '@react-native-async-storage/async-storage';
import appConfig from '_/config/app';
import { Environment } from '_/interfaces/Environment';
import { ConfigEventEmitter, Events } from '_/services/ConfigEventEmitter';
import React, { createContext, useContext, useEffect, useState } from 'react';

export interface EnvironmentContextData {
  environment: Environment;
  switchEnvironment: () => Promise<void>;
}

const EnvironmentContext = createContext<EnvironmentContextData>({} as EnvironmentContextData);

interface EnvironmentProviderProps {
  children: React.ReactNode;
}

export const EnvironmentProvider: React.FC<EnvironmentProviderProps> = ({ children }) => {
  const [environment, setEnvironment] = useState<Environment>('prod');

  useEffect(() => {
    AsyncStorage.getItem(appConfig.environmentKey).then((env) => {
      if (env) {
        setEnvironment(env as Environment);
        ConfigEventEmitter.emit(Events.ENVIRONMENT_CHANGED, env as Environment);
      }
    });
  }, []);

  const switchEnvironment = async () => {
    const newEnvironment: Environment = environment === 'prod' ? 'stg' : 'prod';

    await AsyncStorage.setItem(appConfig.environmentKey, newEnvironment);
    setEnvironment(newEnvironment);
    ConfigEventEmitter.emit(Events.ENVIRONMENT_CHANGED, newEnvironment);
  };

  return (
    <EnvironmentContext.Provider
      value={{
        environment,
        switchEnvironment,
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};

export function useEnvironment(): EnvironmentContextData {
  const context = useContext(EnvironmentContext);

  if (!context) {
    throw new Error('useEnvironment must be used within an EnvironmentProvider');
  }

  return context;
}
