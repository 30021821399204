import { useNetInfo } from '@react-native-community/netinfo';
import { DeliveriesModel, HostsModel } from '@w3lcome/types';
import { colors } from '_/config/theme';
import { getBase64String } from '_/helpers/getBase64String';
import { deliveriesApi, hostApi, ipadLogsApi } from '_/services/api';
import logger from '_/services/logger';
import DeliveryDB from '_/services/sqlite/DeliveryDB';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showMessage } from 'react-native-flash-message';
import uuid from 'react-native-uuid';

import { useCustomization } from './CustomizationContext';

interface DeliveryContextData {
  delivery: Partial<DeliveriesModel>;
  setDelivery: React.Dispatch<React.SetStateAction<Partial<DeliveriesModel>>>;
  resetDelivery(): void;
  insertDelivery(delivery: Partial<DeliveriesModel>): Promise<void>;
  insertDeliveryResponsibleHost(): void;
  getDeliveryNotificationHosts(): Promise<HostsModel[] | undefined>;
}

const DeliveryContext = createContext<DeliveryContextData>({} as DeliveryContextData);

type DeliveryType = {
  children: React.ReactNode;
};

export const DeliveryProvider: React.FC<DeliveryType> = ({ children }) => {
  const [delivery, setDelivery] = useState<Partial<DeliveriesModel>>({});
  const { customization, company } = useCustomization();
  const { t } = useTranslation();
  const netInfo = useNetInfo();

  const resetDelivery = () => {
    setDelivery({ companyId: customization?.companyId });
  };

  const getDeliveryNotificationHosts = async () => {
    try {
      const { data } = await hostApi.getList({
        deliveryNotification: true,
        $select: ['name', 'id'],
        $limit: 1,
      });

      return data;
    } catch (error) {
      logger(error);
    }
  };

  const insertDeliveryResponsibleHost = async () => {
    const { data: hosts } = await hostApi.getList({ deliveryNotification: true });

    insertDelivery({
      companyId: customization?.companyId,
      hosts,
    });
  };

  const insertDelivery = async (delivery: Partial<DeliveriesModel>) => {
    const deliveryData: Partial<DeliveriesModel> = {
      ...delivery,
      pictureUrl: null,
      id: uuid.v4().toString(),
    };

    const value = {
      ...deliveryData,
      deliveryPicture: getBase64String(delivery.pictureUrl ?? ''),
    };

    try {
      await deliveriesApi.insert(value);
    } catch (error: any) {
      if (error?.response?.status >= 500) {
        showMessage({
          message: t('error'),
          description: t('serverErrorMessage'),
          backgroundColor: colors.danger,
        });
        ipadLogsApi.insert({
          type: 'Delivery Error',
          message: error?.response?.data?.message || '',
        });
        return;
      }
      DeliveryDB.create(deliveryData);
    }
  };

  function saveDeliveriesInSQlite() {
    DeliveryDB.all().then((data) => {
      if (data.length === 0) {
        return;
      }
      data.map((delivery, index) => {
        setTimeout(async () => {
          try {
            await deliveriesApi.insert(delivery);
            DeliveryDB.deleteOne(delivery.id!);
          } catch (error) {
            logger(error);
          }
        }, 500 * index);
      });
    });
  }

  useEffect(() => {
    if (!netInfo.isConnected) {
      return;
    }
    if (company) {
      saveDeliveriesInSQlite();
    }
  }, [netInfo.isConnected, company]);

  return (
    <DeliveryContext.Provider
      value={{
        delivery,
        setDelivery,
        resetDelivery,
        insertDelivery,
        insertDeliveryResponsibleHost,
        getDeliveryNotificationHosts,
      }}
    >
      {children}
    </DeliveryContext.Provider>
  );
};

export function useDelivery(): DeliveryContextData {
  const context = useContext(DeliveryContext);

  if (!context) {
    throw new Error('useDelivery must be used within an DeliveryProvider');
  }

  return context;
}
