import { colors, fontSizes } from '_/config/theme';
import { getDateLocale } from '_/helpers/getDateLocale';
import { useAuth } from '_/hooks/AuthContext';
import { useVisit } from '_/hooks/VisitContext';
import { useNextCheckinScreen } from '_/hooks/useNextCheckinScreen';
import { AppRoute } from '_/navigation/types';
import { format } from 'date-fns';
import Constants from 'expo-constants';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, TouchableOpacity } from 'react-native';

import ModalView from '../Modal';
import Text from '../Text';

interface ModalMeetingProps {
  hideModalMeeting(isMeetingConfirmed?: boolean): void;
  showModalPrinter(): void;
  isModalVisible: boolean;
}

const ModalMeeting: React.FC<ModalMeetingProps> = ({
  hideModalMeeting,
  showModalPrinter,
  isModalVisible,
}) => {
  const { t } = useTranslation();
  const { visit } = useVisit();
  const { ipad } = useAuth();

  const { goToNextScreen } = useNextCheckinScreen(AppRoute.CHECKIN);

  function handleConfirm() {
    hideModalMeeting();

    if (ipad?.printerId) {
      if (Constants.expoConfig?.extra?.checkCardNumberToPrintBadge === 'true') {
        if (visit.cardNumber) {
          showModalPrinter();
          return;
        }
        goToNextScreen();
        return;
      }

      if (Constants.expoConfig?.extra?.askVisitToPrintBadgeAvailable === 'true') {
        showModalPrinter();
        return;
      }
    }

    goToNextScreen();
  }

  const formattedDate = useMemo(() => {
    if (visit.meeting?.start) {
      const date = new Date(visit.meeting.start);

      const locale = getDateLocale(ipad?.currentLanguage);
      return format(date, 'Pp', { locale });
    }
    return '';
  }, [visit.meeting?.start, ipad?.currentLanguage]);

  return (
    <ModalView isVisible={isModalVisible}>
      <Text size={fontSizes.xl} center fontType="bold">
        {t('meetingView.meetingDetails')}
      </Text>
      <View style={styles.content}>
        <View style={styles.col}>
          <Text fontType="semibold" style={styles.textStrong}>
            {t('Name')}:
          </Text>
          <Text style={styles.text}>{visit.name}</Text>
        </View>
        <View style={styles.col}>
          <Text fontType="semibold" style={styles.textStrong}>
            {t('subject')}:
          </Text>
          <Text style={styles.text}>{visit.meeting?.title}</Text>
        </View>
        <View style={styles.col}>
          <Text fontType="semibold" style={styles.textStrong}>
            {t('host')}:
          </Text>
          <Text style={styles.text}>{visit.host?.name}</Text>
        </View>
        <View style={styles.col}>
          <Text fontType="semibold" style={styles.textStrong}>
            {t('time')}:
          </Text>
          <Text style={styles.text}>{formattedDate}</Text>
        </View>
        {!!visit.meeting?.location && (
          <View style={styles.col}>
            <Text fontType="semibold" style={styles.textStrong}>
              {t('location')}:
            </Text>
            <Text style={styles.text}>{visit.meeting?.location}</Text>
          </View>
        )}
        {!!visit.meeting?.meetingLocation && (
          <>
            <View style={styles.col}>
              <Text fontType="semibold" style={styles.textStrong}>
                {t('location')}:
              </Text>
              <Text style={[styles.text, { flex: 1 }]}>{visit.meeting?.meetingLocation?.name}</Text>
              {/* TODO: Show more information about the location */}
              {false && (
                <TouchableOpacity
                  style={styles.infoButtonTag}
                  hitSlop={{ top: 8, right: 8, bottom: 8, left: 8 }}
                  // onPress={() => setImageVisible((prev) => !prev)}
                >
                  <Text color={colors.dark3}>Mais informações</Text>
                </TouchableOpacity>
              )}
            </View>
          </>
        )}
      </View>
      <View style={styles.buttonContanier}>
        <TouchableOpacity style={styles.button} onPress={() => hideModalMeeting(false)}>
          <Text fontType="semibold" size={24} color={colors.dark3}>
            {t('Cancel')}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.button} onPress={handleConfirm}>
          <Text fontType="semibold" size={24} color={colors.dark3}>
            {t('Confirm')}
          </Text>
        </TouchableOpacity>
      </View>
    </ModalView>
  );
};

export default ModalMeeting;

const styles = StyleSheet.create({
  content: {
    width: '100%',
    paddingBottom: 24,
    paddingHorizontal: 24,
    borderBottomWidth: 1,
    borderBottomColor: colors.light3,
    overflow: 'hidden',
  },
  col: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 12,
  },
  infoButtonTag: {
    marginTop: 4,
    marginLeft: 8,
    paddingHorizontal: 8,
    paddingVertical: 2,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.light3,
  },
  textStrong: {
    fontSize: fontSizes.lg2,
  },
  text: {
    fontSize: fontSizes.xl,
    marginLeft: 8,
  },
  buttonContanier: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: 12,
    width: '100%',
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 12,
  },
});
