import { createStackNavigator } from '@react-navigation/stack';
import Inactivity from '_/components/Inactivity';
import DeliveryHostScreen from '_/screens/Delivery/Host';
import DeliveryMessageScreen from '_/screens/Delivery/Message';
import React from 'react';

import { AppRoute } from './types';

const DeliveryStack = createStackNavigator();

export default function DeliveryStackScreen() {
  return (
    <Inactivity>
      <DeliveryStack.Navigator
        screenOptions={{ animationEnabled: true, headerShown: false, presentation: 'card' }}
      >
        <DeliveryStack.Screen name={AppRoute.DELIVERY_HOST} component={DeliveryHostScreen} />
        <DeliveryStack.Screen name={AppRoute.DELIVERY_MESSAGE} component={DeliveryMessageScreen} />
      </DeliveryStack.Navigator>
    </Inactivity>
  );
}
