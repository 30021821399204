import { Button, Input, Text } from '_/components';
import KeyboardContainer from '_/components/KeyboardContainer';
import { colors, fontSizes } from '_/config/theme';
import { wp } from '_/helpers/dimension';
import { useAuth } from '_/hooks/AuthContext';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, StyleSheet, View } from 'react-native';
import { showMessage } from 'react-native-flash-message';

const LgpdView: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [endpoint, setEndpoint] = useState('http://');
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();
  const [focusedInput, setFocusedInput] = useState('');
  const { getLgpdAuthentication } = useAuth();

  async function handleSubmit() {
    try {
      setLoading(true);
      await getLgpdAuthentication(endpoint, user, password);
    } catch (error) {
      setLoading(false);
      showMessage({
        message: t('error'),
        description: t('authenticationError'),
        backgroundColor: colors.danger,
      });
    }
  }

  return (
    <View style={styles.container}>
      <KeyboardContainer
        keyboardOpen={!!focusedInput}
        onChange={(text) => {
          if (focusedInput === 'endpoint') setEndpoint(text);
          if (focusedInput === 'user') setUser(text);
          if (focusedInput === 'password') setPassword(text);
        }}
        inputName={focusedInput}
        keyboardType="default"
      >
        <Text size={24} style={styles.label} center>
          {t('insertTheSettings')}
        </Text>
        <ScrollView scrollEnabled keyboardShouldPersistTaps="always">
          <View style={styles.content}>
            <Input
              label="Endpoint"
              value={endpoint}
              onChangeText={(text) => setEndpoint(text)}
              onFocus={() => setFocusedInput('endpoint')}
              onBlur={() => setFocusedInput('')}
              labelSize={fontSizes.xl}
              containerStyle={styles.input}
              style={{ fontSize: fontSizes.xl }}
            />
            <Input
              label={t('User')}
              value={user}
              onChangeText={(text) => setUser(text)}
              onFocus={() => setFocusedInput('user')}
              onBlur={() => setFocusedInput('')}
              labelSize={fontSizes.xl}
              style={{ fontSize: fontSizes.xl }}
              containerStyle={styles.input}
            />
            <Input
              label={t('Password')}
              value={password}
              onChangeText={(text) => setPassword(text)}
              onFocus={() => setFocusedInput('password')}
              onBlur={() => setFocusedInput('')}
              labelSize={fontSizes.xl}
              style={{ fontSize: fontSizes.xl }}
              containerStyle={styles.input}
            />
            <Button
              large
              onPress={handleSubmit}
              buttonStyles={{ marginTop: 24 }}
              loading={isLoading}
            >
              {t('Start')}
            </Button>
          </View>
        </ScrollView>
      </KeyboardContainer>
    </View>
  );
};

export default LgpdView;

const styles = StyleSheet.create({
  container: {
    paddingVertical: 20,
    borderRadius: 12,
    borderWidth: 2,
    borderColor: colors.light3,
    backgroundColor: colors.white,
    width: wp(550),
    maxHeight: 500,
  },
  content: {
    paddingHorizontal: 24,
  },
  label: {
    marginBottom: 12,
  },
  input: {
    paddingHorizontal: 20,
    paddingVertical: 28,
    marginTop: 24,
  },
});
