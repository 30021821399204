import React from 'react';
import { FlatList, Platform, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import DataCell from './DataCell';
import { HostsVisitsType } from './HostsVisitsType';

interface DataFlatListProps<T> {
  data: T[];
  onPressFunction(item: T): void;
  isHosts?: boolean;
  flatListStyled?: StyleProp<ViewStyle>;
}

const HostsVisitsFlatList = <T extends HostsVisitsType>({
  data,
  onPressFunction,
  isHosts,
  flatListStyled,
}: DataFlatListProps<T>) => {
  return (
    <View style={[styles.container, flatListStyled]}>
      <FlatList
        style={StyleSheet.absoluteFill}
        keyboardShouldPersistTaps="always"
        data={data}
        keyExtractor={(item) => item.id}
        showsVerticalScrollIndicator={Platform.OS === 'web'}
        renderItem={({ item }) => (
          <DataCell data={item} isHosts={isHosts} onPress={() => onPressFunction(item)} />
        )}
      />
    </View>
  );
};

export default HostsVisitsFlatList;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 24,
    paddingBottom: 48,
  },
});
