import {
  CompaniesModel,
  CompanyType,
  CustomizationsModel,
  GDPRModel,
  HostsModel,
  IpadLang,
  NDAModel,
} from '@w3lcome/types';
import { useUpdateCustomization } from '_/hooks/useUpdateCustomization';
import {
  childrenCompaniesApi,
  childrenCustomizationsApi,
  childrenGDPRsApi,
  childrenNDAsApi,
  hostApi,
} from '_/services/api';
import logger from '_/services/logger';
import React, { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react';
import Constants from 'expo-constants';

import { useCustomization } from './CustomizationContext';
import { useLanguage } from './LanguageContext';
import i18n from '_/services/locale';

export interface ChildrenCompaniesContextData {
  companies: CompaniesModel[];
  selectedCompany?: CompaniesModel;
  customizations: CustomizationsModel[];
  selectedCustomization?: CustomizationsModel;
  gdprs: GDPRModel[];
  selectedGDPR?: GDPRModel;
  ndas: NDAModel[];
  selectedNDA?: NDAModel;
  loadChildrenData: () => Promise<void>;
  removeData: () => void;
  removeRemoteData: () => void;
  setSelectedCompanyId: (id?: string) => void;
  selectedCompanyId?: string;
  childrenHostsList: HostsModel[];
  searchChildrenHosts(text?: string): void;
}

const ChildrenCompaniesContext = createContext<ChildrenCompaniesContextData>(
  {} as ChildrenCompaniesContextData
);

interface ChildrenCompaniesContextProps {
  children: React.ReactNode;
}

export const ChildrenCompaniesProvider: React.FC<ChildrenCompaniesContextProps> = ({
  children,
}) => {
  const [companies, setCompanies] = useState<CompaniesModel[]>([]);
  const [customizations, setCustomizations] = useState<CustomizationsModel[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>();
  const [gdprs, setGDPRs] = useState<GDPRModel[]>([]);
  const [ndas, setNDAs] = useState<NDAModel[]>([]);

  const [childrenHostsList, setChildrenHostsList] = useState<HostsModel[]>([]);
  const [childrenHostsListAll, setChildrenHostsListAll] = useState<HostsModel[]>([]);

  const [hostsCompanies, setHostsCompanies] = useState<Record<string, HostsModel[]>>({});

  const { company } = useCustomization();
  const { language } = useLanguage();
  const { updateFields } = useUpdateCustomization();

  const [selectedCompany, selectedCustomization, selectedGDPRs, selectedNDAs] = useMemo(() => {
    if (selectedCompanyId) {
      const company = companies.find((company) => company.id === selectedCompanyId);
      const customization = customizations.find(
        (customization) => customization.companyId === selectedCompanyId
      );
      const selectedGDPRs = gdprs.filter((gdpr) => gdpr.customizationId === selectedCompanyId);
      const selectedNDAs = ndas.filter((nda) => nda.customizationId === selectedCompanyId);

      return [company, customization, selectedGDPRs, selectedNDAs];
    }

    return [];
  }, [selectedCompanyId, companies, customizations, gdprs, ndas]);

  const selectedGDPR = useMemo(() => {
    if (selectedGDPRs?.length !== 0) {
      const findCurrentLang = selectedGDPRs?.find((e) => e.lang === language.code);
      const findLangEn = selectedGDPRs?.find((e) => e.lang === 'en');

      if (findCurrentLang) {
        return findCurrentLang;
      }

      if (findLangEn) {
        return findLangEn;
      }

      return selectedGDPRs?.[0];
    }

    return undefined;
  }, [selectedGDPRs, language]);

  const selectedNDA = useMemo(() => {
    if (selectedNDAs?.length !== 0) {
      const findCurrentLang = selectedNDAs?.find((e) => e.lang === language.code);
      const findLangEn = selectedNDAs?.find((e) => e.lang === 'en');

      if (findCurrentLang) {
        return findCurrentLang;
      }

      if (findLangEn) {
        return findLangEn;
      }

      return selectedNDAs?.[0];
    }

    return undefined;
  }, [selectedNDAs, language]);

  const getAllChildrenHosts = async () => {
    if (!selectedCompanyId) {
      return;
    }

    const { data, limit, total } = await hostApi.getListChildrenHosts({
      companyId: selectedCompanyId,
    });

    let allHosts: HostsModel[] = data;
    let loopCount = 0;
    let skip = limit;
    const maxLoops = 50;

    while (skip < total && loopCount < maxLoops) {
      const newHosts = await hostApi.getListChildrenHosts({
        companyId: selectedCompanyId,
        $skip: skip,
      });
      allHosts = allHosts.concat(newHosts.data);
      skip += newHosts.limit;
      loopCount++;
    }

    if (loopCount >= maxLoops) {
      console.warn(
        'Max number of loops reached when trying to get all hosts, breaking to prevent potential infinite loop.'
      );
    }

    setHostsCompanies({ ...hostsCompanies, [selectedCompanyId]: allHosts });

    setChildrenHostsList(allHosts);
    setChildrenHostsListAll(allHosts);
  };

  const searchChildrenHosts = async (text?: string) => {
    if (text) {
      const filtered = childrenHostsListAll.filter(
        ({ name, email, sector }) =>
          name.toLowerCase().includes(text.toLowerCase()) ||
          email?.toLowerCase().includes(text.toLowerCase()) ||
          sector?.toLowerCase().includes(text.toLowerCase())
      );

      return setChildrenHostsList(filtered);
    }

    return setChildrenHostsListAll(childrenHostsListAll);
  };

  function removeData() {
    setCompanies([]);
    setCustomizations([]);
    removeRemoteData();
  }

  function removeRemoteData() {
    setSelectedCompanyId(undefined);
    setChildrenHostsList([]);
    setChildrenHostsListAll([]);
  }

  const loadChildrenData = useCallback(async () => {
    try {
      const { data: companiesData } = await childrenCompaniesApi.getList();
      const { data: customizationsData } = await childrenCustomizationsApi.getList();

      const updatedCustomizations = customizationsData.map(updateFields);

      setCompanies(companiesData);
      setCustomizations(updatedCustomizations);

      if (companiesData.length !== 0) {
        const { data: gdprData } = await childrenGDPRsApi.getList();
        const { data: ndaData } = await childrenNDAsApi.getList();

        setGDPRs(gdprData);
        setNDAs(ndaData);
      }
    } catch (error) {
      logger(error);
    }
  }, [updateFields]);

  useEffect(() => {
    if (company?.type === CompanyType.PARENT) {
      loadChildrenData();
    }
  }, [company, loadChildrenData]);

  useEffect(() => {
    loadTranslations();
  }, [customizations]);

  async function loadTranslations() {
    const translationsMap: { [key: string]: any } = {};

    const supportedLangs = [IpadLang.PTBR, IpadLang.EN, IpadLang.ENGB, IpadLang.ES];

    for (const childCompany of customizations) {
      if (childCompany.translationId) {
        translationsMap[childCompany.translationId] = {};

        for (const lang of supportedLangs) {
          const response = await fetch(
            `${Constants.expoConfig?.extra?.assetsURL}/locales/${childCompany.translationId}/totem/${lang}/translation.json`
          );

          const translationData = await response.json().catch((e) => console.error(e));

          translationsMap[childCompany.translationId][lang] = translationData;
        }
      }
    }

    for (const lang of supportedLangs) {
      for (const translationId in translationsMap) {
        const namespace = `translation_${translationId}`;
        i18n.addResourceBundle(lang, namespace, translationsMap[translationId][lang], true, true);
      }
    }
  }

  useEffect(() => {
    if (selectedCompanyId) {
      const storedHosts = hostsCompanies[selectedCompanyId];

      if (storedHosts) {
        setChildrenHostsListAll(storedHosts);
      } else {
        getAllChildrenHosts();
      }
    }
  }, [selectedCompanyId, hostsCompanies]);

  return (
    <ChildrenCompaniesContext.Provider
      value={{
        companies,
        selectedCompany,
        customizations,
        selectedCustomization,
        gdprs,
        selectedGDPR,
        ndas,
        selectedNDA,
        loadChildrenData,
        removeData,
        setSelectedCompanyId,
        selectedCompanyId,
        childrenHostsList,
        searchChildrenHosts,
        removeRemoteData,
      }}
    >
      {children}
    </ChildrenCompaniesContext.Provider>
  );
};

export function useChildrenCompanies(): ChildrenCompaniesContextData {
  const context = useContext(ChildrenCompaniesContext);

  if (!context) {
    throw new Error('useChildrenCompanies must be used within an ChildrenCompaniesProvider');
  }

  return context;
}
