import { BadgeConfig } from '@w3lcome/types';
import { BadgeInit } from '_/interfaces/BadgeInit';
import { PrinterPaper } from '_/interfaces/PrinterPaper';

import { paper29mmx90mm } from './paper29mmx90mm';
import { paper29mmx90mmTest } from './paper29mmx90mmTest';
import { paper62mmx100mm } from './paper62mmx100mm';
import { paper62mmx100mmTest } from './paper62mmx100mmTest';
import { paper62mmx29mmTest } from './paper62mmx29mmTest';

export const badges: Record<PrinterPaper, BadgeInit | undefined> = {
  [PrinterPaper.Paper17mmx54mm]: undefined,
  [PrinterPaper.Paper17mmx87mm]: undefined,
  [PrinterPaper.Paper23mmx23mm]: undefined,
  [PrinterPaper.Paper29mmx42mm]: undefined,
  [PrinterPaper.Paper29mmx90mm]: paper29mmx90mm,
  [PrinterPaper.Paper38mmx90mm]: undefined,
  [PrinterPaper.Paper39mmx48mm]: undefined,
  [PrinterPaper.Paper52mmx29mm]: undefined,
  [PrinterPaper.Paper62mmx29mm]: undefined,
  [PrinterPaper.Paper62mmx100mm]: paper62mmx100mm,
  [PrinterPaper.Paper60mmx86mm]: undefined,
  [PrinterPaper.Paper54mmx29mm]: undefined,
  [PrinterPaper.Paper102mmx51mm]: undefined,
  [PrinterPaper.Paper102mmx152mm]: undefined,
  [PrinterPaper.Paper103mmx164mm]: undefined,
  [PrinterPaper.Paper12mm]: undefined,
  [PrinterPaper.Paper29mm]: undefined,
  [PrinterPaper.Paper38mm]: undefined,
  [PrinterPaper.Paper50mm]: undefined,
  [PrinterPaper.Paper54mm]: undefined,
  [PrinterPaper.Paper62mm]: paper62mmx100mm,
  [PrinterPaper.Paper62mmRB]: paper62mmx100mm,
  [PrinterPaper.Paper102mm]: undefined,
  [PrinterPaper.Paper103mm]: undefined,
  [PrinterPaper.PaperDT90mm]: undefined,
  [PrinterPaper.PaperDT102mm]: undefined,
  [PrinterPaper.PaperDT102mmx51mm]: undefined,
  [PrinterPaper.PaperDT102mmx152mm]: undefined,
};

export const testBadges: Record<PrinterPaper, BadgeConfig | undefined> = {
  [PrinterPaper.Paper17mmx54mm]: undefined,
  [PrinterPaper.Paper17mmx87mm]: undefined,
  [PrinterPaper.Paper23mmx23mm]: undefined,
  [PrinterPaper.Paper29mmx42mm]: undefined,
  [PrinterPaper.Paper29mmx90mm]: paper29mmx90mmTest,
  [PrinterPaper.Paper38mmx90mm]: undefined,
  [PrinterPaper.Paper39mmx48mm]: undefined,
  [PrinterPaper.Paper52mmx29mm]: undefined,
  [PrinterPaper.Paper62mmx29mm]: paper62mmx29mmTest,
  [PrinterPaper.Paper62mmx100mm]: paper62mmx100mmTest,
  [PrinterPaper.Paper60mmx86mm]: undefined,
  [PrinterPaper.Paper54mmx29mm]: undefined,
  [PrinterPaper.Paper102mmx51mm]: undefined,
  [PrinterPaper.Paper102mmx152mm]: undefined,
  [PrinterPaper.Paper103mmx164mm]: undefined,
  [PrinterPaper.Paper12mm]: undefined,
  [PrinterPaper.Paper29mm]: undefined,
  [PrinterPaper.Paper38mm]: undefined,
  [PrinterPaper.Paper50mm]: undefined,
  [PrinterPaper.Paper54mm]: undefined,
  [PrinterPaper.Paper62mm]: paper62mmx100mmTest,
  [PrinterPaper.Paper62mmRB]: paper62mmx100mmTest,
  [PrinterPaper.Paper102mm]: undefined,
  [PrinterPaper.Paper103mm]: undefined,
  [PrinterPaper.PaperDT90mm]: undefined,
  [PrinterPaper.PaperDT102mm]: undefined,
  [PrinterPaper.PaperDT102mmx51mm]: undefined,
  [PrinterPaper.PaperDT102mmx152mm]: undefined,
};
