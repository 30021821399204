import { IpadLang } from '@w3lcome/types';
import { HTMLRender, Text } from '_/components';
import { colors, defaultShadow, fontSizes } from '_/config/theme';
import { getDateLocale } from '_/helpers/getDateLocale';
import { textReplace } from '_/helpers/textReplace';
import { useCustomization } from '_/hooks/CustomizationContext';
import { useVisit } from '_/hooks/VisitContext';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, TouchableOpacity, ImageBackground } from 'react-native';

interface PaperProps {
  onClearSignature: () => void;
  isClearVisible: boolean;
  signatureImage?: string;
  paperPaddingHorizontal: number;
  children?: React.ReactNode;
}

const Paper: React.FC<PaperProps> = ({
  paperPaddingHorizontal,
  signatureImage,
  isClearVisible,
  onClearSignature,
  children,
}) => {
  const { customization, nda } = useCustomization();
  const { t, i18n } = useTranslation();

  const { visit } = useVisit();

  const ndaText = useMemo(() => {
    return textReplace(nda?.ndaText || '', i18n.language as IpadLang, customization, visit);
  }, [customization]);

  const today = useMemo(() => {
    const locale = getDateLocale(i18n.language as IpadLang);
    const now = new Date();
    return format(now, 'PPP', { locale });
  }, []);

  return (
    <View style={[styles.paper, { paddingHorizontal: paperPaddingHorizontal }]}>
      {!!nda?.ndaText && (
        <View>
          <HTMLRender html={ndaText} />
        </View>
      )}
      <View style={styles.signatureContent}>
        <ImageBackground
          source={{ uri: signatureImage }}
          style={{ height: 300, width: '100%', borderRadius: 12 }}
        >
          {children}
        </ImageBackground>

        {!isClearVisible && (
          <View
            pointerEvents="none"
            style={{ position: 'absolute', top: '30%', alignSelf: 'center' }}
          >
            <Text color={colors.light2} size={64}>
              {t('NDAScreen.signHere')}
            </Text>
          </View>
        )}

        <View style={styles.bottomSignatureContent}>
          <Text size={fontSizes.lg2} style={styles.labelBottom}>
            {t('NDAScreen.Signature')}
          </Text>
          {isClearVisible && (
            <TouchableOpacity style={styles.buttonClear} onPress={onClearSignature}>
              <Text color={colors.white} size={fontSizes.lg}>
                {t('NDAScreen.Clear')}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
      <View pointerEvents="none" style={styles.bottomContent}>
        <View style={styles.textContent}>
          <Text size={fontSizes.lg2} style={styles.textBottom}>
            {visit.name || ' '}
          </Text>
          <View style={styles.divider} />
          <Text size={fontSizes.lg} style={styles.labelBottom}>
            {t('Name')}
          </Text>
        </View>
        <View style={styles.textContent}>
          <Text size={fontSizes.lg2} style={styles.textBottom}>
            {today}
          </Text>
          <View style={styles.divider} />
          <Text size={fontSizes.lg} style={styles.labelBottom}>
            {t('Date')}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default Paper;

const styles = StyleSheet.create({
  paper: {
    ...defaultShadow,
    flex: 1,
    paddingVertical: 24,
    backgroundColor: colors.white,
    justifyContent: 'space-between',
  },
  bottomContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 18,
  },
  textContent: {
    flex: 1,
    paddingHorizontal: 18,
  },
  textBottom: {
    textAlign: 'center',
    color: colors.black,
  },
  labelBottom: {
    textAlign: 'center',
    color: colors.light2,
  },
  signatureContent: {
    flex: 1,
    width: '100%',
    marginTop: 18,
    justifyContent: 'flex-end',
  },
  bottomSignatureContent: {
    width: '100%',
    position: 'relative',
  },
  buttonClear: {
    position: 'absolute',
    right: 0,
    top: 6,
    paddingHorizontal: 32,
    paddingVertical: 4,
    backgroundColor: colors.light2,
    borderRadius: 6,
  },
  divider: {
    height: 1,
    backgroundColor: colors.light3,
    borderRadius: 2,
    marginVertical: 4,
  },
});
