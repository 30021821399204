import { fontSizes } from '_/config/theme';
import { ExtendedCountryCode, countries } from '_/helpers/countryDials';
import { useVisit } from '_/hooks/VisitContext';
import React, { useMemo } from 'react';
import { Image, TouchableOpacity } from 'react-native';

import Text from '../Text';
interface CountryPickerProps {
  countryCode?: ExtendedCountryCode;
}

const CountryPicker: React.FC<CountryPickerProps> = ({ countryCode }) => {
  const country = useMemo(() => {
    return countries.find((e) => e.code.toLocaleUpperCase() === countryCode);
  }, [countryCode]);
  const { setCountryPickerModalActive } = useVisit();

  return (
    <TouchableOpacity
      hitSlop={{ top: 12, right: 12, bottom: 12, left: 12 }}
      onPress={() => setCountryPickerModalActive(true)}
      activeOpacity={1}
      style={{ flexDirection: 'row', alignItems: 'center' }}
    >
      {!!country && (
        <>
          <Image source={country.flag} style={{ width: 30, height: 30, marginRight: 12 }} />
          <Text fontType="semibold" size={fontSizes.lg} style={{ marginRight: 8 }}>
            {country.countryCode}
          </Text>
        </>
      )}
    </TouchableOpacity>
  );
};

export default CountryPicker;
