import { CustomizationsModel } from '@w3lcome/types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getNameField, getPrimaryKeyField } from '../helpers/fields';

export function useUpdateCustomization() {
  const { t } = useTranslation();

  const updateFields = useCallback(
    (customizationData: CustomizationsModel): CustomizationsModel => {
      const primaryKeyField = getPrimaryKeyField(customizationData);
      const nameField = getNameField(customizationData, t('Name'));

      return {
        ...customizationData,
        fields: (customizationData.fields || [])
          .concat([primaryKeyField, nameField])
          .sort((a, b) => a.sort - b.sort),
        questionnaireFields: (customizationData.questionnaireFields || []).sort(
          (a, b) => a.sort - b.sort
        ),
        feedbacks: (customizationData.feedbacks || []).sort((a, b) => a.sort - b.sort),
      };
    },
    [t]
  );

  return {
    updateFields,
  };
}
