import { IpadLang, Lang } from '@w3lcome/types';
import { enUS, ptBR, de, fr, zhCN, enGB } from 'date-fns/locale';

export function getDateLocale(lang: IpadLang | Lang | null = IpadLang.EN) {
  switch (lang) {
    case IpadLang.PTBR:
      return ptBR;
    case IpadLang.ZHHANS:
      return zhCN;
    case IpadLang.FR:
      return fr;
    case IpadLang.DE:
      return de;
    case IpadLang.ENGB:
      return enGB;
    default:
      return enUS;
  }
}
