import { DeliveriesModel } from '@w3lcome/types';

import db from './SqliteDatabase';

const init = () => {
  db?.transaction((tx) => {
    tx.executeSql(
      'CREATE TABLE IF NOT EXISTS deliveries_table (id TEXT PRIMARY KEY, delivery TEXT);'
    );
  });
};

const create = (delivery: Partial<DeliveriesModel>) => {
  return new Promise((resolve, reject) => {
    db?.transaction((tx) => {
      tx.executeSql(
        'INSERT INTO deliveries_table (id, delivery) VALUES (?, ?);',
        [delivery.id as string, JSON.stringify(delivery)],
        (_, { rowsAffected, insertId }) => {
          if (rowsAffected > 0) resolve(insertId);
          else reject(new Error(`Error inserting delivery with id: ${delivery.id}`));
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

const deleteOne = (id: string) => {
  return new Promise((resolve, reject) => {
    db?.transaction((tx) => {
      tx.executeSql(
        'DELETE FROM deliveries_table WHERE id = ?',
        [id],
        (_, { rowsAffected }) => {
          if (rowsAffected > 0) resolve(true);
          else reject(new Error('Error removing delivery'));
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

const all = (): Promise<DeliveriesModel[]> => {
  return new Promise((resolve, reject) => {
    db?.transaction((tx) => {
      tx.executeSql(
        'SELECT * FROM deliveries_table;',
        [],
        (_, { rows }) => {
          const deliveries: DeliveriesModel[] = [];
          for (let i = 0; i < rows.length; ++i) {
            const item = rows.item(i);
            deliveries.push(JSON.parse(item.delivery));
          }
          resolve(deliveries);
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

const deleteTable = () => {
  return new Promise((resolve, reject) => {
    db?.transaction((tx) => {
      tx.executeSql(
        'DROP TABLE deliveries_table;',
        [],
        (_, { rowsAffected }) => {
          resolve(rowsAffected);
        },
        (_, error) => {
          reject(error);
          return false;
        }
      );
    });
  });
};

export default {
  create,
  all,
  deleteTable,
  deleteOne,
  init,
};
