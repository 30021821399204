import { Button, Text } from '_/components';
import { colors, fontSizes } from '_/config/theme';
import { useCustomization } from '_/hooks/CustomizationContext';
import { ResizeMode, Video } from 'expo-av';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Platform } from 'react-native';
import ReactPlayer from 'react-player';

interface QuestionnaireVideoProps {
  onPressNext: () => void;
}

export const QuestionnaireVideo: React.FC<QuestionnaireVideoProps> = ({ onPressNext }) => {
  const [isWatched, setWatched] = useState(false);
  const { customization, setIsVideoPlaying } = useCustomization();
  const { t } = useTranslation();

  function finishVideo() {
    setIsVideoPlaying(false);
    setWatched(true);
  }

  return (
    <View style={{ flex: 1 }}>
      <Text
        center
        style={styles.title}
        size={fontSizes.xl2}
        fontType="semibold"
        color={colors.dark3}
      >
        {t('questionnaireScreen.WatchVideo')}
      </Text>

      {!!customization?.questionnaireVideoDescription && (
        <Text
          center
          style={styles.title}
          size={fontSizes.lg2}
          fontType="medium"
          color={colors.dark1}
        >
          {customization.questionnaireVideoDescription}
        </Text>
      )}

      <View style={styles.content}>
        {Platform.OS === 'web' ? (
          <View style={styles.videoContent}>
            <ReactPlayer
              url={customization?.questionnaireVideoUrl!}
              playing
              onEnded={() => finishVideo()}
              onError={() => finishVideo()}
              width="100%"
              height="100%"
              onPlay={() => {
                setIsVideoPlaying(true);
              }}
            />
          </View>
        ) : (
          <Video
            style={styles.video}
            source={{ uri: customization?.questionnaireVideoUrl! }}
            resizeMode={ResizeMode.CONTAIN}
            isLooping={false}
            shouldPlay
            onError={() => finishVideo()}
            onPlaybackStatusUpdate={(status: any) => {
              if (status.isPlaying) {
                setIsVideoPlaying(true);
              }

              if (status.isLoaded && status.didJustFinish) {
                finishVideo();
              }
            }}
          />
        )}

        <View style={styles.buttonView}>
          <Button
            large
            backgroundColor={customization?.mainColor || colors.primary}
            labelColor={customization?.backgroundColor || colors.white}
            disabled={!isWatched}
            onPress={onPressNext}
          >
            {t('Next')}
          </Button>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  content: {
    flex: 1,
    justifyContent: 'space-between',
    paddingHorizontal: 32,
  },
  buttonView: {
    width: '100%',
    marginTop: 32,
  },
  title: {
    paddingHorizontal: 32,
    paddingBottom: 18,
    flexWrap: 'wrap',
  },
  videoContent: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  video: {
    ...Platform.select({
      ios: {
        flex: 1,
      },
      android: {
        flex: 1,
      },
    }),
    justifyContent: 'center',
    backgroundColor: colors.light3,
    borderRadius: 4,
  },
});
