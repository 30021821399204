import { BadgeConfig } from '@w3lcome/types';
import { badgeScale } from '_/constants/badgeScale';
import { BadgeInit } from '_/interfaces/BadgeInit';

export const paper29mmx90mm: BadgeInit = ({ field1, field2, field3, image = '' }) => {
  const badgeConfig: BadgeConfig = {
    width: 900 * badgeScale,
    height: 290 * badgeScale,
    fields: [
      {
        type: 'text',
        subType: 'text',
        value: field1.value,
        styles: {
          top: 0 * badgeScale,
          left: 0 * badgeScale,
          width: 600 * badgeScale,
          height: 82 * badgeScale,
          fontSize: 62 * badgeScale,
          fontWeight: 'bold',
        },
      },
      {
        type: 'text',
        subType: 'text',
        value: field2.value,
        styles: {
          top: 90 * badgeScale,
          left: 0 * badgeScale,
          width: 600 * badgeScale,
          height: 62 * badgeScale,
          fontSize: 52 * badgeScale,
          fontWeight: 'normal',
        },
      },
      {
        type: 'text',
        subType: 'text',
        value: `${field3.title}`,
        styles: {
          top: 170 * badgeScale,
          left: 0 * badgeScale,
          width: 600 * badgeScale,
          height: 62 * badgeScale,
          fontSize: 40 * badgeScale,
          fontWeight: 'normal',
        },
      },
      {
        type: 'text',
        subType: 'text',
        value: field3.value,
        styles: {
          top: 210 * badgeScale,
          left: 0 * badgeScale,
          width: 640 * badgeScale,
          height: 62 * badgeScale,
          fontSize: 50 * badgeScale,
          fontWeight: 'normal',
        },
      },
      {
        type: 'image',
        subType: 'image',
        value: image,
        styles: {
          top: 16 * badgeScale,
          left: 644 * badgeScale,
          width: 248 * badgeScale,
          height: 248 * badgeScale,
        },
      },
    ],
  };

  return badgeConfig;
};
