import 'react-native-gesture-handler';
import { images } from '_/assets';
import { fontFiles, fontFamily } from '_/config/theme';
import { AuthProvider } from '_/hooks/AuthContext';
import { CallbackProvider } from '_/hooks/CallbackContext';
import { ChildrenCompaniesProvider } from '_/hooks/ChildrenCompaniesContex';
import { CustomizationProvider } from '_/hooks/CustomizationContext';
import { DeliveryProvider } from '_/hooks/DeliveryContext';
import { EnvironmentProvider } from '_/hooks/EnvironmentContext';
import { HostsProvider } from '_/hooks/HostsContext';
import { IntegrationsProvider } from '_/hooks/IntegrationsContext';
import { LanguageProvider } from '_/hooks/LanguageContext';
import { PrintersProvider } from '_/hooks/PrintersContext';
import { VisitProvider } from '_/hooks/VisitContext';
import { VisitsListProvider } from '_/hooks/VisitsListContext';
import Routes from '_/navigation';
import { CustomizationLgpdProvider } from '_/screens/LgpdCheckinFlow/hooks/CustomizationLgpdContext';
import { VisitLgpdProvider } from '_/screens/LgpdCheckinFlow/hooks/VisitLgpdContext';
import i18n from '_/services/locale';
import logger from '_/services/logger';
import { initSentry } from '_/services/sentry/initSentry';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import React, { useCallback, useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { View, StyleSheet } from 'react-native';
import FlashMessage from 'react-native-flash-message';

initSentry();

export default function App() {
  const [loading, setLoading] = useState(true);

  async function loadAsync() {
    await Asset.loadAsync(Object.values(images));
    return Font.loadAsync(fontFiles);
  }

  useEffect(() => {
    async function prepare() {
      try {
        await loadAsync();
      } catch (error) {
        logger(error);
      } finally {
        setLoading(false);
      }
    }

    prepare();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (!loading) {
      await SplashScreen.hideAsync();
    }
  }, [loading]);

  if (loading) {
    return null;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <EnvironmentProvider>
        <AuthProvider>
          <LanguageProvider>
            <CustomizationProvider>
              <ChildrenCompaniesProvider>
                <CustomizationLgpdProvider>
                  <HostsProvider>
                    <VisitProvider>
                      <VisitLgpdProvider>
                        <VisitsListProvider>
                          <DeliveryProvider>
                            <PrintersProvider>
                              <IntegrationsProvider>
                                <CallbackProvider>
                                  <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
                                    <Routes />
                                    <StatusBar hidden />
                                    <FlashMessage
                                      floating
                                      position="top"
                                      duration={5000}
                                      titleStyle={styles.titleStyle}
                                      textStyle={styles.textStyle}
                                    />
                                  </View>
                                </CallbackProvider>
                              </IntegrationsProvider>
                            </PrintersProvider>
                          </DeliveryProvider>
                        </VisitsListProvider>
                      </VisitLgpdProvider>
                    </VisitProvider>
                  </HostsProvider>
                </CustomizationLgpdProvider>
              </ChildrenCompaniesProvider>
            </CustomizationProvider>
          </LanguageProvider>
        </AuthProvider>
      </EnvironmentProvider>
    </I18nextProvider>
  );
}

const styles = StyleSheet.create({
  titleStyle: {
    fontFamily: fontFamily.bold,
    fontSize: 26,
    lineHeight: 26,
    textAlign: 'center',
  },
  textStyle: {
    fontFamily: fontFamily.regular,
    fontSize: 20,
    lineHeight: 20,
    paddingTop: 12,
    textAlign: 'center',
  },
});
