import { Feather } from '@expo/vector-icons';
import { colors, defaultShadow, fontFamily, fontSizes } from '_/config/theme';
import React, { useState, useRef, forwardRef, useEffect } from 'react';
import {
  TextInputProps,
  StyleSheet,
  TextInput,
  View,
  Platform,
  NativeSyntheticEvent,
  TextInputFocusEventData,
  StyleProp,
  ViewStyle,
  Animated,
  Easing,
} from 'react-native';

import Text from '../Text';

interface InputProps extends TextInputProps {
  label: string;
  icon?: string;
  containerStyle?: StyleProp<ViewStyle>;
  labelSize?: number;
  staticLabel?: boolean;
  required?: boolean;
}

const Input: React.ForwardRefRenderFunction<TextInput, InputProps> = (
  {
    label,
    containerStyle,
    labelSize,
    value,
    style,
    icon,
    required,
    staticLabel,
    onFocus,
    onBlur,
    ...rest
  },
  ref
) => {
  const [textWidth, setTextWidth] = useState(0);
  const [isFieldActive, setIsFieldActive] = useState(false);
  const labelAnimation = useRef(new Animated.Value(0));

  useEffect(() => {
    Animated.timing(labelAnimation.current, {
      toValue: isFieldActive || value?.trim() ? 1 : 0,
      duration: 250,
      easing: Easing.out(Easing.linear),
      useNativeDriver: Platform.OS !== 'web',
    }).start();
  }, [isFieldActive, value]);

  const handleFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    setIsFieldActive(true);
    onFocus?.(e);
  };

  const handleBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    if (isFieldActive && !value?.trim()) {
      setIsFieldActive(false);
    }
    onBlur?.(e);
  };

  const scale = labelAnimation.current.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0.7],
    extrapolate: 'clamp',
  });

  const translateX = labelAnimation.current.interpolate({
    inputRange: [0, 1],
    outputRange: [icon ? 36 : 0, -(textWidth * 0.2)],
    extrapolate: 'clamp',
  });

  const translateY = labelAnimation.current.interpolate({
    inputRange: [0, 0.5, 1],
    outputRange: [0, -50, -50],
    extrapolate: 'clamp',
  });

  const opacity = labelAnimation.current.interpolate({
    inputRange: [0, 1],
    outputRange: [1, 0.9],
    extrapolate: 'clamp',
  });

  return (
    <Animated.View
      style={[
        styles.container,
        containerStyle,
        {
          ...(staticLabel && {
            paddingHorizontal: 32,
            paddingVertical: 24,
          }),
        },
      ]}
    >
      <View
        style={[
          styles.content,
          {
            ...(staticLabel && {
              flexDirection: 'column',
              alignItems: 'stretch',
              height: 'auto',
            }),
          },
          { borderBottomColor: isFieldActive ? colors.light0 : colors.light3 },
        ]}
      >
        {staticLabel ? (
          <View style={styles.innerContainer}>
            <Text size={labelSize ?? fontSizes.xl3}>{label}</Text>
            {required && (
              <Text fontType="bold" style={styles.asterisk}>
                *
              </Text>
            )}
          </View>
        ) : (
          <Text
            onTextLayout={(e) => setTextWidth(e.nativeEvent.lines[0].width)}
            size={labelSize ?? fontSizes.xl3}
            style={[
              styles.labelStyle,
              { opacity, transform: [{ scale }, { translateX }, { translateY }] },
            ]}
          >
            {label}
          </Text>
        )}

        {!!icon && (
          <Feather
            name={icon as any}
            color={colors.light1}
            size={fontSizes.lg2}
            style={styles.icon}
          />
        )}
        <TextInput
          {...rest}
          ref={ref}
          autoCorrect={false}
          clearButtonMode="while-editing"
          value={value}
          style={[styles.searchInput, style]}
          placeholderTextColor={colors.light3}
          selectionColor={colors.primary}
          underlineColorAndroid="transparent"
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </View>
    </Animated.View>
  );
};

export default forwardRef(Input);

const styles = StyleSheet.create({
  container: {
    padding: 42,
    borderRadius: 8,
    backgroundColor: colors.white,
    ...defaultShadow,
  },
  content: {
    borderBottomWidth: 2,
    height: 56,
    width: '100%',
    position: 'relative',
    flexDirection: 'row',
    alignItems: 'center',
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  icon: {
    marginRight: 12,
  },
  labelStyle: {
    position: 'absolute',
    left: 0,
    color: colors.dark3,
  },
  searchInput: {
    color: colors.dark1,
    fontFamily: fontFamily.medium,
    marginTop: 4,
    height: 50,
    width: '100%',
    fontSize: fontSizes.xl3,
    zIndex: 10,
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
    }),
  },
  asterisk: {
    fontSize: fontSizes.xl,
    color: colors.danger,
    marginLeft: 8,
  },
});
