import { images } from '_/assets';
import { LanguageButton, MultiTap } from '_/components';
import LanguagesChoose from '_/components/LanguagesChoose';
import { colors } from '_/config/theme';
import { hp, wp } from '_/helpers/dimension';
import { useEnvironment } from '_/hooks/EnvironmentContext';
import Constants from 'expo-constants';
import React, { useState } from 'react';
import { Text, View, Image, StyleSheet } from 'react-native';

import LgpdView from './LgpdView';
import PinView from './PinView';

const PinScreen: React.FC = () => {
  const { switchEnvironment } = useEnvironment();
  const [closeLanguageScreen, setCloseLanguageScreen] = useState(false);
  const [LGPDAvailable, setLGPDAvailable] = useState(false);
  const version = Constants.expoConfig?.version;
  
  return (
    <View style={styles.container}>
      <View style={styles.background}>
        <View style={styles.backgroundTop} />
        <View style={styles.backgroundBottom} />
      </View>

      <View style={styles.container}>
        <MultiTap numTaps={3} onPress={() => switchEnvironment()}>
          <View style={{ height: 60 }} />
        </MultiTap>
        <View style={styles.pinContainer}>
          <MultiTap
            numTaps={5}
            onPress={() => {
              if (Constants.expoConfig?.extra?.lgpdTotemAvailable === 'true') {
                setLGPDAvailable(!LGPDAvailable);
              }
            }}
          >
            <View>
              <Image
                resizeMode="contain"
                source={images.logo}
                style={[styles.logo, { marginTop: LGPDAvailable ? 0 : hp(-120) }]}
              />

              {LGPDAvailable ? <LgpdView /> : <PinView />}
            </View>
          </MultiTap>
        </View>
      </View>
      <View style={styles.bottomContainer}>
        <Text style={styles.versionText}>
          {version}
        </Text>
        <LanguageButton textColor={colors.dark2} onPress={() => setCloseLanguageScreen(true)} />
      </View>
      {closeLanguageScreen && <LanguagesChoose close={setCloseLanguageScreen} />}
    </View>
  );
};

export default PinScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  background: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  backgroundTop: {
    flex: 1,
    backgroundColor: colors.primary,
  },
  backgroundBottom: {
    flex: 1,
  },
  logo: {
    marginBottom: 60,
    alignSelf: 'center',
    height: hp(80),
    width: wp(400),
  },
  pinContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomContainer: {
    paddingVertical: 12,
  },
  versionText: {
    color: 'darkgray',
    position: 'absolute',
    left: 20,
    bottom: 10,
  },
});
