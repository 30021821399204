import { Feather } from '@expo/vector-icons';
import { HostsModel } from '@w3lcome/types';
import { Text } from '_/components';
import Modal from '_/components/Modal';
import { colors, fontFamily, fontSizes } from '_/config/theme';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  TextInput,
  Platform,
  KeyboardAvoidingView,
  Keyboard,
  Image,
} from 'react-native';

interface ConfirmHostModalProps {
  host?: HostsModel;
  onHide: () => void;
  onNotify: (message: string) => void;
  ipadDeliveriesIsPictureEnabled: boolean | undefined;
  onDeliveriesPictureEnabled: (value: boolean) => void;
  pictureUrl: string | undefined | null;
}

const ConfirmHostModal: React.FC<ConfirmHostModalProps> = ({
  host,
  onHide,
  onNotify,
  ipadDeliveriesIsPictureEnabled,
  onDeliveriesPictureEnabled,
  pictureUrl,
}) => {
  const [message, setMessage] = useState('');

  const { t } = useTranslation();

  function handleHide() {
    Keyboard.dismiss();
    onHide();
    setMessage('');
  }

  return (
    <Modal isVisible={!!host}>
      <KeyboardAvoidingView
        style={styles.container}
        behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      >
        {ipadDeliveriesIsPictureEnabled && (
          <TouchableOpacity
            onPress={() => onDeliveriesPictureEnabled(true)}
            style={styles.cameraViewAbsolute}
          >
            <Feather name="camera" size={32} />
          </TouchableOpacity>
        )}
        <View style={styles.content}>
          <View style={styles.header}>
            <Text fontType="bold" center size={fontSizes.xl3} color={colors.black}>
              {host?.name}
            </Text>
            <Text size={fontSizes.lg2} style={{ marginTop: 12 }}>
              {t('deliveryScreen.ClickConfirmNotification')}
            </Text>
          </View>
          <View style={styles.contentMessagePicture}>
            <View style={styles.contentMessage}>
              <TextInput
                clearButtonMode="while-editing"
                autoCorrect={false}
                style={styles.textInputStyle}
                placeholder={`${t('message')} (${t('optional')})`}
                value={message}
                onChangeText={(text) => setMessage(text)}
                multiline
              />
            </View>
            {pictureUrl && <Image style={styles.picture} source={{ uri: pictureUrl }} />}
          </View>
          <View style={styles.divider} />
          <View style={styles.buttons}>
            <TouchableOpacity style={styles.button} onPress={handleHide}>
              <Text color={colors.danger} size={fontSizes.lg2} fontType="bold">
                {t('Cancel')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button} onPress={() => onNotify(message)}>
              <Text color={colors.dark2} size={fontSizes.lg2} fontType="bold">
                {t('Confirm')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
};

export default ConfirmHostModal;

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  content: {
    alignItems: 'center',
  },
  header: {
    textAlign: 'center',
    marginTop: 12,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },
  button: {
    flex: 1,
    alignItems: 'center',
    marginTop: 4,
  },
  contentMessagePicture: {
    flexDirection: 'row',
    width: '90%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentMessage: {
    marginTop: 12,
    flex: 1,
  },
  picture: {
    marginVertical: 8,
    height: 120,
    width: 120,
    borderRadius: 4,
  },
  textInputStyle: {
    fontFamily: fontFamily.regular,
    textAlign: 'center',
    fontSize: fontSizes.lg2,
    paddingHorizontal: 24,
    paddingVertical: 12,
    ...Platform.select({
      web: {
        outlineWidth: 0,
        maxHeight: 120,
      },
    }),
  },
  divider: {
    height: 1,
    borderRadius: 2,
    backgroundColor: colors.light3,
    width: '100%',
    marginTop: 24,
    marginBottom: 16,
  },
  cameraViewAbsolute: {
    position: 'absolute',
    right: 32,
    top: 0,
    zIndex: 900,
  },
});
