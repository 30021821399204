import Constants from 'expo-constants';

import { ColorsType } from './../interfaces/ColorsType';
import { FontsType } from './../interfaces/FontsType';

export const colors: ColorsType = {
  primary: Constants.expoConfig?.extra?.colors.primary,
  secondary: Constants.expoConfig?.extra?.colors.secondary,
  warning: Constants.expoConfig?.extra?.colors.warning,
  danger: Constants.expoConfig?.extra?.colors.danger,
  success: Constants.expoConfig?.extra?.colors.success,
  white: Constants.expoConfig?.extra?.colors.white,
  black: Constants.expoConfig?.extra?.colors.black,
  dark1: Constants.expoConfig?.extra?.colors.dark1,
  dark2: Constants.expoConfig?.extra?.colors.dark2,
  dark3: Constants.expoConfig?.extra?.colors.dark3,
  light0: Constants.expoConfig?.extra?.colors.light0,
  light1: Constants.expoConfig?.extra?.colors.light1,
  light2: Constants.expoConfig?.extra?.colors.light2,
  light3: Constants.expoConfig?.extra?.colors.light3,
  transparent: 'transparent',
};

export const spacing = {
  /** @const {4} */
  xxxs: 4,

  /** @const {6} */
  xxs: 6,

  /** @const {8} */
  xs: 8,

  /** @const {12} */
  sm: 12,

  /** @const {16} */
  md: 16,

  /** @const {24} */
  lg: 24,

  /** @const {36} */
  xl: 36,

  /** @const {48} */
  xxl: 48,

  /** @const {60} */
  xxxl: 60,
};

export const fontSizes = {
  /** @const {10} */
  xs: 10,

  /** @const {12} */
  sm: 12,

  /** @const {14} */
  md: 14,

  /** @const {16} */
  md2: 16,

  /** @const {18} */
  lg: 18,

  /** @const {20} */
  lg2: 20,

  /** @const {22} */
  xl: 22,

  /** @const {24} */
  xl2: 24,

  /** @const {28} */
  xl3: 28,

  /** @const {32} */
  xxl: 32,

  /** @const {42} */
  xxxl: 42,
};

export const fontFamily: FontsType = {
  bold: Constants.expoConfig?.extra?.fonts.bold,
  light: Constants.expoConfig?.extra?.fonts.light,
  medium: Constants.expoConfig?.extra?.fonts.medium,
  regular: Constants.expoConfig?.extra?.fonts.regular,
  semibold: Constants.expoConfig?.extra?.fonts.semibold,
};

enum FontNames {
  Montserrat = 'Montserrat',
  BaiJamjuree = 'BaiJamjuree',
}

const fontName = Constants.expoConfig?.extra?.fonts.name as FontNames;

export const fontFiles = {
  ...(fontName === FontNames.BaiJamjuree && {
    [fontFamily.bold]: require(`_/assets/fonts/BaiJamjuree-Bold.ttf`),
    [fontFamily.light]: require(`_/assets/fonts/BaiJamjuree-Light.ttf`),
    [fontFamily.medium]: require(`_/assets/fonts/BaiJamjuree-Medium.ttf`),
    [fontFamily.regular]: require(`_/assets/fonts/BaiJamjuree-Regular.ttf`),
    [fontFamily.semibold]: require(`_/assets/fonts/BaiJamjuree-SemiBold.ttf`),
  }),
  ...(fontName === FontNames.Montserrat && {
    [fontFamily.bold]: require(`_/assets/fonts/Montserrat-Bold.ttf`),
    [fontFamily.light]: require(`_/assets/fonts/Montserrat-Light.ttf`),
    [fontFamily.medium]: require(`_/assets/fonts/Montserrat-Medium.ttf`),
    [fontFamily.regular]: require(`_/assets/fonts/Montserrat-Regular.ttf`),
    [fontFamily.semibold]: require(`_/assets/fonts/Montserrat-SemiBold.ttf`),
  }),
};

export const whiteOpacity = 'rgba(250,250,250,0.8)';

export const defaultShadow = {
  shadowColor: colors.light1,
  shadowOffset: {
    width: 0,
    height: 0,
  },
  shadowOpacity: 0.3,
  shadowRadius: 12,
  elevation: 3,
};
