import { CountryCode } from 'libphonenumber-js';
import { ImageSourcePropType } from 'react-native';

export type ExtendedCountryCode = CountryCode | 'AQ' | 'BV' | 'TF' | 'HM' | 'PN' | 'GS';

export interface ICountries {
  name: string;
  countryCode: string;
  code: ExtendedCountryCode;
  flag: ImageSourcePropType;
}

export const countries: ICountries[] = [
  {
    name: 'Afghanistan',
    countryCode: '+93',
    code: 'AF',
    flag: require('../assets/images/flags/afghanistan.png'),
  },
  {
    name: 'Åland Islands',
    countryCode: '+358',
    code: 'AX',
    flag: require('../assets/images/flags/aland-islands.png'),
  },
  {
    name: 'Albania',
    countryCode: '+355',
    code: 'AL',
    flag: require('../assets/images/flags/albania.png'),
  },
  {
    name: 'Algeria',
    countryCode: '+213',
    code: 'DZ',
    flag: require('../assets/images/flags/algeria.png'),
  },
  {
    name: 'American Samoa',
    countryCode: '+1684',
    code: 'AS',
    flag: require('../assets/images/flags/american-samoa.png'),
  },
  {
    name: 'Andorra',
    countryCode: '+376',
    code: 'AD',
    flag: require('../assets/images/flags/andorra.png'),
  },
  {
    name: 'Angola',
    countryCode: '+244',
    code: 'AO',
    flag: require('../assets/images/flags/angola.png'),
  },
  {
    name: 'Anguilla',
    countryCode: '+1264',
    code: 'AI',
    flag: require('../assets/images/flags/anguilla.png'),
  },
  {
    name: 'Antarctica',
    countryCode: '+672',
    code: 'AQ',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'Antigua and Barbuda',
    countryCode: '+1268',
    code: 'AG',
    flag: require('../assets/images/flags/antigua-and-barbuda.png'),
  },
  {
    name: 'Argentina',
    countryCode: '+54',
    code: 'AR',
    flag: require('../assets/images/flags/argentina.png'),
  },
  {
    name: 'Armenia',
    countryCode: '+374',
    code: 'AM',
    flag: require('../assets/images/flags/armenia.png'),
  },
  {
    name: 'Aruba',
    countryCode: '+297',
    code: 'AW',
    flag: require('../assets/images/flags/aruba.png'),
  },
  {
    name: 'Australia',
    countryCode: '+61',
    code: 'AU',
    flag: require('../assets/images/flags/australia.png'),
  },
  {
    name: 'Austria',
    countryCode: '+43',
    code: 'AT',
    flag: require('../assets/images/flags/austria.png'),
  },
  {
    name: 'Azerbaijan',
    countryCode: '+994',
    code: 'AZ',
    flag: require('../assets/images/flags/azerbaijan.png'),
  },
  {
    name: 'Bahamas',
    countryCode: '+1242',
    code: 'BS',
    flag: require('../assets/images/flags/bahamas.png'),
  },
  {
    name: 'Bahrain',
    countryCode: '+973',
    code: 'BH',
    flag: require('../assets/images/flags/bahrain.png'),
  },
  {
    name: 'Bangladesh',
    countryCode: '+880',
    code: 'BD',
    flag: require('../assets/images/flags/bangladesh.png'),
  },
  {
    name: 'Barbados',
    countryCode: '+1246',
    code: 'BB',
    flag: require('../assets/images/flags/barbados.png'),
  },
  {
    name: 'Belarus',
    countryCode: '+375',
    code: 'BY',
    flag: require('../assets/images/flags/belarus.png'),
  },
  {
    name: 'Belgium',
    countryCode: '+32',
    code: 'BE',
    flag: require('../assets/images/flags/belgium.png'),
  },
  {
    name: 'Belize',
    countryCode: '+501',
    code: 'BZ',
    flag: require('../assets/images/flags/belize.png'),
  },
  {
    name: 'Benin',
    countryCode: '+229',
    code: 'BJ',
    flag: require('../assets/images/flags/benin.png'),
  },
  {
    name: 'Bermuda',
    countryCode: '+1441',
    code: 'BM',
    flag: require('../assets/images/flags/bermuda.png'),
  },
  {
    name: 'Bhutan',
    countryCode: '+975',
    code: 'BT',
    flag: require('../assets/images/flags/bhutan.png'),
  },
  {
    name: 'Bolivia, Plurinational State of bolivia',
    countryCode: '+591',
    code: 'BO',
    flag: require('../assets/images/flags/bolivia.png'),
  },
  {
    name: 'Bosnia and Herzegovina',
    countryCode: '+387',
    code: 'BA',
    flag: require('../assets/images/flags/bosnia-and-herzegovina.png'),
  },
  {
    name: 'Botswana',
    countryCode: '+267',
    code: 'BW',
    flag: require('../assets/images/flags/botswana.png'),
  },
  {
    name: 'Bouvet Island',
    countryCode: '+47',
    code: 'BV',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'Brazil',
    countryCode: '+55',
    code: 'BR',
    flag: require('../assets/images/flags/brazil.png'),
  },
  {
    name: 'British Indian Ocean Territory',
    countryCode: '+246',
    code: 'IO',
    flag: require('../assets/images/flags/british-indian-ocean-territory.png'),
  },
  {
    name: 'Brunei',
    countryCode: '+673',
    code: 'BN',
    flag: require('../assets/images/flags/brunei.png'),
  },
  {
    name: 'Bulgaria',
    countryCode: '+359',
    code: 'BG',
    flag: require('../assets/images/flags/bulgaria.png'),
  },
  {
    name: 'Burkina Faso',
    countryCode: '+226',
    code: 'BF',
    flag: require('../assets/images/flags/burkina-faso.png'),
  },
  {
    name: 'Burundi',
    countryCode: '+257',
    code: 'BI',
    flag: require('../assets/images/flags/burundi.png'),
  },
  {
    name: 'Cambodia',
    countryCode: '+855',
    code: 'KH',
    flag: require('../assets/images/flags/cambodia.png'),
  },
  {
    name: 'Cameroon',
    countryCode: '+237',
    code: 'CM',
    flag: require('../assets/images/flags/cameroon.png'),
  },
  {
    name: 'Canada',
    countryCode: '+1',
    code: 'CA',
    flag: require('../assets/images/flags/canada.png'),
  },
  {
    name: 'Cape Verde',
    countryCode: '+238',
    code: 'CV',
    flag: require('../assets/images/flags/cape-verde.png'),
  },
  {
    name: 'Cayman Islands',
    countryCode: '+ 345',
    code: 'KY',
    flag: require('../assets/images/flags/cayman-islands.png'),
  },
  {
    name: 'Central African Republic',
    countryCode: '+236',
    code: 'CF',
    flag: require('../assets/images/flags/central-african-republic.png'),
  },
  {
    name: 'Chad',
    countryCode: '+235',
    code: 'TD',
    flag: require('../assets/images/flags/chad.png'),
  },
  {
    name: 'Chile',
    countryCode: '+56',
    code: 'CL',
    flag: require('../assets/images/flags/chile.png'),
  },
  {
    name: 'China',
    countryCode: '+86',
    code: 'CN',
    flag: require('../assets/images/flags/china.png'),
  },
  {
    name: 'Christmas Island',
    countryCode: '+61',
    code: 'CX',
    flag: require('../assets/images/flags/christmas-island.png'),
  },
  {
    name: 'Cocos (Keeling) Islands',
    countryCode: '+61',
    code: 'CC',
    flag: require('../assets/images/flags/cocos-island.png'),
  },
  {
    name: 'Colombia',
    countryCode: '+57',
    code: 'CO',
    flag: require('../assets/images/flags/colombia.png'),
  },
  {
    name: 'Comoros',
    countryCode: '+269',
    code: 'KM',
    flag: require('../assets/images/flags/comoros.png'),
  },
  {
    name: 'Cook Islands',
    countryCode: '+682',
    code: 'CK',
    flag: require('../assets/images/flags/cook-islands.png'),
  },
  {
    name: 'Costa Rica',
    countryCode: '+506',
    code: 'CR',
    flag: require('../assets/images/flags/costa-rica.png'),
  },
  {
    name: 'Croatia',
    countryCode: '+385',
    code: 'HR',
    flag: require('../assets/images/flags/croatia.png'),
  },
  {
    name: 'Cuba',
    countryCode: '+53',
    code: 'CU',
    flag: require('../assets/images/flags/cuba.png'),
  },
  {
    name: 'Cyprus',
    countryCode: '+357',
    code: 'CY',
    flag: require('../assets/images/flags/cyprus.png'),
  },
  {
    name: 'Czech Republic',
    countryCode: '+420',
    code: 'CZ',
    flag: require('../assets/images/flags/czech-republic.png'),
  },
  {
    name: 'Denmark',
    countryCode: '+45',
    code: 'DK',
    flag: require('../assets/images/flags/denmark.png'),
  },
  {
    name: 'Djibouti',
    countryCode: '+253',
    code: 'DJ',
    flag: require('../assets/images/flags/djibouti.png'),
  },
  {
    name: 'Dominica',
    countryCode: '+1767',
    code: 'DM',
    flag: require('../assets/images/flags/dominica.png'),
  },
  {
    name: 'Dominican Republic',
    countryCode: '+1849',
    code: 'DO',
    flag: require('../assets/images/flags/dominican-republic.png'),
  },
  {
    name: 'Ecuador',
    countryCode: '+593',
    code: 'EC',
    flag: require('../assets/images/flags/ecuador.png'),
  },
  {
    name: 'Egypt',
    countryCode: '+20',
    code: 'EG',
    flag: require('../assets/images/flags/egypt.png'),
  },
  {
    name: 'El Salvador',
    countryCode: '+503',
    code: 'SV',
    flag: require('../assets/images/flags/el-salvador.png'),
  },
  {
    name: 'Equatorial Guinea',
    countryCode: '+240',
    code: 'GQ',
    flag: require('../assets/images/flags/equatorial-guinea.png'),
  },
  {
    name: 'Eritrea',
    countryCode: '+291',
    code: 'ER',
    flag: require('../assets/images/flags/eritrea.png'),
  },
  {
    name: 'Estonia',
    countryCode: '+372',
    code: 'EE',
    flag: require('../assets/images/flags/estonia.png'),
  },
  {
    name: 'Ethiopia',
    countryCode: '+251',
    code: 'ET',
    flag: require('../assets/images/flags/ethiopia.png'),
  },
  {
    name: 'Falkland Islands (Malvinas)',
    countryCode: '+500',
    code: 'FK',
    flag: require('../assets/images/flags/falkland-islands.png'),
  },
  {
    name: 'Faroe Islands',
    countryCode: '+298',
    code: 'FO',
    flag: require('../assets/images/flags/faroe-islands.png'),
  },
  {
    name: 'Fiji',
    countryCode: '+679',
    code: 'FJ',
    flag: require('../assets/images/flags/fiji.png'),
  },
  {
    name: 'Finland',
    countryCode: '+358',
    code: 'FI',
    flag: require('../assets/images/flags/finland.png'),
  },
  {
    name: 'France',
    countryCode: '+33',
    code: 'FR',
    flag: require('../assets/images/flags/france.png'),
  },
  {
    name: 'French Guiana',
    countryCode: '+594',
    code: 'GF',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'French Polynesia',
    countryCode: '+689',
    code: 'PF',
    flag: require('../assets/images/flags/french-polynesia.png'),
  },
  {
    name: 'French Southern',
    countryCode: '+262',
    code: 'TF',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'Gabon',
    countryCode: '+241',
    code: 'GA',
    flag: require('../assets/images/flags/gabon.png'),
  },
  {
    name: 'Gambia',
    countryCode: '+220',
    code: 'GM',
    flag: require('../assets/images/flags/gambia.png'),
  },
  {
    name: 'Georgia',
    countryCode: '+995',
    code: 'GE',
    flag: require('../assets/images/flags/georgia.png'),
  },
  {
    name: 'Germany',
    countryCode: '+49',
    code: 'DE',
    flag: require('../assets/images/flags/germany.png'),
  },
  {
    name: 'Ghana',
    countryCode: '+233',
    code: 'GH',
    flag: require('../assets/images/flags/ghana.png'),
  },
  {
    name: 'Gibraltar',
    countryCode: '+350',
    code: 'GI',
    flag: require('../assets/images/flags/gibraltar.png'),
  },
  {
    name: 'Greece',
    countryCode: '+30',
    code: 'GR',
    flag: require('../assets/images/flags/greece.png'),
  },
  {
    name: 'Greenland',
    countryCode: '+299',
    code: 'GL',
    flag: require('../assets/images/flags/greenland.png'),
  },
  {
    name: 'Grenada',
    countryCode: '+1473',
    code: 'GD',
    flag: require('../assets/images/flags/grenada.png'),
  },
  {
    name: 'Guadeloupe',
    countryCode: '+590',
    code: 'GP',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'Guam',
    countryCode: '+1671',
    code: 'GU',
    flag: require('../assets/images/flags/guam.png'),
  },
  {
    name: 'Guatemala',
    countryCode: '+502',
    code: 'GT',
    flag: require('../assets/images/flags/guatemala.png'),
  },
  {
    name: 'Guernsey',
    countryCode: '+44',
    code: 'GG',
    flag: require('../assets/images/flags/guernsey.png'),
  },
  {
    name: 'Guinea',
    countryCode: '+224',
    code: 'GN',
    flag: require('../assets/images/flags/guinea.png'),
  },
  {
    name: 'Guinea-Bissau',
    countryCode: '+245',
    code: 'GW',
    flag: require('../assets/images/flags/guinea-bissau.png'),
  },
  {
    name: 'Guyana',
    countryCode: '+592',
    code: 'GY',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'Haiti',
    countryCode: '+509',
    code: 'HT',
    flag: require('../assets/images/flags/haiti.png'),
  },
  {
    name: 'Heard Island and Mcdonald Islands',
    countryCode: '+0',
    code: 'HM',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'Honduras',
    countryCode: '+504',
    code: 'HN',
    flag: require('../assets/images/flags/honduras.png'),
  },
  {
    name: 'Hong Kong',
    countryCode: '+852',
    code: 'HK',
    flag: require('../assets/images/flags/hong-kong.png'),
  },
  {
    name: 'Hungary',
    countryCode: '+36',
    code: 'HU',
    flag: require('../assets/images/flags/hungary.png'),
  },
  {
    name: 'Iceland',
    countryCode: '+354',
    code: 'IS',
    flag: require('../assets/images/flags/iceland.png'),
  },
  {
    name: 'India',
    countryCode: '+91',
    code: 'IN',
    flag: require('../assets/images/flags/india.png'),
  },
  {
    name: 'Indonesia',
    countryCode: '+62',
    code: 'ID',
    flag: require('../assets/images/flags/indonesia.png'),
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    countryCode: '+98',
    code: 'IR',
    flag: require('../assets/images/flags/iran.png'),
  },
  {
    name: 'Iraq',
    countryCode: '+964',
    code: 'IQ',
    flag: require('../assets/images/flags/iraq.png'),
  },
  {
    name: 'Ireland',
    countryCode: '+353',
    code: 'IE',
    flag: require('../assets/images/flags/ireland.png'),
  },
  {
    name: 'Isle of Man',
    countryCode: '+44',
    code: 'IM',
    flag: require('../assets/images/flags/isle-of-man.png'),
  },
  {
    name: 'Israel',
    countryCode: '+972',
    code: 'IL',
    flag: require('../assets/images/flags/israel.png'),
  },
  {
    name: 'Italy',
    countryCode: '+39',
    code: 'IT',
    flag: require('../assets/images/flags/italy.png'),
  },
  {
    name: 'Ivory Coast',
    countryCode: '+225',
    code: 'CI',
    flag: require('../assets/images/flags/ivory-coast.png'),
  },
  {
    name: 'Jamaica',
    countryCode: '+1876',
    code: 'JM',
    flag: require('../assets/images/flags/jamaica.png'),
  },
  {
    name: 'Japan',
    countryCode: '+81',
    code: 'JP',
    flag: require('../assets/images/flags/japan.png'),
  },
  {
    name: 'Jersey',
    countryCode: '+44',
    code: 'JE',
    flag: require('../assets/images/flags/jersey.png'),
  },
  {
    name: 'Jordan',
    countryCode: '+962',
    code: 'JO',
    flag: require('../assets/images/flags/jordan.png'),
  },
  {
    name: 'Kazakhstan',
    countryCode: '+7',
    code: 'KZ',
    flag: require('../assets/images/flags/kazakhstan.png'),
  },
  {
    name: 'Kenya',
    countryCode: '+254',
    code: 'KE',
    flag: require('../assets/images/flags/kenya.png'),
  },
  {
    name: 'Kiribati',
    countryCode: '+686',
    code: 'KI',
    flag: require('../assets/images/flags/kiribati.png'),
  },
  {
    name: 'Kosovo',
    countryCode: '+383',
    code: 'XK',
    flag: require('../assets/images/flags/kosovo.png'),
  },
  {
    name: 'Kuwait',
    countryCode: '+965',
    code: 'KW',
    flag: require('../assets/images/flags/kwait.png'),
  },
  {
    name: 'Kyrgyzstan',
    countryCode: '+996',
    code: 'KG',
    flag: require('../assets/images/flags/kyrgyzstan.png'),
  },
  {
    name: 'Laos',
    countryCode: '+856',
    code: 'LA',
    flag: require('../assets/images/flags/laos.png'),
  },
  {
    name: 'Latvia',
    countryCode: '+371',
    code: 'LV',
    flag: require('../assets/images/flags/latvia.png'),
  },
  {
    name: 'Lebanon',
    countryCode: '+961',
    code: 'LB',
    flag: require('../assets/images/flags/lebanon.png'),
  },
  {
    name: 'Lesotho',
    countryCode: '+266',
    code: 'LS',
    flag: require('../assets/images/flags/lesotho.png'),
  },
  {
    name: 'Liberia',
    countryCode: '+231',
    code: 'LR',
    flag: require('../assets/images/flags/liberia.png'),
  },
  {
    name: 'Libyan Arab Jamahiriya',
    countryCode: '+218',
    code: 'LY',
    flag: require('../assets/images/flags/libya.png'),
  },
  {
    name: 'Liechtenstein',
    countryCode: '+423',
    code: 'LI',
    flag: require('../assets/images/flags/liechtenstein.png'),
  },
  {
    name: 'Lithuania',
    countryCode: '+370',
    code: 'LT',
    flag: require('../assets/images/flags/lithuania.png'),
  },
  {
    name: 'Luxembourg',
    countryCode: '+352',
    code: 'LU',
    flag: require('../assets/images/flags/luxembourg.png'),
  },
  {
    name: 'Macao',
    countryCode: '+853',
    code: 'MO',
    flag: require('../assets/images/flags/macao.png'),
  },
  {
    name: 'Macedonia',
    countryCode: '+389',
    code: 'MK',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'Madagascar',
    countryCode: '+261',
    code: 'MG',
    flag: require('../assets/images/flags/madagascar.png'),
  },
  {
    name: 'Malaysia',
    countryCode: '+60',
    code: 'MY',
    flag: require('../assets/images/flags/malasya.png'),
  },
  {
    name: 'Malawi',
    countryCode: '+265',
    code: 'MW',
    flag: require('../assets/images/flags/malawi.png'),
  },
  {
    name: 'Maldives',
    countryCode: '+960',
    code: 'MV',
    flag: require('../assets/images/flags/maldives.png'),
  },
  {
    name: 'Mali',
    countryCode: '+223',
    code: 'ML',
    flag: require('../assets/images/flags/mali.png'),
  },
  {
    name: 'Malta',
    countryCode: '+356',
    code: 'MT',
    flag: require('../assets/images/flags/malta.png'),
  },
  {
    name: 'Marshall Islands',
    countryCode: '+692',
    code: 'MH',
    flag: require('../assets/images/flags/marshall-island.png'),
  },
  {
    name: 'Martinique',
    countryCode: '+596',
    code: 'MQ',
    flag: require('../assets/images/flags/martinique.png'),
  },
  {
    name: 'Mauritania',
    countryCode: '+222',
    code: 'MR',
    flag: require('../assets/images/flags/mauritania.png'),
  },
  {
    name: 'Mauritius',
    countryCode: '+230',
    code: 'MU',
    flag: require('../assets/images/flags/mauritius.png'),
  },
  {
    name: 'Mayotte',
    countryCode: '+262',
    code: 'YT',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'Mexico',
    countryCode: '+52',
    code: 'MX',
    flag: require('../assets/images/flags/mexico.png'),
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    countryCode: '+691',
    code: 'FM',
    flag: require('../assets/images/flags/micronesia.png'),
  },
  {
    name: 'Moldova',
    countryCode: '+373',
    code: 'MD',
    flag: require('../assets/images/flags/moldova.png'),
  },
  {
    name: 'Monaco',
    countryCode: '+377',
    code: 'MC',
    flag: require('../assets/images/flags/monaco.png'),
  },
  {
    name: 'Mongolia',
    countryCode: '+976',
    code: 'MN',
    flag: require('../assets/images/flags/mongolia.png'),
  },
  {
    name: 'Montenegro',
    countryCode: '+382',
    code: 'ME',
    flag: require('../assets/images/flags/montenegro.png'),
  },
  {
    name: 'Montserrat',
    countryCode: '+1664',
    code: 'MS',
    flag: require('../assets/images/flags/montserrat.png'),
  },
  {
    name: 'Morocco',
    countryCode: '+212',
    code: 'MA',
    flag: require('../assets/images/flags/morocco.png'),
  },
  {
    name: 'Mozambique',
    countryCode: '+258',
    code: 'MZ',
    flag: require('../assets/images/flags/mozambique.png'),
  },
  {
    name: 'Myanmar',
    countryCode: '+95',
    code: 'MM',
    flag: require('../assets/images/flags/myanmar.png'),
  },
  {
    name: 'Namibia',
    countryCode: '+264',
    code: 'NA',
    flag: require('../assets/images/flags/namibia.png'),
  },
  {
    name: 'Nauru',
    countryCode: '+674',
    code: 'NR',
    flag: require('../assets/images/flags/nauru.png'),
  },
  {
    name: 'Nepal',
    countryCode: '+977',
    code: 'NP',
    flag: require('../assets/images/flags/nepal.png'),
  },
  {
    name: 'Netherlands',
    countryCode: '+31',
    code: 'NL',
    flag: require('../assets/images/flags/netherlands.png'),
  },
  {
    name: 'New Caledonia',
    countryCode: '+687',
    code: 'NC',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'New Zealand',
    countryCode: '+64',
    code: 'NZ',
    flag: require('../assets/images/flags/new-zealand.png'),
  },
  {
    name: 'Nicaragua',
    countryCode: '+505',
    code: 'NI',
    flag: require('../assets/images/flags/nicaragua.png'),
  },
  {
    name: 'Niger',
    countryCode: '+227',
    code: 'NE',
    flag: require('../assets/images/flags/niger.png'),
  },
  {
    name: 'Nigeria',
    countryCode: '+234',
    code: 'NG',
    flag: require('../assets/images/flags/nigeria.png'),
  },
  {
    name: 'Niue',
    countryCode: '+683',
    code: 'NU',
    flag: require('../assets/images/flags/niue.png'),
  },
  {
    name: 'Norfolk Island',
    countryCode: '+672',
    code: 'NF',
    flag: require('../assets/images/flags/norfolk-island.png'),
  },
  {
    name: 'North Korea',
    countryCode: '+850',
    code: 'KP',
    flag: require('../assets/images/flags/north-korea.png'),
  },
  {
    name: 'Northern Mariana Islands',
    countryCode: '+1670',
    code: 'MP',
    flag: require('../assets/images/flags/northern-marianas-islands.png'),
  },
  {
    name: 'Norway',
    countryCode: '+47',
    code: 'NO',
    flag: require('../assets/images/flags/norway.png'),
  },
  {
    name: 'Oman',
    countryCode: '+968',
    code: 'OM',
    flag: require('../assets/images/flags/oman.png'),
  },
  {
    name: 'Pakistan',
    countryCode: '+92',
    code: 'PK',
    flag: require('../assets/images/flags/pakistan.png'),
  },
  {
    name: 'Palau',
    countryCode: '+680',
    code: 'PW',
    flag: require('../assets/images/flags/palau.png'),
  },
  {
    name: 'Palestinian',
    countryCode: '+970',
    code: 'PS',
    flag: require('../assets/images/flags/palestine.png'),
  },
  {
    name: 'Panama',
    countryCode: '+507',
    code: 'PA',
    flag: require('../assets/images/flags/panama.png'),
  },
  {
    name: 'Papua New Guinea',
    countryCode: '+675',
    code: 'PG',
    flag: require('../assets/images/flags/papua-new-guinea.png'),
  },
  {
    name: 'Paraguay',
    countryCode: '+595',
    code: 'PY',
    flag: require('../assets/images/flags/paraguay.png'),
  },
  {
    name: 'Peru',
    countryCode: '+51',
    code: 'PE',
    flag: require('../assets/images/flags/peru.png'),
  },
  {
    name: 'Philippines',
    countryCode: '+63',
    code: 'PH',
    flag: require('../assets/images/flags/philippines.png'),
  },
  {
    name: 'Pitcairn',
    countryCode: '+64',
    code: 'PN',
    flag: require('../assets/images/flags/pitcairn-islands.png'),
  },
  {
    name: 'Poland',
    countryCode: '+48',
    code: 'PL',
    flag: require('../assets/images/flags/poland.png'),
  },
  {
    name: 'Portugal',
    countryCode: '+351',
    code: 'PT',
    flag: require('../assets/images/flags/portugal.png'),
  },
  {
    name: 'Puerto Rico',
    countryCode: '+1939',
    code: 'PR',
    flag: require('../assets/images/flags/puerto-rico.png'),
  },
  {
    name: 'Qatar',
    countryCode: '+974',
    code: 'QA',
    flag: require('../assets/images/flags/qatar.png'),
  },
  {
    name: 'Romania',
    countryCode: '+40',
    code: 'RO',
    flag: require('../assets/images/flags/romania.png'),
  },
  {
    name: 'Russia',
    countryCode: '+7',
    code: 'RU',
    flag: require('../assets/images/flags/russia.png'),
  },
  {
    name: 'Rwanda',
    countryCode: '+250',
    code: 'RW',
    flag: require('../assets/images/flags/rwanda.png'),
  },
  {
    name: 'Saint Barthelemy',
    countryCode: '+590',
    code: 'BL',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    countryCode: '+290',
    code: 'SH',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'Saint Kitts and Nevis',
    countryCode: '+1869',
    code: 'KN',
    flag: require('../assets/images/flags/sahrawi-arab-democratic-republic.png'),
  },
  {
    name: 'Saint Lucia',
    countryCode: '+1758',
    code: 'LC',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'Saint Martin',
    countryCode: '+590',
    code: 'MF',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'Saint Pierre and Miquelon',
    countryCode: '+508',
    code: 'PM',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'Saint Vincent and the Grenadines',
    countryCode: '+1784',
    code: 'VC',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'Samoa',
    countryCode: '+685',
    code: 'WS',
    flag: require('../assets/images/flags/samoa.png'),
  },
  {
    name: 'San Marino',
    countryCode: '+378',
    code: 'SM',
    flag: require('../assets/images/flags/san-marino.png'),
  },
  {
    name: 'Sao Tome and Prince',
    countryCode: '+239',
    code: 'ST',
    flag: require('../assets/images/flags/sao-tome-and-prince.png'),
  },
  {
    name: 'Saudi Arabia',
    countryCode: '+966',
    code: 'SA',
    flag: require('../assets/images/flags/saudi-arabia.png'),
  },
  {
    name: 'Senegal',
    countryCode: '+221',
    code: 'SN',
    flag: require('../assets/images/flags/senegal.png'),
  },
  {
    name: 'Serbia',
    countryCode: '+381',
    code: 'RS',
    flag: require('../assets/images/flags/serbia.png'),
  },
  {
    name: 'Seychelles',
    countryCode: '+248',
    code: 'SC',
    flag: require('../assets/images/flags/seychelles.png'),
  },
  {
    name: 'Sierra Leone',
    countryCode: '+232',
    code: 'SL',
    flag: require('../assets/images/flags/sierra-leone.png'),
  },
  {
    name: 'Singapore',
    countryCode: '+65',
    code: 'SG',
    flag: require('../assets/images/flags/singapore.png'),
  },
  {
    name: 'Slovakia',
    countryCode: '+421',
    code: 'SK',
    flag: require('../assets/images/flags/slovakia.png'),
  },
  {
    name: 'Slovenia',
    countryCode: '+386',
    code: 'SI',
    flag: require('../assets/images/flags/slovenia.png'),
  },
  {
    name: 'Solomon Islands',
    countryCode: '+677',
    code: 'SB',
    flag: require('../assets/images/flags/solomon-islands.png'),
  },
  {
    name: 'Somalia',
    countryCode: '+252',
    code: 'SO',
    flag: require('../assets/images/flags/somalia.png'),
  },
  {
    name: 'South Africa',
    countryCode: '+27',
    code: 'ZA',
    flag: require('../assets/images/flags/south-africa.png'),
  },
  {
    name: 'South Korea',
    countryCode: '+82',
    code: 'KR',
    flag: require('../assets/images/flags/south-korea.png'),
  },
  {
    name: 'South Sudan',
    countryCode: '+211',
    code: 'SS',
    flag: require('../assets/images/flags/south-sudan.png'),
  },
  {
    name: 'South Georgia',
    countryCode: '+500',
    code: 'GS',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'Spain',
    countryCode: '+34',
    code: 'ES',
    flag: require('../assets/images/flags/spain.png'),
  },
  {
    name: 'Sri Lanka',
    countryCode: '+94',
    code: 'LK',
    flag: require('../assets/images/flags/sri-lanka.png'),
  },
  {
    name: 'Sudan',
    countryCode: '+249',
    code: 'SD',
    flag: require('../assets/images/flags/sudan.png'),
  },
  {
    name: 'Suriname',
    countryCode: '+597',
    code: 'SR',
    flag: require('../assets/images/flags/suriname.png'),
  },
  {
    name: 'Svalbard and Jan Mayen',
    countryCode: '+47',
    code: 'SJ',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'Sweden',
    countryCode: '+46',
    code: 'SE',
    flag: require('../assets/images/flags/sweden.png'),
  },
  {
    name: 'Switzerland',
    countryCode: '+41',
    code: 'CH',
    flag: require('../assets/images/flags/switzerland.png'),
  },
  {
    name: 'Syrian Arab Republic',
    countryCode: '+963',
    code: 'SY',
    flag: require('../assets/images/flags/syria.png'),
  },
  {
    name: 'Taiwan',
    countryCode: '+886',
    code: 'TW',
    flag: require('../assets/images/flags/taiwan.png'),
  },
  {
    name: 'Tajikistan',
    countryCode: '+992',
    code: 'TJ',
    flag: require('../assets/images/flags/tajikistan.png'),
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    countryCode: '+255',
    code: 'TZ',
    flag: require('../assets/images/flags/tanzania.png'),
  },
  {
    name: 'Thailand',
    countryCode: '+66',
    code: 'TH',
    flag: require('../assets/images/flags/thailand.png'),
  },
  {
    name: 'Timor-Leste',
    countryCode: '+670',
    code: 'TL',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'Togo',
    countryCode: '+228',
    code: 'TG',
    flag: require('../assets/images/flags/togo.png'),
  },
  {
    name: 'Tokelau',
    countryCode: '+690',
    code: 'TK',
    flag: require('../assets/images/flags/tokelau.png'),
  },
  {
    name: 'Tonga',
    countryCode: '+676',
    code: 'TO',
    flag: require('../assets/images/flags/tonga.png'),
  },
  {
    name: 'Trinidad and Tobago',
    countryCode: '+1868',
    code: 'TT',
    flag: require('../assets/images/flags/trinidad-and-tobago.png'),
  },
  {
    name: 'Tunisia',
    countryCode: '+216',
    code: 'TN',
    flag: require('../assets/images/flags/tunisia.png'),
  },
  {
    name: 'Turkey',
    countryCode: '+90',
    code: 'TR',
    flag: require('../assets/images/flags/turkey.png'),
  },
  {
    name: 'Turkmenistan',
    countryCode: '+993',
    code: 'TM',
    flag: require('../assets/images/flags/turkmenistan.png'),
  },
  {
    name: 'Turks and Caicos Islands',
    countryCode: '+1649',
    code: 'TC',
    flag: require('../assets/images/flags/turks-and-caicos.png'),
  },
  {
    name: 'Tuvalu',
    countryCode: '+688',
    code: 'TV',
    flag: require('../assets/images/flags/tuvalu.png'),
  },
  {
    name: 'Uganda',
    countryCode: '+256',
    code: 'UG',
    flag: require('../assets/images/flags/uganda.png'),
  },
  {
    name: 'Ukraine',
    countryCode: '+380',
    code: 'UA',
    flag: require('../assets/images/flags/ukraine.png'),
  },
  {
    name: 'United Arab Emirates',
    countryCode: '+971',
    code: 'AE',
    flag: require('../assets/images/flags/united-arab-emirates.png'),
  },
  {
    name: 'United Kingdom',
    countryCode: '+44',
    code: 'GB',
    flag: require('../assets/images/flags/united-kingdom.png'),
  },
  {
    name: 'United States',
    countryCode: '+1',
    code: 'US',
    flag: require('../assets/images/flags/united-states.png'),
  },
  {
    name: 'Uruguay',
    countryCode: '+598',
    code: 'UY',
    flag: require('../assets/images/flags/uruguay.png'),
  },
  {
    name: 'Uzbekistan',
    countryCode: '+998',
    code: 'UZ',
    flag: require('../assets/images/flags/uzbekistn.png'),
  },
  {
    name: 'Vanuatu',
    countryCode: '+678',
    code: 'VU',
    flag: require('../assets/images/flags/vanuatu.png'),
  },
  {
    name: 'Vatican City',
    countryCode: '+3906698',
    code: 'VA',
    flag: require('../assets/images/flags/vatican-city.png'),
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    countryCode: '+58',
    code: 'VE',
    flag: require('../assets/images/flags/venezuela.png'),
  },
  {
    name: 'Vietnam',
    countryCode: '+84',
    code: 'VN',
    flag: require('../assets/images/flags/vietnam.png'),
  },
  {
    name: 'Virgin Islands, British',
    countryCode: '+1284',
    code: 'VG',
    flag: require('../assets/images/flags/virgin-islands.png'),
  },
  {
    name: 'Wallis and Futuna',
    countryCode: '+681',
    code: 'WF',
    flag: require('../assets/images/flags/placeholder.png'),
  },
  {
    name: 'Yemen',
    countryCode: '+967',
    code: 'YE',
    flag: require('../assets/images/flags/yemen.png'),
  },
  {
    name: 'Zambia',
    countryCode: '+260',
    code: 'ZM',
    flag: require('../assets/images/flags/zambia.png'),
  },
  {
    name: 'Zimbabwe',
    countryCode: '+263',
    code: 'ZW',
    flag: require('../assets/images/flags/zimbabwe.png'),
  },
];
