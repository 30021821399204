import { SmsOtpValidationsModel } from '@w3lcome/types';
import { AxiosRequestConfig } from 'axios';

import logger from '../logger';
import RequestCreator from './BaseRequest';

type ValidateOtpPayload = {
  code: string;
  phone: string;
};

type RequestOtpPayload = {
  phone: string;
};

class SmsOtpValidations extends RequestCreator<SmsOtpValidationsModel> {
  private childrenSmsOtpValidationsPath = 'ipad/children/sms-otp-validations';

  childrenRequestOtp = async (
    companyId: string,
    values: RequestOtpPayload,
    config?: AxiosRequestConfig
  ) => {
    try {
      const result = await this.api.post<SmsOtpValidationsModel>(
        `${this.childrenSmsOtpValidationsPath}`,
        values,
        {
          ...config,
          params: { companyId },
        }
      );
      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  childrenValidatetOtp = async (
    companyId: string,
    values: ValidateOtpPayload,
    config?: AxiosRequestConfig
  ) => {
    try {
      const result = await this.api.patch<SmsOtpValidationsModel>(
        `${this.childrenSmsOtpValidationsPath}/${companyId}`,
        values,
        {
          ...config,
          params: { companyId },
        }
      );
      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };

  validateOtp = async (
    id: string | null,
    values: ValidateOtpPayload,
    config?: AxiosRequestConfig
  ) => {
    try {
      const result = await this.api.patch<SmsOtpValidationsModel>(
        `${this.path}/${id}`,
        values,
        config
      );
      return result.data;
    } catch (error) {
      logger(error);
      throw error;
    }
  };
}

const api = new SmsOtpValidations('ipad/sms-otp-validations');
export default api;
