export enum Events {
  ENVIRONMENT_CHANGED = 'ENVIRONMENT_CHANGED',
}

class EventEmitter {
  private events: { [key: string]: Function[] } = {};

  public subscribe = (event: string, callback: Function) => {
    if (!this.events[event]) {
      this.events[event] = [];
    }

    this.events[event].push(callback);

    return () => (this.events[event] = this.events[event].filter((func) => func !== callback));
  };

  public emit = (event: string, data?: any) => {
    if (this.events[event]) {
      this.events[event].forEach((func) => func(data));
    }
  };
}

export const ConfigEventEmitter = new EventEmitter();
