import { useNavigation } from '@react-navigation/native';
import { IpadLang } from '@w3lcome/types';
import { Text } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import { colors, fontSizes } from '_/config/theme';
import { textReplace } from '_/helpers/textReplace';
import { AppRoute, LGPDCheckinMessageProps } from '_/navigation/types';
import i18n from '_/services/locale';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet } from 'react-native';

import { useCustomizationLgpd } from '../../hooks/CustomizationLgpdContext';
import { useVisitLgpdContext } from '../../hooks/VisitLgpdContext';
import CustomMessage from './CustomMessage';

const LgpdCheckinMessageScreen: React.FC = () => {
  const { customizationLgpd } = useCustomizationLgpd();

  const { visit, visitCheckin, resetData } = useVisitLgpdContext();
  const [startTimer, setStartTimer] = useState(false);

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { t } = useTranslation();
  const navigation = useNavigation<LGPDCheckinMessageProps>();

  useEffect(() => {
    async function checkin() {
      if (visit.IsValidGDPRSignature) {
        setStartTimer(true);
        setLoading(false);
        return;
      }
      try {
        await visitCheckin(visit);
      } catch (error) {
        setErrorMessage(t('checkinScreen.checkinError'));
      }
      setStartTimer(true);
      setLoading(false);
    }

    checkin();
  }, []);

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (startTimer) {
      timerId = setTimeout(() => {
        resetData();
        navigation.navigate(AppRoute.LGPD_TOTEM_CHECKIN);
      }, 10 * 1000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [startTimer]);

  const ipadWelcomeMessage = useMemo(() => {
    return textReplace(customizationLgpd?.CompanyAuxText04 || '', i18n.language as IpadLang, null);
  }, [customizationLgpd]);

  return (
    <View style={styles.container}>
      <ContainerWidthLimit>
        <View style={styles.content}>
          <View style={{ alignItems: 'center' }}>
            <Text size={fontSizes.xxxl}>{t('Welcome')}</Text>
            <Text fontType="bold" size={fontSizes.xxxl}>
              {`${visit.FirstName} ${visit.LastName}`}
            </Text>
          </View>
          <CustomMessage error={errorMessage} message={ipadWelcomeMessage} loading={loading} />
        </View>
      </ContainerWidthLimit>
    </View>
  );
};

export default LgpdCheckinMessageScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    alignItems: 'center',
  },
  content: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    paddingHorizontal: 64,
    textAlign: 'center',
  },
});
