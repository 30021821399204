import { Button } from '_/components';
import ModalBackground from '_/components/ModalBackground';
import { colors, fontSizes } from '_/config/theme';
import { useAuth } from '_/hooks/AuthContext';
import { useCustomization } from '_/hooks/CustomizationContext';
import logger from '_/services/logger';
import { Camera, CameraType } from 'expo-camera/build/legacy';
import * as ImageManipulator from 'expo-image-manipulator';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet } from 'react-native';
import { View } from 'react-native-animatable';
import { showMessage } from 'react-native-flash-message';

interface ModalDeliveriesPictureProps {
  isVisible: boolean;
  onCancel: () => void;
  onTakePicture: (values: string | undefined) => void;
}

const ModalDeliveriesPicture: React.FC<ModalDeliveriesPictureProps> = ({
  isVisible,
  onCancel,
  onTakePicture,
}) => {
  const camera = useRef<Camera>(null);
  const { ipad } = useAuth();

  const { t } = useTranslation();
  const { customization } = useCustomization();

  const takePicture = async () => {
    if (!camera.current) {
      return;
    }

    try {
      const picture = await camera.current?.takePictureAsync();

      let pictureUrl = 'data:image/png;base64,';
      if (picture?.uri) {
        const flippedPicture = await ImageManipulator.manipulateAsync(picture.uri, [], {
          compress: 1,
          base64: true,
        });
        pictureUrl += flippedPicture.base64;
      } else {
        pictureUrl += picture.base64;
      }

      onTakePicture(pictureUrl);
      onCancel();
    } catch (error) {
      logger(error);
      showMessage({
        message: t('error'),
        description: t('AccessDenied'),
        backgroundColor: colors.danger,
      });
    }
  };

  return (
    <ModalBackground isVisible={isVisible}>
      <View style={styles.cameraContainer}>
        <Camera
          ref={camera}
          type={
            (ipad?.settingsDeliveryPictureCamera.toLowerCase() as CameraType) || CameraType.front
          }
          style={styles.camera}
        />
      </View>
      <View style={styles.buttons}>
        <Button
          onPress={onCancel}
          small
          outline
          leftIcon="x"
          textStyles={{ fontSize: fontSizes.lg }}
        >
          {t('Cancel')}
        </Button>
        <Button
          onPress={takePicture}
          small
          leftIcon="camera"
          textStyles={{ fontSize: fontSizes.lg }}
          backgroundColor={customization?.mainColor || colors.primary}
          labelColor={customization?.backgroundColor || colors.white}
        >
          {t('takePicture')}
        </Button>
      </View>
    </ModalBackground>
  );
};

export default ModalDeliveriesPicture;

const styles = StyleSheet.create({
  cameraContainer: {
    width: 400,
    height: 400,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: colors.white,
  },
  camera: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  buttons: {
    marginTop: 12,
    width: 400,
    height: 64,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
});
