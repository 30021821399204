import { useNavigation } from '@react-navigation/native';
import { Button, CustomFieldInput, Header, Text } from '_/components';
import ContainerWidthLimit from '_/components/ContainerWidthLimit';
import { colors, fontSizes } from '_/config/theme';
import { useCustomization } from '_/hooks/CustomizationContext';
import { useVisit } from '_/hooks/VisitContext';
import { AppRoute, CheckoutFeedbackProps } from '_/navigation/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, ScrollView } from 'react-native';

const CheckoutFeedbackScreen: React.FC = () => {
  const { customization } = useCustomization();
  const { setVisitDataByFeedback, getVisitDataByFeedback } = useVisit();

  const { t } = useTranslation();

  const { navigate } = useNavigation<CheckoutFeedbackProps>();

  const handleNextScreen = () => {
    navigate(AppRoute.CHECKOUT_MESSAGE);
  };

  return (
    <ScrollView
      style={[styles.container, StyleSheet.absoluteFill]}
      contentContainerStyle={{ paddingBottom: 60 }}
    >
      <View style={[styles.container, { alignItems: 'center' }]}>
        <ContainerWidthLimit>
          <Header />
          <View style={styles.content}>
            {customization?.feedbacks && (
              <>
                {customization?.feedbacks.map((feedback) => {
                  return (
                    <CustomFieldInput
                      key={feedback.id}
                      value={getVisitDataByFeedback(feedback)}
                      onChangeText={(value) => setVisitDataByFeedback(value, feedback)}
                      customField={feedback}
                    />
                  );
                })}
                <Button
                  containerStyles={styles.buttonContainer}
                  buttonStyles={[styles.button]}
                  onPress={handleNextScreen}
                >
                  <Text color={colors.white} fontType="medium" size={fontSizes.xl}>
                    {t('Done')}
                  </Text>
                </Button>
                <Button
                  containerStyles={styles.buttonContainer}
                  buttonStyles={[styles.buttonSkip]}
                  onPress={handleNextScreen}
                >
                  <Text color={colors.primary} fontType="medium" size={fontSizes.xl}>
                    {t('Skip')}
                  </Text>
                </Button>
              </>
            )}
          </View>
        </ContainerWidthLimit>
      </View>
    </ScrollView>
  );
};

export default CheckoutFeedbackScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
  },
  content: {
    alignItems: 'center',
    flex: 1,
  },
  buttonContainer: {
    width: '60%',
  },
  button: {
    marginTop: 26,
    marginBottom: 22,
    height: 64,
  },
  buttonSkip: {
    marginTop: 8,
    marginBottom: 22,
    height: 64,
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: colors.primary,
  },
});
